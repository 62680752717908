<template>
    <button
        @click.prevent="toggleShowChildren"
        aria-label="Toggle showing child rows"
        class="group relative"
    >
        <IconExpand v-if="!model.show_children"></IconExpand>
        <div v-if="!model.show_children" class="tooltip tooltip-left">
            Show child rows
        </div>
        <IconCollapse v-if="model.show_children"></IconCollapse>
        <div v-if="model.show_children" class="tooltip tooltip-left">
            Hide child rows
        </div>
    </button>
</template>

<script>
import Vue from 'vue'
import IconExpand from '@/components/icons/IconExpand'
import IconCollapse from '@/components/icons/IconCollapse'

export default {
    name: 'RowChildToggle',
    props: {
        model: Object,
    },
    components: {
        IconExpand,
        IconCollapse,
    },
    methods: {
        toggleShowChildren() {
            Vue.set(this.model, 'show_children', !this.model.show_children)
        },
    },
}
</script>

<style scoped>
.sortable-ghost {
    border: 2px dashed #2d3748;
}
</style>
