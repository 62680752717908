import Vue from 'vue'

const state = Vue.observable({
    sessionId: null,
})

// getters
const getters = {
    sessionId: (state) => state.sessionId,
}

// actions
const actions = {
    setSessionId({ commit }, sessionId) {
        commit('SET_SESSION_ID', sessionId)
    },
}

// mutations
const mutations = {
    SET_SESSION_ID(state, sessionId) {
        state.sessionId = sessionId
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}
