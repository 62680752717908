<template>
    <svg
        ARROW-NARROW-RIGHT
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
    >
        <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M17 8l4 4m0 0l-4 4m4-4H3"
        />
    </svg>
</template>

<script>
export default {
    name: 'IconNarrowRight',
}
</script>
