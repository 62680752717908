import Vue from 'vue'

export function createprice(id, name, markup, tax) {
    return {
        id: id,
        rowType: 'PRICE',
        name: name,
        rows: [],
        show_summary: true,
        type: 'COMPUTED',
        fixedPrice: 0,
        markup: { ...markup },
        tax: { ...tax },
        roundPrice: {
            enabled: false,
            amount: 250,
            direction: 'UP',
        },
    }
}

export function rounded(number, fixed = true) {
    let rounded = Math.round((number + Number.EPSILON) * 100) / 100
    return fixed ? rounded.toFixed(2) : rounded
}

export function separator(number) {
    return number.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    })
}

export function roundPrice(price, direction, amount) {
    if (amount === 0) {
        return 0
    }
    if (direction === 'NEAREST') {
        return Math.round(price / amount) * amount
    } else if (direction === 'UP') {
        return Math.ceil(price / amount) * amount
    } else if (direction === 'DOWN') {
        return Math.floor(price / amount) * amount
    } else {
        return price
    }
}

export function formatDate(date) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear(),
        hours = (d.getHours() < 10 ? '0' : '') + d.getHours(),
        minutes = (d.getMinutes() < 10 ? '0' : '') + d.getMinutes()

    if (month.length < 2) month = '0' + month
    if (day.length < 2) day = '0' + day

    return [[year, month, day].join('-'), [hours, minutes].join('')].join('-T')
}

export function makeid() {
    var length = 16
    var result = ''
    var characters =
        'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
    var charactersLength = characters.length
    for (var i = 0; i < length; i++) {
        result += characters.charAt(
            Math.floor(Math.random() * charactersLength)
        )
    }
    return result
}

export function setmarkup(row, markup) {
    Vue.set(row, 'markup_total', markup)
    if (row.rows) {
        row.rows.forEach((r) => {
            setmarkup(r, markup)
        })
    }
}

export function dfs(obj, targetId) {
    if (obj.id === targetId) {
        return obj
    }
    if (obj.children) {
        for (let item of obj.children) {
            let check = dfs(item, targetId)
            if (check) {
                return check
            }
        }
    }
    return null
}

export function flatten(items) {
    const flat = []

    if (!Array.isArray(items)) return flat

    items.forEach((item) => {
        if (Array.isArray(item.rows)) {
            flat.push(...flatten(item.rows))
        } else {
            flat.push(item)
        }
    })

    return flat
}

export function deselectAllRows(price) {
    price.rows.forEach(function (row) {
        row.isSelected = false

        if (row.rows) {
            deselectAllRows(row)
        }
    })
}

export function findNodeById(data, nodeId) {
    // Check if the current node matches the ID
    if (data.id === nodeId) {
        return data
    }

    // If the node has children, iterate through them and search recursively
    if (data.children && data.children.length) {
        for (let child of data.children) {
            const result = findNodeById(child, nodeId)
            if (result) {
                // If the node is found in a subtree, return it
                return result
            }
        }
    }

    // If the node wasn't found in the current subtree, return null
    return null
}

export function deleteNodeById(data, nodeId) {
    // Base case: If the root itself is the node to delete, return null or an empty object
    if (data.id === nodeId) {
        return null // or return {} depending on requirements
    }

    if (!data.children || !data.children.length) {
        return data
    }

    // Iterate through children nodes
    for (let i = 0; i < data.children.length; i++) {
        // If the current child node is the one to delete
        if (data.children[i].id === nodeId) {
            // Remove the node
            data.children.splice(i, 1)
            return data // Stop searching once the node is found and removed
        }

        // If the current child node is not the one to delete, recurse on it
        data.children[i] = deleteNodeById(data.children[i], nodeId)
    }

    return data
}

// Make all external links open in new window
window.onload = function () {
    for (
        var tagArray = document.getElementsByTagName('a'), index = 0;
        index < tagArray.length;
        index++
    ) {
        var b = tagArray[index]
        b.getAttribute('href') &&
            b.hostname !== location.hostname &&
            (b.target = '_blank') &&
            (b.rel = 'noopener')
    }
}
