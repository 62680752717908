<template>
    <div
        class="bg-gray-200 border border-l-8 rounded"
        v-if="price"
        :class="[borderColor]"
    >
        <div class="px-1 flex items-center justify-between space-x-2">
            <div
                class="group relative handle cursor-move w-7 h-full p-2 flex items-center hover:text-gray-400"
            >
                <IconDrag></IconDrag>
                <div class="tooltip tooltip-left">Reorder card</div>
            </div>
            <router-link
                :to="price.to"
                class="block cursor-pointer flex-grow flex-row p-2 hover:opacity-50"
                :class="{ 'line-through': !active }"
            >
                <PriceCardDetails
                    :price="price"
                    :settings="settings"
                ></PriceCardDetails>
            </router-link>
            <div class="flex-shrink-0 h-full">
                <PriceContextMenu
                    :color="price.color"
                    :active="active"
                    @color-selected="colorSelected"
                    @delete="$emit('delete-price')"
                    @toggle-active="$emit('toggle-active', price)"
                ></PriceContextMenu>
            </div>
        </div>
    </div>
</template>

<script>
import Vue from 'vue'
import { format, formatDistance } from 'date-fns'
import { getRowColor } from '@/row'
import PriceContextMenu from '@/components/projects/PriceContextMenu'
import IconDrag from '@/components/icons/IconDrag'
import PriceCardDetails from '@/components/projects/PriceCardDetails'

export default {
    name: 'PriceCard',
    components: {
        PriceContextMenu,
        IconDrag,
        PriceCardDetails,
    },
    props: {
        price: Object,
        settings: Object,
        active: Boolean,
    },
    data() {
        return {
            //price: null
        }
    },
    computed: {
        updatedAt() {
            return this.price.updated_at
                ? formatDistance(new Date(this.price.updated_at), new Date(), {
                      addSuffix: true,
                  })
                : ''
        },
        createdAt() {
            return this.price.created_at
                ? format(new Date(this.price.created_at), 'PPPPp')
                : ''
        },
        borderColor() {
            return 'border-' + getRowColor(this.price) + '-400'
        },
    },
    methods: {
        colorSelected(color) {
            Vue.set(this.price, 'color', color)
        },
    },
    mounted() {
        //this.loadPrice()
    },
}
</script>
