<template>
    <svg fill="none" viewBox="0 0 24 24">
        <path
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1.5"
            d="M4.75 8H7.25"
        ></path>
        <path
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1.5"
            d="M12.75 8H19.25"
        ></path>
        <path
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1.5"
            d="M4.75 16H12.25"
        ></path>
        <path
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1.5"
            d="M17.75 16H19.25"
        ></path>
        <circle
            cx="10"
            cy="8"
            r="2.25"
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1.5"
        ></circle>
        <circle
            cx="15"
            cy="16"
            r="2.25"
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1.5"
        ></circle>
    </svg>
</template>

<script>
export default {
    name: 'IconSettingsSliders',
}
</script>
