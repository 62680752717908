<template>
    <div class="-mt-1">
        <button
            @click.prevent.stop="showMenu = !showMenu"
            aria-label="Show options for Price Summary"
            class="group relative rounded-full text-gray-800 hover:text-gray-500 focus:outline-none focus:text-gray-500 px-2 pt-2"
        >
            <div class="w-5 mx-auto inline-block">
                <IconSettingsSliders></IconSettingsSliders>
            </div>
            <div class="tooltip tooltip-left">Summary menu</div>
        </button>
        <div
            :class="{ hidden: !showMenu }"
            class="origin-top-left absolute z-10 w-max left-40 sm:left-auto rounded-md shadow-lg"
        >
            <div
                class="grid sm:grid-cols-2 gap-x-4 rounded-md bg-white border border-gray-200 shadow-xs"
            >
                <div
                    class="py-1 relative"
                    v-for="(option, index) in options"
                    :key="index"
                >
                    <PriceSummaryCheckbox
                        :model="option"
                    ></PriceSummaryCheckbox>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import PriceSummaryCheckbox from '@/components/price/PriceSummaryCheckbox'
import IconSettingsSliders from '@/components/icons/IconSettingsSliders'

export default {
    name: 'PriceSummaryMenu',
    components: {
        PriceSummaryCheckbox,
        IconSettingsSliders,
    },
    props: {
        options: {
            type: Array,
        },
    },
    data() {
        return {
            showMenu: false,
        }
    },
    methods: {
        hideMenu() {
            this.showMenu = false
        },
    },
    created: function () {
        window.addEventListener('click', this.hideMenu)
    },
    destroyed: function () {
        window.removeEventListener('click', this.hideMenu)
    },
}
</script>
