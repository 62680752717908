import Vue from 'vue'
import Vuex from 'vuex'

import prices from './modules/prices'
import projects from './modules/projects'
import settings from './modules/settings'
import session from './modules/session'

Vue.use(Vuex)

export default new Vuex.Store({
    actions: {
        loadExamples({ commit }) {
            /*if(window.localStorage.getItem('prices')){
                return
            }*/
            const examples = require('@/trifle-examples.json')
            if (examples.prices) {
                examples.prices.forEach((price) => {
                    commit('prices/ADD_PRICE', price, { root: true })
                })
            }

            if (examples.projects) {
                examples.projects.forEach((project) => {
                    commit('projects/ADD_PROJECT', project, { root: true })
                })
            }
        },
    },
    modules: {
        prices,
        projects,
        settings,
        session,
    },
})
