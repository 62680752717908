<template>
    <div class="flex items-end border-t-4 border-gray-800 pr-4">
        <div class="">
            <div class="text-xs text-gray-500">Price excl.</div>
            <div class="inline-block text-xl sm:text-2xl font-bold">
                <span class="text-gray-500 mr-1">{{ settings.currency }}</span>
                <span>{{ separator(totalPrice) }}</span>
            </div>
        </div>

        <div class="ml-4 sm:ml-8">
            <div class="text-xs text-gray-500">Price incl.</div>
            <div class="inline-block text-xl sm:text-2xl font-bold">
                <span class="text-gray-500 mr-1">{{ settings.currency }}</span>
                <span>{{ separator(totalPriceIncTax) }}</span>
            </div>
        </div>

        <div class="hidden inline-block ml-2">
            <button
                @click="openModal"
                class="group relative w-9 rounded-full text-gray-800 hover:text-gray-500 focus:text-gray-500 p-2"
            >
                <IconSettingsSliders></IconSettingsSliders>
                <div class="tooltip tooltip-left">
                    Project Headline Price options
                </div>
            </button>
        </div>
    </div>
</template>

<script>
import { separator } from '@/helpers'
import { mapState } from 'vuex'
import IconSettingsSliders from '@/components/icons/IconSettingsSliders'

export default {
    name: 'ProjectTotal',
    components: {
        IconSettingsSliders,
    },
    data() {
        return {
            separator,
        }
    },
    computed: {
        ...mapState('settings', {
            settings: 'settings',
        }),
        ...mapState('projects', {
            project: 'project',
        }),
        totalPrice() {
            if (this.project.prices?.length === 0) {
                return 0
            } else {
                return this.$store.getters['projects/totalPrice']
            }
        },
        totalPriceIncTax() {
            if (this.project.prices?.length === 0) {
                return 0
            } else {
                return this.$store.getters['projects/totalPriceIncTax']
            }
        },
    },
    methods: {
        openModal() {
            this.$root.$emit('show-headline-price-modal')
        },
    },
}
</script>
