<template>
    <div
        class="rowparent w-full relative flex flex-wrap justify-between rounded-sm mb-1 bg-gray-200"
        :class="[depth > 0 ? 'border-r-0 rounded-r-none' : '']"
    >
        <div class="flex justify-between print:flex-no-wrap">
            <div
                class="flex space-x-4 lg:space-x-0 lg:flex-col py-1 lg:py-0 mt-1"
            >
                <div
                    v-if="mode !== modes.REFERENCE"
                    class="flex items-center pl-2 relative"
                >
                    <RowNumber :number="number"></RowNumber>
                </div>

                <div
                    v-if="mode === modes.DEFAULT"
                    class="flex justify-between items-center space-x-4 lg:space-x-2 px-2 lg:px-1 print:hidden"
                >
                    <div
                        :class="{ 'w-4': !isOutdentable }"
                        class="inline-flex items-center"
                    >
                        <button
                            v-show="isOutdentable"
                            @click.prevent="outdentClicked"
                            aria-label="Outdent row"
                            class="group relative cursor-pointer text-gray-400 hover:text-gray-800"
                        >
                            <IconIndentRight></IconIndentRight>
                            <div class="tooltip tooltip-left">Outdent row</div>
                        </button>
                    </div>

                    <button
                        aria-label="Drag row to reorder"
                        class="group relative handle cursor-move inline-flex justify-center w-4 text-gray-400 hover:text-gray-800"
                    >
                        <IconDrag></IconDrag>
                        <div class="tooltip tooltip-left">Reorder row</div>
                    </button>

                    <div
                        :class="{ 'w-4': !isIndentable }"
                        class="inline-flex items-center"
                    >
                        <button
                            v-show="isIndentable"
                            @click.prevent="indentClicked"
                            aria-label="Indent row"
                            class="group relative cursor-pointer text-gray-400 hover:text-gray-800"
                        >
                            <IconIndentLeft></IconIndentLeft>
                            <div class="tooltip tooltip-left">Indent row</div>
                        </button>
                    </div>
                </div>
            </div>

            <!-- child toggle button -->
            <div class="inline-flex items-start mt-1">
                <div
                    class="h-8 opacity-25 border-l border-current print:border-0"
                ></div>
                <div
                    class="w-4 mx-2 flex flex-col items-center text-gray-400 hover:text-gray-800 focus:text-gray-800 cursor-pointer print:opacity-0"
                >
                    <RowChildToggle
                        class="w-3 print:hidden"
                        :model="model"
                    ></RowChildToggle>
                    <div class="text-xs leading-none">
                        {{ model.rows.length }}
                    </div>
                </div>
            </div>
        </div>

        <!-- debug -->
        <div v-if="settings.debug.showJsonModels" class="text-xs">
            <p v-if="model.id">id: {{ model.id }}</p>
            <p v-if="model.root_id">root_id: {{ model.root_id }}</p>
            <p v-if="model.parent_id">parent_id: {{ model.parent_id }}</p>
            <p v-if="model.link_id">link_id {{ model.link_id }}</p>
            <p v-if="model.price_id">price_id {{ model.price_id }}</p>
        </div>

        <div v-if="mode !== modes.DEFAULT" class="my-1 print:hidden">
            <SortButton class="mr-2" @click="$root.$emit('add-row', model)">
                <IconAdd></IconAdd>
            </SortButton>
        </div>
        <div v-else class="flex my-1 print:hidden">
            <RowAddMenu
                :disabled="editable"
                :showReferenceButton="true"
                @duplicateRow="duplicateRow"
                @addParentRowBelow="addParentRowBelow"
                @addBlankRowBelow="addNewRowBelow"
                @importRowBelow="importRowBelow"
                @addLinkedRowBelow="referenceRowBelow"
            ></RowAddMenu>
            <RowContextMenu
                :isReference="isReference"
                :source="model.source"
                :disabled="editable"
                :active="isActive"
                :color="model.color"
                @color-selected="colorSelected"
                @convertToIndependent="
                    $root.$emit('convert-to-independent', model)
                "
                @deleteRow="deleteThisRow"
                @toggle-active="toggleRowActive"
            ></RowContextMenu>
        </div>

        <!-- Hidden from Print-->
        <div
            class="print:hidden w-full lg:w-auto mx-2 lg:mx-0 flex lg:flex-1 mb-2 lg:my-1 border border-gray-800 rounded bg-white"
        >
            <RowDescription
                v-model="description"
                :isParent="true"
                :isReference="isReference"
                :mode="mode"
                :active="isActive"
                :disabled="mode !== modes.DEFAULT || editable"
            ></RowDescription>
            <button
                @click.prevent="toggleParentSummary"
                aria-label="Toggle showing Parent Summary"
                class="group relative w-8 flex h-full p-1 rounded-r bg-gray-200 border-l border-gray-800 hover:text-gray-400"
            >
                <div class="reflect-x w-full">
                    <IconParent></IconParent>
                </div>
                <div class="tooltip tooltip-right">
                    Show &amp; hide parent summary
                </div>
            </button>
        </div>

        <div
            class="print:hidden w-full lg:w-72 print:w-48 mx-2 mb-2 lg:my-1 ml-2 flex self-start border border-gray-800 rounded"
        >
            <RowSummary
                :model="model"
                :price="price"
                :active="isActive"
                :disabled="mode !== modes.DEFAULT || editable"
                :settings="settings"
                @toggle-markup="toggleMarkup"
            ></RowSummary>
        </div>

        <!-- Print only-->
        <div class="hidden print:flex print:flex-1 print:flex-nowrap">
            <div
                class="w-full lg:w-auto mx-2 lg:mx-0 flex lg:flex-1 mb-2 lg:my-1 border border-gray-800 rounded bg-white"
            >
                <RowDescription
                    v-model="description"
                    :isParent="true"
                    :isReference="isReference"
                    :mode="mode"
                    :active="isActive"
                    :disabled="mode !== modes.DEFAULT || editable"
                ></RowDescription>
                <button
                    @click.prevent="toggleParentSummary"
                    aria-label="Toggle showing Parent Summary"
                    class="group relative w-8 flex h-full p-1 rounded-r bg-gray-200 border-l border-gray-800 hover:text-gray-400"
                >
                    <div class="reflect-x w-full">
                        <IconParent></IconParent>
                    </div>
                    <div class="tooltip tooltip-right">
                        Show &amp; hide parent summary
                    </div>
                </button>
            </div>

            <div
                class="w-full lg:w-72 print:w-48 mx-2 mb-2 lg:my-1 ml-2 print:mr-0 flex self-start border border-gray-800 rounded"
            >
                <RowSummary
                    :model="model"
                    :price="price"
                    :active="isActive"
                    :disabled="mode !== modes.DEFAULT || editable"
                    :settings="settings"
                    @toggle-markup="toggleMarkup"
                ></RowSummary>
            </div>
        </div>

        <ParentSummary
            v-if="model.showParentSummary"
            v-model="model.uom"
            :model="model"
        ></ParentSummary>

        <!-- disabled overlay -->
        <transition name="fade">
            <div
                v-if="disabled"
                class="w-full h-full absolute left-0 top-0 bg-gray-100 opacity-90 rounded-sm"
            ></div>
        </transition>

        <!-- Start: Children -->
        <draggable
            class="pl-4 sm:pl-8 w-full pt-1"
            :class="[bgColor]"
            handle=".handle"
            v-if="model.show_children"
            v-model="model.rows"
        >
            <Row
                v-for="(row, index) in model.rows"
                :model="row"
                :parent="model"
                :mode="mode"
                :rootRow="rootRow"
                :key="index"
                :index="index"
                :depth="depth + 1"
                :number="number"
                :disabled="rowDisabled(row)"
                :editable="rowIsBeingEdited(row)"
                @outdent-row="outdentRow"
            ></Row>
        </draggable>
        <!-- End: Children -->
    </div>
</template>

<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'
import draggable from 'vuedraggable'
import { modes } from '@/constants'

import SortButton from '@/components/ui/SortButton'
import IconDrag from '@/components/icons/IconDrag'
import IconAdd from '@/components/icons/IconAdd'
import IconParent from '@/components/icons/IconParent'
import IconIndentRight from '@/components/icons/IconIndentRight'
import IconIndentLeft from '@/components/icons/IconIndentLeft'

import RowNumber from '@/components/row/RowNumber'
import RowChildToggle from '@/components/row/RowChildToggle'
import RowAddMenu from '@/components/row/RowAddMenu'
import RowDescription from '@/components/row/description/RowDescription'
import RowSummary from '@/components/row/RowSummary'
import RowContextMenu from '@/components/row/RowContextMenu'
import ParentSummary from '@/components/row/ParentSummary'

import { flatten } from '@/helpers'
import { getRowColor } from '@/row'

export default {
    name: 'RowParent',
    components: {
        draggable,
        SortButton,
        IconDrag,
        IconAdd,
        IconParent,
        IconIndentRight,
        IconIndentLeft,
        RowNumber,
        RowChildToggle,
        RowAddMenu,
        RowDescription,
        RowSummary,
        RowContextMenu,
        ParentSummary,
    },
    props: {
        model: {
            type: Object,
            default() {
                return {}
            },
        },
        parent: {
            type: Object,
            default() {
                return {}
            },
        },
        rootRow: {
            type: Object,
            default() {
                return {}
            },
        },
        depth: {
            type: Number,
            default: 0,
        },
        index: {
            type: Number,
            default: 0,
        },
        number: {
            type: String,
            default: '1',
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        editable: {
            type: Boolean,
            default: true,
        },
        mode: {
            type: String,
            default: 'DEFAULT',
        },
    },
    data() {
        return {
            modes,
            showChildren: true,
        }
    },
    computed: {
        ...mapGetters('prices', {
            price: 'price',
        }),
        ...mapGetters('settings', {
            settings: 'settings',
        }),
        isOutdentable() {
            return this.depth > 0
        },
        isIndentable() {
            return true
        },
        bgColor() {
            return 'bg-' + getRowColor(this.model) + '-300'
        },
        isReference() {
            return this.model.is_reference // && this.mode !== 'ADD' // !== undefined ? true : false;
        },
        isActive() {
            if (this.mode === modes.DEFAULT) {
                return this.model.active
            } else {
                return true
            }
        },
        description: {
            get() {
                return this.model.is_reference && this.mode !== modes.DEFAULT
                    ? this.model.linked_description
                    : this.model.description
            },
            set(newValue) {
                if (this.model.is_reference && this.mode !== modes.DEFAULT) {
                    //this.model.linked_description = newValue
                } else {
                    this.model.description = newValue
                }
            },
        },
        reference_model() {
            let price = this.$store.state.prices.prices.filter(
                (p) => p.id === this.model.price_id
            )[0]
            //console.log(this.$store.state.prices.prices.filter(p => p.id === this.model.price_id))
            let rows = flatten(price.rows)

            return rows.filter((row) => {
                return row.id === this.model.link_id
            })[0]
        },
    },
    methods: {
        toggleParentSummary() {
            if (
                !Object.prototype.hasOwnProperty.call(
                    this.model,
                    'showParentSummary'
                )
            ) {
                Vue.set(this.model, 'showParentSummary', false)
            }

            let showSummary = this.model.showParentSummary
            Vue.set(this.model, 'showParentSummary', !showSummary)
        },
        outdentClicked() {
            let payload = {
                row: this.model,
                parent: this.parent,
                index: this.index,
            }
            this.$emit('outdent-row', payload)
            //this.$store.dispatch('prices/outdentRow', payload)
        },
        indentClicked() {
            let payload = {
                row: this.model,
                parent: this.parent,
                index: this.index,
            }
            this.$store.dispatch('prices/indentRow', payload)
        },
        outdentRow(payload) {
            let newPayload = {
                row: payload.row,
                parent: payload.parent,
                root: this.parent,
                index: this.index + 1,
            }
            this.$store.dispatch('prices/outdentRow', newPayload)
        },
        toggleMarkup() {
            Vue.set(this.model, 'markup_total', !this.model.markup_total)
        },
        colorSelected(color) {
            /*let payload = {
        row: this.model,
        color: color
      }
      this.$store.dispatch('prices/setRowColor', payload)*/
            Vue.set(this.model, 'color', color)
        },
        toggleRowActive() {
            if (!Object.prototype.hasOwnProperty.call(this.model, 'active')) {
                Vue.set(this.model, 'active', true)
            }
            this.model.active = !this.model.active
            this.setChildrenActive(this.model.rows)
        },
        setChildrenActive(rows) {
            rows.forEach((row) => {
                row.active = this.model.active
                if (row.rows && row.rows.length > 0) {
                    this.setChildrenActive(row.rows)
                }
            })
        },
        rowDisabled(row) {
            if (this.$store.getters['prices/editingReference'] !== '') {
                if (
                    row.id === this.$store.getters['prices/editingReference'] ||
                    row.link_id ===
                        this.$store.getters['prices/editingReference']
                ) {
                    return false
                }
                return true
            } else {
                return false
            }
        },
        rowIsBeingEdited(row) {
            if (row.is_reference) {
                if (this.$store.getters['prices/editingReference'] !== '') {
                    if (
                        row.id ===
                            this.$store.getters['prices/editingReference'] ||
                        row.link_id ===
                            this.$store.getters['prices/editingReference']
                    ) {
                        return true
                    }
                }
            }
            return false
        },
        addParentRowBelow() {
            let model = this.parent
            let index = this.index + 1
            this.$store.dispatch('prices/addParentRow', { model, index })
        },
        addParentRowChild() {
            let model = this.model
            let index = this.index + 1

            this.$store.dispatch('prices/addParentToParentModelAtIndex', {
                model,
                index,
            })
        },
        addNewRowBelow() {
            //this.$root.$emit('add-new-row-below', this.parent, this.index + 1)
            //this.$emit('add-new-row-below', this.index + 1)
            //add the row to our parent so it appears below this one
            let model = this.parent
            let index = this.index + 1
            this.$store.dispatch('prices/addRowToModelAtIndex', {
                model,
                index,
            })
        },
        addNewRowChild() {
            console.log('addBlankRowChild')

            let model = this.model
            let index = this.index + 1
            //this.$store.dispatch('prices/addChildToModelAtIndex', { model, index })

            this.$store.dispatch('prices/addChildToParentModelAtIndex', {
                model,
                index,
            })
        },
        importRowBelow() {
            console.log('importRowBelow ROW')
            this.$root.$emit('show-import-modal', this.parent, this.index + 1)
        },
        importRowChild() {
            console.log('importRowChild ROW')
            this.$root.$emit('show-import-modal', this.model, this.index + 1)
        },
        referenceRowBelow() {
            console.log('addLinkedRowBelow ROW')
            this.$root.$emit(
                'show-reference-modal',
                this.parent,
                this.index + 1
            )
        },
        referenceRowChild() {
            console.log('addLinkedRowChild')
            let index = this.model.rows ? this.model.rows.length : 0
            this.$root.$emit('show-reference-modal', this.model, index)
        },
        deleteThisRow() {
            if (window.confirm('Are you sure you want to delete this row?')) {
                this.model.depth = this.depth
                this.model.index = this.index

                let row = this.model
                let model = this.parent
                this.$store.dispatch('prices/deleteRowFromModel', {
                    row,
                    model,
                })
            }
        },
        duplicateRow() {
            let row = this.model
            let model = this.parent
            let index = this.index + 1
            this.$store.dispatch('prices/duplicateRowInModelAtIndex', {
                row,
                model,
                index,
            })
        },
    },
}
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.25s;
}
.fade-enter,
.fade-leave-to {
    opacity: 0;
}
</style>
