<template>
    <svg
        PARENT
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M 2.991 6.456 L 21.01 6.456 M 2.991 12.001 L 15.466 12.001 M 2.991 17.544 L 15.386 17.544"
        />
    </svg>
</template>

<script>
export default {
    name: 'IconParent',
}
</script>
