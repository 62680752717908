<template>
    <div class="relative w-auto">
        <div class="">
            <button
                @click.prevent.stop="showPriceMenu = !showPriceMenu"
                aria-label="Show Price menu"
                class="group relative rounded-full px-2 py-1 flex items-center text-gray-800 hover:text-gray-500 focus:outline-none focus:text-gray500"
            >
                <div class="w-5 h-5 mr-2">
                    <IconCog></IconCog>
                </div>
                <div class="w-3 h-3">
                    <IconChevron></IconChevron>
                </div>
                <div class="tooltip tooltip-right mt-2">Price menu</div>
            </button>
        </div>
        <div
            :class="{ hidden: !showPriceMenu }"
            class="origin-top-right absolute z-10 right-0 mt-2 w-auto border border-gray-200 rounded-md shadow-lg"
        >
            <!-- this is the menu-->
            <div class="rounded-md bg-white shadow-xs py-2 divide-y">
                <button
                    @click.prevent.stop="duplicatePrice"
                    class="menu-options-btn"
                >
                    <div class="w-5 h-5">
                        <IconDuplicate></IconDuplicate>
                    </div>
                    <div class="truncate">Duplicate Price</div>
                </button>

                <button
                    @click.prevent.stop="exportPrice"
                    class="menu-options-btn"
                >
                    <div class="w-5 h-5">
                        <IconUpload></IconUpload>
                    </div>
                    <div class="truncate">Export Price</div>
                </button>

                <button
                    @click.prevent.stop="importPrice"
                    class="menu-options-btn"
                >
                    <div class="w-5 h-5">
                        <IconDownload></IconDownload>
                    </div>
                    <div class="truncate">Import Price</div>
                </button>

                <button
                    @click.prevent.stop="deletePrice"
                    class="menu-options-btn"
                >
                    <div class="w-5 h-5">
                        <IconTrash></IconTrash>
                    </div>
                    <div class="truncate">Delete Price</div>
                </button>

                <input
                    type="file"
                    class="hidden"
                    @change="onFileChange"
                    ref="fileInput"
                />
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import IconCog from '@/components/icons/IconCog'
import IconChevron from '@/components/icons/IconChevron'
import IconDuplicate from '@/components/icons/IconDuplicate'
import IconDownload from '@/components/icons/IconDownload'
import IconUpload from '@/components/icons/IconUpload'
import IconTrash from '@/components/icons/IconTrash'
import { formatDate } from '@/helpers'

export default {
    name: 'PriceMenu',
    components: {
        IconCog,
        IconChevron,
        IconDuplicate,
        IconDownload,
        IconUpload,
        IconTrash,
    },
    data() {
        return {
            showPriceMenu: false,
        }
    },
    computed: {
        ...mapState('prices', {
            price: 'price',
        }),
    },
    methods: {
        duplicatePrice() {
            this.hidePriceMenu()
            this.$emit('duplicatePrice')
        },
        deletePrice() {
            if (
                window.confirm(
                    'Are you sure? \n \nThis will delete this Price.'
                )
            ) {
                this.$emit('delete-price')
            }
            this.hidePriceMenu()
        },
        onFileChange(e) {
            let files = e.target.files || e.dataTransfer.files

            if (!files.length) {
                return
            }

            files.forEach((file) => {
                let reader = new FileReader()
                reader.onload = (e) => {
                    let price = JSON.parse(e.target.result)
                    this.$store.dispatch('prices/addRowsToPrice', price.rows)
                }
                reader.readAsText(file)
            })
        },
        importPrice() {
            this.$refs.fileInput.click()
        },
        exportPrice() {
            let now = new Date()
            let filename = this.price.name.replace(/\s+/g, '-')
            filename = filename + '-' + this.price.id + '-'
            filename = filename + formatDate(now)
            var a = document.createElement('a')
            var file = new Blob([JSON.stringify(this.price)], {
                type: 'text/plain',
            })
            a.href = URL.createObjectURL(file)

            a.download = filename + '.json'
            a.click()
        },
        hidePriceMenu() {
            this.showPriceMenu = false
        },
    },
    created() {
        window.addEventListener('click', this.hidePriceMenu)
    },
    destroyed() {
        window.removeEventListener('click', this.hidePriceMenu)
    },
}
</script>
