//import { makeid } from './helpers';
import Vue from 'vue'
import store from '@/store'
import { makeid } from '@/helpers'

export function createrow(id) {
    return {
        id: id,
        description: '',
        active: true,
        uom: store.getters['settings/defaultUom'],
        qty: 1,
        rate: null,
        show_description: true,
    }
}

export function setrowdefaults(rows) {
    rows.forEach((row) => {
        if (!Object.prototype.hasOwnProperty.call(row, 'active')) {
            Vue.set(row, 'active', true)
        }

        if (!Object.prototype.hasOwnProperty.call(row, 'show_description')) {
            Vue.set(row, 'show_description', true)
        }

        if (!Object.prototype.hasOwnProperty.call(row, 'markup_total')) {
            Vue.set(row, 'markup_total', false)
        }

        if (row.rows && row.rows.length > 0) {
            if (!Object.prototype.hasOwnProperty.call(row, 'show_children')) {
                Vue.set(row, 'show_children', true)
            }
            setrowdefaults(row.rows)
        }
    })
}

export function createparent(id) {
    return {
        id: id,
        description: '',
        active: true,
        rows: [],
        qty: 1,
    }
}

export function makeRowLinked(row) {
    //row.source = 'Global';
    makeChildRowsLinked(row)
    return row
}

export function makeChildRowsLinked(row) {
    row.link_id = row.id
    //row.linked_description = row.description;

    //let id = makeid(4);
    //row.id = id;
    row.description = ''

    if (row.rows) {
        row.rows.forEach((child) => {
            makeChildRowsLinked(child)
        })
    }
}

export function referencechildren(row, clone) {
    //console.log(row, clone)

    //give our cloned row a new id
    Vue.set(clone, 'id', makeid())
    Vue.set(clone, 'is_reference', true)

    Vue.set(clone, 'description', '')
    if (!clone.link_id) {
        Vue.set(clone, 'link_id', row.id)
        Vue.delete(clone, 'linked_description')

        Vue.set(row, 'is_reference', true)
        Vue.set(row, 'link_id', row.id) //set the link_id to itself
        if (!row.linked_description) {
            Vue.set(row, 'linked_description', row.description)
            Vue.set(row, 'description', '')
        }
    }

    //Vue.set(clone, 'price_id', row.price_id)

    // console.log('row.id:', row.id, 'link_id:', row.link_id, 'price_id:', row.price_id)
    // console.log('clone.id:', clone.id, 'link_id:', clone.link_id, 'price_id:', clone.price_id)

    //clone.link_id = row.id

    if (row.rows) {
        for (let i = 0; i < row.rows.length; i++) {
            let child = row.rows[i]
            let cloneChild = clone.rows[i]
            referencechildren(child, cloneChild)
        }
    }
}

export function importchildren(row, clone) {
    //console.log(row, clone)

    //give our cloned row a new id
    Vue.set(clone, 'id', makeid())
    Vue.set(clone, 'is_reference', true)

    Vue.set(clone, 'description', '')
    if (!clone.link_id) {
        Vue.set(clone, 'link_id', row.id)
        Vue.delete(clone, 'linked_description')

        Vue.set(row, 'is_reference', true)
        Vue.set(row, 'link_id', row.id) //set the link_id to itself
        if (!row.linked_description) {
            Vue.set(row, 'linked_description', row.description)
            Vue.set(row, 'description', '')
        }
    }

    //Vue.set(clone, 'price_id', row.price_id)

    // console.log('row.id:', row.id, 'link_id:', row.link_id)
    // console.log('clone.id:', clone.id, 'link_id:', clone.link_id)

    //clone.link_id = row.id

    if (row.rows) {
        for (let i = 0; i < row.rows.length; i++) {
            let child = row.rows[i]
            let cloneChild = clone.rows[i]
            referencechildren(child, cloneChild)
        }
    }
}

export function getRowColor(model) {
    if (model.color) {
        return model.color
        //}else if(parent.color){
        //    return parent.color + '-300'
    } else {
        return 'gray'
    }
}
/*
export function getallfamilyids(row){
    let ids = []
    let hasParent = true

}*/
