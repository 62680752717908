<template>
    <!-- Start: Add modal -->
    <div
        class="fixed z-20 bottom-0 top-0 inset-x-0 px-4 py-4 flex items-center max-w-xl mx-auto"
    >
        <div class="fixed inset-0 transition-opacity">
            <div
                @click="$emit('close')"
                class="absolute inset-0 bg-gray-500 opacity-75"
            ></div>
        </div>

        <div
            class="relative bg-white rounded-lg p-4 overflow-auto lg:overflow-hidden shadow-xl transform transition-all md:p-6 md:pr-3 w-full"
        >
            <div class="absolute top-0 right-0">
                <button
                    class="p-4 text-gray-400 hover:text-gray-500 focus:outline-none focus:text-gray-500 transition ease-in-out duration-150"
                    @click="$emit('close')"
                    type="button"
                >
                    <IconClose></IconClose>
                </button>
            </div>
            <div class="">
                <h3
                    class="w-full text-xl leading-6 font-medium text-gray-900 mb-2"
                >
                    {{ price ? 'Edit price name' : 'Create a new price' }}
                </h3>

                <form method="post" @submit.stop.prevent="createPrice">
                    <div
                        class="form-group md:flex justify-between space-y-2 md:space-x-2 items-end"
                    >
                        <div class="flex-1">
                            <label
                                class="block text-sm font-medium leading-5 text-gray-700"
                                >Name</label
                            >

                            <input
                                class="mt-1 form-input block w-full py-2 px-3 text-xl border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out"
                                name="name"
                                v-model="name"
                                type="text"
                                placeholder="Price name"
                            />
                        </div>
                        <div class="flex justify-end">
                            <button
                                class="py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-gray-800 shadow-sm hover:bg-gray-500 focus:outline-none focus:shadow-outline-blue active:bg-gray-600 transition duration-150 ease-in-out"
                                type="submit"
                            >
                                {{ price ? 'Save' : 'Create' }}
                            </button>
                        </div>
                    </div>
                </form>

                <div v-if="!price">
                    <div class="text-center mb-6 md:my-8">
                        <div
                            class="text-xl leading-6 font-medium text-gray-900"
                        >
                            Or
                        </div>
                    </div>

                    <div class="flex justify-between space-x-2 items-end">
                        <div>
                            <h3
                                class="text-xl leading-6 font-medium text-gray-900 mb-2"
                            >
                                Import from a file
                            </h3>
                            <p class="text-sm text-gray-700">
                                This will be a <code>.json</code> file
                                previously created in this&nbsp;app.
                            </p>
                        </div>
                        <div>
                            <button
                                @click.prevent.stop="importPrice"
                                class="py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-gray-800 shadow-sm hover:bg-gray-500 focus:outline-none focus:shadow-outline-blue active:bg-gray-600 transition duration-150 ease-in-out"
                            >
                                Import
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <input
            type="file"
            class="hidden"
            accept="application/JSON"
            @change="onFileChange"
            ref="fileInput"
        />
    </div>
    <!-- End: Add modal -->
</template>

<script>
import IconClose from '@/components/icons/IconClose'

export default {
    name: 'PriceModal',
    components: {
        IconClose,
    },
    props: {
        price: Object,
    },
    data() {
        return {
            name: '',
        }
    },
    computed: {},
    mounted() {
        this.name = this.price ? this.price.name : ''
    },
    methods: {
        createPrice() {
            this.$emit('save-price', this.name)
        },
        onFileChange(e) {
            let files = e.target.files || e.dataTransfer.files

            if (!files.length) {
                return
            }

            files.forEach((file) => {
                let reader = new FileReader()
                reader.onload = (e) => {
                    let price = JSON.parse(e.target.result)
                    this.$emit('price-imported', price)
                    //this.$store.dispatch('prices/addRowsToPrice', price.rows)
                }
                reader.readAsText(file)
            })
        },
        importPrice() {
            this.$refs.fileInput.click()
        },
    },
}
</script>
