<template>
    <div id="app" class="APP bg-gray-50">
        <div v-if="!settings.bannerHidden" class="bg-gray-300 border-2 border-red-400 py-1 text-center">
            <div>
                Your data is only saved to <em>your</em> browser, not the cloud.
                <a
                    href="https://buttondown.email/trifleapp?utm_source=use_trifle_app"
                    class="bg-gray-800 focus:bg-gray-500 focus:outline-none hover:bg-gray-500 inline-block leading-none px-4 py-1 rounded text-white font-bold text-sm"
                    >Subscribe to find out more</a
                >
                <button @click="hideBanner" class="ml-2 bg-gray-400 focus:bg-gray-500 focus:outline-none hover:bg-gray-500 inline-block leading-none px-4 py-1 rounded text-sm">Hide</button>
            </div>
        </div>
        <div
            class="flex h-screen overflow-hidden print:h-full print:overflow-visible"
        >
            <Split class="print:hidden">
                <SplitArea :size="sidebarSize" :minSize="0">
                    <SidebarDesktop></SidebarDesktop>
                </SplitArea>
                <SplitArea :size="mainPanelSize">
                    <SidebarMobile ref="sidebar"></SidebarMobile>
                    <MainPanel @open-sidebar="openSidebar"></MainPanel>
                </SplitArea>
            </Split>
            <div class="hidden print:block">
                <MainPanel @open-sidebar="openSidebar"></MainPanel>
            </div>
        </div>

        <PriceModal
            v-if="showNewPriceModal"
            @save-price="createPrice"
            @price-imported="importPrice"
            @close="showNewPriceModal = false"
        ></PriceModal>
        <PriceModal
            v-if="showEditPriceModal"
            :price="price"
            @save-price="editPrice"
            @close="showEditPriceModal = false"
        ></PriceModal>
        <ProjectModal
            v-if="showNewProjectModal"
            @close="showNewProjectModal = false"
            @create-project="createProject"
        ></ProjectModal>
        <ProjectModal
            v-if="showEditProjectModal"
            :project="project"
            @close="showEditProjectModal = false"
            @create-project="editProject"
        ></ProjectModal>

        <NewFolderModal
            v-if="showNewFolderModal"
            @close="showNewFolderModal = false"
            @create-folder="createFolder"
        ></NewFolderModal>

        <div v-if="settings.debug.showJsonModels">
            <h1>Active Node:</h1>
            <pre>
        {{ activeNode }}
      </pre
            >
            <h1>Folders:</h1>
            <pre>
        {{ folders }}
      </pre
            >
            <h1>Price:</h1>
            <pre>
        {{ price }}
      </pre
            >
            <hr />
            <h1>Project:</h1>
            <pre>
        {{ project }}
      </pre
            >
            <hr />
            <h1>Settings:</h1>
            <pre>
        {{ settings }}
      </pre
            >
        </div>
    </div>
</template>

<script>
import Vue from 'vue'
import { mapGetters, mapActions } from 'vuex'
import SidebarMobile from '@/components/sidebar/SidebarMobile'
import SidebarDesktop from '@/components/sidebar/SidebarDesktop'
import MainPanel from '@/components/MainPanel'
import PriceModal from '@/components/modals/PriceModal'
import ProjectModal from '@/components/modals/ProjectModal'
import NewFolderModal from '@/components/modals/NewFolderModal'
import { makeid, createprice } from '@/helpers'

export default {
    name: 'App',
    components: {
        SidebarMobile,
        SidebarDesktop,
        MainPanel,
        PriceModal,
        ProjectModal,
        NewFolderModal,
    },
    data() {
        return {
            showNewPriceModal: false,
            showNewProjectModal: false,
            showNewFolderModal: false,
            showEditPriceModal: false,
            showEditProjectModal: false,
            priceToDuplicate: null,
            projectToDuplicate: null,
            isPortrait: false,
        }
    },
    computed: {
        ...mapGetters('settings', {
            settings: 'settings',
        }),
        ...mapGetters('prices', {
            prices: 'prices',
            price: 'price',
            folders: 'folders',
            activeNode: 'activeNode',
        }),
        ...mapGetters('projects', {
            project: 'project',
        }),
        sidebarSize() {
            return this.isPortrait ? 0 : 20
        },
        mainPanelSize() {
            return this.isPortrait ? 99 : 80
        },
    },
    methods: {
        ...mapActions('prices', {
            setPrice: 'setPrice',
        }),
        newPrice() {
            this.showNewPriceModal = true
            this.$refs.sidebar.close()
        },
        newProject() {
            this.showNewProjectModal = true
            this.$refs.sidebar.close()
        },
        newFolder() {
            this.showNewFolderModal = true
            this.$refs.sidebar.close()
        },
        closeNewPriceModal() {
            this.showNewPriceModal = false
        },
        closeNewFolderModal() {
            this.showNewFolderModal = false
        },
        assignPriceIdToRows(parent) {
            parent.rows.forEach((row) => {
                row.price_id = parent.price_id ? parent.price_id : parent.id
                if (row.rows) {
                    this.assignPriceIdToRows(row)
                }
            })
        },
        createPrice(name) {
            if (this.priceToDuplicate) {
                let clone = JSON.parse(JSON.stringify(this.priceToDuplicate))
                this.priceToDuplicate = null
                clone.id = makeid()
                clone.name = name

                this.assignPriceIdToRows(clone)

                this.$store.dispatch('prices/addPrice', clone)
                this.closeNewPriceModal()
                this.$router.push({
                    name: 'price',
                    params: { priceid: clone.id },
                })
            } else {
                let newPrice = createprice(
                    makeid(),
                    name,
                    this.settings.markup,
                    this.settings.tax
                )
                this.$store.dispatch('prices/addPrice', newPrice)
                this.closeNewPriceModal()
                this.$router.push({
                    name: 'price',
                    params: { priceid: newPrice.id },
                })
            }
        },
        importPrice(price) {
            const id = makeid()
            Vue.set(price, 'id', id)
            this.assignPriceIdToRows(price)
            this.$store.dispatch('prices/addPrice', price)
            this.closeNewPriceModal()
            this.$router.push({ name: 'price', params: { priceid: id } })
        },
        editPrice(price) {
            this.$store.dispatch('prices/updatePrice', price)
            this.showEditPriceModal = false
        },
        editProject(project) {
            this.$store.dispatch('projects/updateProject', project)
            this.showEditProjectModal = false
        },
        duplicatePrice(price) {
            this.showNewPriceModal = true
            this.priceToDuplicate = price
        },
        deletePrice(price) {
            this.$store.dispatch('prices/deletePrice', price.id)
            this.$router.push({ name: 'home' })
        },
        createFolder(folder) {
            let newFolder = {
                id: makeid(),
                name: folder.name,
            }

            this.$store.dispatch('prices/createFolder', newFolder)
            this.closeNewFolderModal()
        },
        createProject(name) {
            if (this.projectToDuplicate) {
                let clone = JSON.parse(JSON.stringify(this.projectToDuplicate))
                this.projectToDuplicate = null
                clone.id = makeid()
                clone.name = name

                this.$store.dispatch('projects/addProject', clone)
                this.showNewProjectModal = false
                this.$router.push({
                    name: 'project',
                    params: { projectid: clone.id },
                })
            } else {
                let date = new Date()
                let newProject = {
                    id: makeid(),
                    rowType: 'PROJECT',
                    name: name,
                    prices: [],
                    show_summary: true,
                    created_at: date,
                    updated_at: date,
                    type: 'COMPUTED',
                    fixedPrice: 0,
                    roundPrice: {
                        enabled: false,
                        amount: 250,
                        direction: 'UP',
                    },
                }
                this.$store.dispatch('projects/addProject', newProject)
                this.showNewProjectModal = false
                this.$router.push({
                    name: 'project',
                    params: { projectid: newProject.id },
                })
            }
        },
        openSidebar() {
            this.$refs.sidebar.open()
        },
        editName() {
            if (this.price) {
                this.showEditPriceModal = true
            }

            if (this.project) {
                this.showEditProjectModal = true
            }
        },
        duplicateProject(project) {
            this.showNewProjectModal = true
            this.projectToDuplicate = project
        },
        deleteProject(project) {
            this.$store.dispatch('projects/deleteProject', project.id)
            this.$router.push({ name: 'home' })
        },
        windowResized() {
            if (window.innerWidth < 768) {
                this.isPortrait = true
            } else {
                this.isPortrait = false
            }
        },
        hideBanner() {
            this.$store.dispatch('settings/hideBanner')
        }
    },
    created() {
        let id = localStorage.getItem('trifle-session-id')
        if (!id) {
            id = makeid()
            localStorage.setItem('trifle-session-id', id)
        }
        this.$store.dispatch('session/setSessionId', id)
    },
    mounted() {
        this.$root.$on('new-price', this.newPrice)
        this.$root.$on('new-project', this.newProject)
        this.$root.$on('new-folder', this.newFolder)
        this.$root.$on('close-new-folder-modal', this.closeNewfolderModal)
        this.$root.$on('duplicate-price', this.duplicatePrice)
        this.$root.$on('delete-price', this.deletePrice)
        this.$root.$on('edit-name', this.editName)
        this.$root.$on('delete-project', this.deleteProject)
        this.$root.$on('duplicate-project', this.duplicateProject)

        window.addEventListener('resize', this.windowResized)
        this.windowResized()
    },
    beforeDestroy() {
        this.$root.$off('new-price', this.newPrice)
        this.$root.$off('new-project', this.newProject)
        this.$root.$off('new-folder', this.newFolder)
        this.$root.$off('close-new-folder-modal', this.closeNewfolderModal)
        this.$root.$off('duplicate-price', this.duplicatePrice)
        this.$root.$off('delete-price', this.deletePrice)
        this.$root.$off('edit-name', this.editName)
        this.$root.$off('delete-project', this.deleteProject)
        this.$root.$off('duplicate-project', this.duplicateProject)

        window.removeEventListener('resize', this.windowResized)
    },
}
</script>

<style></style>
