<template>
    <div
        class="hidden lg:block print:block border-t-4 border-gray-800 leading-none"
    >
        <div
            class="flex flex-wrap overflow-hidden text-xs text-gray-500 print:pt-1"
        >
            <div class="flex-initial w-48 pl-4 mr-2">Row #</div>
            <div class="flex-1 flex justify-between w-auto">
                <div>Description</div>
                <div class="mr-1 print:hidden">UoM</div>
            </div>
            <div
                class="flex-initial flex items-stretch text-center w-72 mx-2 print:pr-2"
            >
                <div class="flex-0 w-16 pl-1 print:w-full print:text-right">
                    Qty
                </div>
                <div class="flex-1 pl-1 print:hidden">Rate</div>
                <div class="flex-1 pl-1 print:hidden">Row Total</div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'RowsHeader',
}
</script>
