<template>
    <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M4.75 5.75H13.25"
            stroke="currentColor"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
        ></path>
        <path
            d="M4.75 9.75H10.25"
            stroke="currentColor"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
        ></path>
        <path
            d="M19.25 8.75L15.75 12L19.25 15.25"
            stroke="currentColor"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
        ></path>
        <path
            d="M4.75 18.25H13.25"
            stroke="currentColor"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
        ></path>
        <path
            d="M4.75 14.25H10.25"
            stroke="currentColor"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
        ></path>
    </svg>
</template>

<script>
// via iconic.app
export default {
    name: 'IconIndentRight',
}
</script>
