<template>
    <div :class="{ hidden: !sidebarOpen }" class="md:hidden">
        <div
            @click="closeSidebar()"
            class="fixed inset-0 z-30 transition-opacity ease-linear duration-300"
        >
            <div
                @click="closeSidebar()"
                class="absolute inset-0 bg-gray-600 opacity-75"
            ></div>
        </div>
        <div class="fixed inset-0 flex z-40">
            <div
                :class="{ hidden: !sidebarOpen }"
                class="flex-1 flex flex-col w-sidebar w-full py-4 bg-white transform ease-in-out duration-300"
            >
                <div class="absolute top-0 right-0 -mr-14 p-1">
                    <button
                        :class="{ hidden: !sidebarOpen }"
                        @click="closeSidebar"
                        class="flex items-center justify-center h-12 w-12 rounded-full focus:outline-none focus:bg-gray-600"
                    >
                        <svg
                            class="h-6 w-6 text-white"
                            stroke="currentColor"
                            fill="none"
                            viewBox="0 0 24 24"
                        >
                            <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                d="M6 18L18 6M6 6l12 12"
                            />
                        </svg>
                    </button>
                </div>
                <div class="flex-shrink-0 flex items-center px-2">
                    <div class="" @click="closeSidebar">
                        <router-link :to="{ name: 'home' }">
                            <Logotype class="logotype w-20"></Logotype>
                        </router-link>
                    </div>
                </div>
                <div class="mt-5 px-2 flex-1">
                    <div class="mb-4 relative">
                        <NewButton></NewButton>
                    </div>
                    <div class="mb-4 relative">
                        <div
                            class="border-b border-gray-300 text-xs text-gray-500 mb-1"
                        >
                            Prices & Projects
                        </div>
                        <SidebarNav></SidebarNav>
                    </div>
                </div>
            </div>
            <div class="flex-shrink-0 w-full" @click="closeSidebar">
                <!-- Force sidebar to shrink to fit close icon -->
            </div>
        </div>
    </div>
</template>

<script>
import SidebarNav from '@/components/sidebar/SidebarNav.vue'
import NewButton from '@/components/NewButton'
import Logotype from '@/components/sidebar/Logotype'

export default {
    name: 'SidebarMobile',
    components: {
        SidebarNav,
        NewButton,
        Logotype,
    },
    data() {
        return {
            sidebarOpen: false,
        }
    },
    watch: {
        '$route.params.priceid': function () {
            this.sidebarOpen = false
        },
    },
    methods: {
        closeSidebar: function () {
            this.sidebarOpen = false
            this.$emit('sidebarClosed')
        },
        open() {
            this.sidebarOpen = true
        },
        close() {
            this.sidebarOpen = false
        },
    },
}
</script>
