import Vue from 'vue'
import App from './App.vue'
import Row from '@/components/row/Row'
import VueSplit from 'vue-split-panel'

import router from './router'
import store from './store'

//register Row globally to prevent recursion errors
Vue.component('Row', Row)

Vue.use(VueSplit)

import '@/assets/css/tailwind.css'
import '@/assets/css/custom-styles.css'

Vue.config.productionTip = false

new Vue({
    router,
    store,
    render: (h) => h(App),
}).$mount('#app')
