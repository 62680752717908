<template>
    <div class="relative w-auto -mt-1">
        <div class="">
            <button
                @click.prevent.stop="showProjectMenu = !showProjectMenu"
                class="group relative rounded-full px-2 py-1 flex items-center text-gray-800 hover:text-gray-500 focus:outline-none focus:text-gray500"
            >
                <div class="w-5 h-5 mr-2">
                    <IconCog></IconCog>
                </div>
                <div class="w-3 h-3">
                    <IconChevron></IconChevron>
                </div>
                <div class="tooltip tooltip-right mt-2">Project menu</div>
            </button>
        </div>
        <div
            :class="{ hidden: !showProjectMenu }"
            class="origin-top-right absolute z-10 right-0 mt-2 w-auto border border-gray-200 rounded-md shadow-lg"
        >
            <div class="rounded-md bg-white shadow-xs py-2 divide-y">
                <button
                    @click.prevent.stop="duplicateProject"
                    class="menu-options-btn"
                >
                    <div class="w-5 h-5">
                        <IconDuplicate></IconDuplicate>
                    </div>
                    <div class="truncate">Duplicate Project</div>
                </button>

                <button
                    @click.prevent.stop="deleteProject"
                    class="menu-options-btn"
                >
                    <div class="w-5 h-5">
                        <IconTrash></IconTrash>
                    </div>
                    <div class="truncate">Delete Project</div>
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import IconCog from '@/components/icons/IconCog'
import IconChevron from '@/components/icons/IconChevron'
import IconDuplicate from '@/components/icons/IconDuplicate'
import IconTrash from '@/components/icons/IconTrash'

export default {
    name: 'ProjectMenu',
    components: {
        IconCog,
        IconChevron,
        IconDuplicate,
        IconTrash,
    },
    data() {
        return {
            showProjectMenu: false,
        }
    },
    computed: {
        ...mapState('prices', {
            price: 'price',
        }),
    },
    methods: {
        duplicateProject() {
            this.hideProjectMenu()
            this.$emit('duplicate-project')
        },
        deleteProject() {
            if (
                window.confirm(
                    'Are you sure? \n \nThis will delete this project.'
                )
            ) {
                this.$emit('delete-project')
            }
            this.hideProjectMenu()
        },
        hideProjectMenu() {
            this.showProjectMenu = false
        },
    },
    created() {
        window.addEventListener('click', this.hideProjectMenu)
    },
    destroyed() {
        window.removeEventListener('click', this.hideProjectMenu)
    },
}
</script>
