<template>
    <div class="flex flex-col w-full h-full flex-1 overflow-hidden">
        <div class="flex-shrink-0 flex">
            <div class="relative z-10 flex-shrink-0 flex print:hidden">
                <!-- Start mobile menu icon -->
                <button
                    @click.stop="$emit('open-sidebar')"
                    class="px-2 border-b border-gray-300 text-gray-500 focus:outline-none focus:bg-gray-100 focus:text-gray-600 md:hidden"
                >
                    <svg
                        class="h-6 w-6"
                        stroke="currentColor"
                        fill="none"
                        viewBox="0 0 24 24"
                    >
                        <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M4 6h16M4 12h16M4 18h7"
                        />
                    </svg>
                </button>
                <!-- End mobile menu icon -->
            </div>

            <Header></Header>
        </div>

        <main
            class="flex-1 relative z-20 overflow-y-auto overflow-x-hidden py-4 px-2 md:pr-4 focus:outline-none"
        >
            <router-view :key="$route.fullPath"></router-view>
        </main>
    </div>
</template>

<script>
import Header from '@/components/Header'
export default {
    name: 'MainPanel',
    components: {
        Header,
    },
}
</script>
