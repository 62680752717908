<template>
    <button
        @click.prevent.stop="model.isChecked = !model.isChecked"
        class="flex w-full pl-3 pr-4 py-2 text-left text-sm leading-5 whitespace-no-wrap text-gray-700 hover:bg-gray-200 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900"
    >
        <div class="w-5 h-5 mr-2 p-px border rounded-sm">
            <svg
                v-if="model.isChecked"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                stroke="currentColor"
                viewBox="0 0 24 24"
            >
                <path d="M5 13l4 4L19 7" />
            </svg>
        </div>
        <div class>{{ model.description }}</div>
    </button>
</template>

<script>
export default {
    name: 'PriceSummaryCheckbox',
    props: {
        model: Object,
    },
}
</script>
