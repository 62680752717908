<template>
    <div class="relative w-full mr-2 sm:mr-4" v-if="project">
        <!-- Toggle display of summary -->
        <div class="flex items-center space-x-3 mb-2">
            <div class="text-xl leading-none">Project summary</div>
            <PriceSummaryToggle
                :showSummary="project.show_summary"
                @toggle-summary="project.show_summary = !project.show_summary"
            ></PriceSummaryToggle>
            <PriceSummaryMenu
                v-if="project.show_summary"
                :options="availableOptions"
            ></PriceSummaryMenu>
        </div>

        <div
            class="flex flex-wrap leading-none"
            :class="{ hidden: !project.show_summary }"
        >
            <!-- Display selected summary items -->
            <div
                class="mr-2 mb-2"
                v-for="(option, index) in filteredOptions"
                :key="index"
            >
                <PriceSummaryItem
                    :option="option"
                    :value="getValue(option)"
                    :settings="settings"
                ></PriceSummaryItem>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import PriceSummaryToggle from '@/components/price/PriceSummaryToggle'
import PriceSummaryMenu from '@/components/price/PriceSummaryMenu'
import PriceSummaryItem from '@/components/price/PriceSummaryItem'

export default {
    name: 'ProjectSummary',
    components: {
        PriceSummaryToggle,
        PriceSummaryMenu,
        PriceSummaryItem,
    },
    props: {
        project: Object,
        prices: Array,
    },
    data() {
        return {
            price: null,
            markupEnabledOnRows: false,
        }
    },
    computed: {
        ...mapGetters('settings', {
            settings: 'settings',
        }),
        availableOptions() {
            return this.$store.getters['settings/projectSummaryOptions']
        },
        filteredOptions() {
            return this.availableOptions.filter((o) => o.isChecked)
        },
    },
    methods: {
        getValue(option) {
            return this.$store.getters[option.func](this.prices)
            /*let v = 0
      this.prices.forEach(price => {
        v += this.$store.getters[option.func](price)
      })
      return v*/
        },
        toggleMarkupOnRows() {
            this.markupEnabledOnRows = !this.markupEnabledOnRows
            this.$store.commit(
                'prices/SET_MARKUP_ON_ALL_ROWS',
                this.markupEnabledOnRows
            )
        },
    },
}
</script>
