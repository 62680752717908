<template>
    <div class="relative w-full mr-2 sm:mr-4" v-if="price">
        <!-- Toggle display of summary -->
        <div class="flex items-center space-x-3 mb-2 print:hidden">
            <div class="text-xl leading-none">Price summary</div>
            <PriceSummaryToggle
                :showSummary="price.show_summary"
                @toggle-summary="price.show_summary = !price.show_summary"
            ></PriceSummaryToggle>
            <PriceSummaryMenu
                v-if="price.show_summary"
                :options="availableOptions"
            ></PriceSummaryMenu>
        </div>

        <div
            class="flex flex-wrap leading-none"
            :class="{ hidden: !price.show_summary }"
        >
            <div
                class="border-t-4 border-gray-800 text-gray-800 pr-4 mr-4 mb-2 flex space-x-2 print:hidden"
            >
                <div class="">
                    <label for="price-markup-rate" class="text-xs text-gray-500"
                        >Mark-up</label
                    >
                    <div class="flex space-x-1 -mt-1">
                        <input
                            v-model="price.markup.rate"
                            name="price-markup-rate"
                            type="number"
                            placeholder="0"
                            class="w-16 px-1 border-b border-gray-500 bg-transparent text-xl font-bold text-right"
                        />
                        <div class="text-gray-500">%</div>
                    </div>
                </div>
                <div v-if="price.markup.rate != 0" class="">
                    <label
                        class="block text-xs text-gray-500"
                        title="Toggle mark-up on the Row Total of all rows"
                        >On all rows</label
                    >
                    <button
                        aria-pressed="false"
                        @click="toggleMarkupOnRows"
                        aria-label="Toggle mark-up on rows"
                        type="button"
                        name=""
                        class="block mt-1 flex-shrink-0 group relative rounded-full inline-flex items-center justify-center h-5 w-10 cursor-pointer focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-200"
                    >
                        <span
                            aria-hidden="true"
                            class="pointer-events-none absolute bg-white w-full h-full rounded-full"
                        ></span>
                        <span
                            aria-hidden="true"
                            :class="{
                                'bg-gray-200': !markupEnabledOnRows,
                                'bg-green-200': markupEnabledOnRows,
                            }"
                            class="bg-gray-200 pointer-events-none absolute h-4 w-9 mx-auto rounded-full transition-colors ease-in-out duration-200"
                        ></span>
                        <span
                            aria-hidden="true"
                            :class="{
                                'translate-x-0': !markupEnabledOnRows,
                                'translate-x-5': markupEnabledOnRows,
                            }"
                            class="pointer-events-none absolute left-0 inline-block h-5 w-5 border border-gray-400 rounded-full bg-white shadow transform ring-0 transition-transform ease-in-out duration-200"
                        ></span>
                    </button>
                </div>
            </div>

            <!-- Display selected summary items -->
            <div
                class="mr-2 mb-2"
                v-for="(option, index) in filteredOptions"
                :key="index"
            >
                <PriceSummaryItem
                    :option="option"
                    :value="getValue(option)"
                    :settings="settings"
                ></PriceSummaryItem>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { flatten } from '@/helpers'
import PriceSummaryToggle from '@/components/price/PriceSummaryToggle'
import PriceSummaryMenu from '@/components/price/PriceSummaryMenu'
import PriceSummaryItem from '@/components/price/PriceSummaryItem'

export default {
    name: 'PriceSummary',
    components: {
        PriceSummaryToggle,
        PriceSummaryMenu,
        PriceSummaryItem,
    },
    props: {
        price: Object,
    },
    data() {
        return {
            markupEnabledOnRows: false,
        }
    },
    computed: {
        ...mapGetters('settings', {
            settings: 'settings',
        }),
        availableOptions() {
            if (this.price.tax.rate > 0) {
                return this.$store.getters['settings/priceSummaryOptions']
            } else {
                return this.$store.getters[
                    'settings/priceSummaryOptions'
                ].filter((o) => o.label !== 'cost + markup + tax')
            }
        },
        filteredOptions() {
            return this.availableOptions.filter((o) => o.isChecked)
        },
        flattendRows() {
            return flatten(this.price.rows)
        },
    },
    methods: {
        getValue(option) {
            return this.$store.getters[option.func](this.price)
        },
        /*loadPrice(){
      this.price = this.$store.state.prices.prices.find(p => p.id === this.priceid)
    },*/
        toggleMarkupOnRows() {
            this.markupEnabledOnRows = !this.markupEnabledOnRows
            this.$store.commit(
                'prices/SET_MARKUP_ON_ALL_ROWS',
                this.markupEnabledOnRows
            )
        },
    },
    mounted() {
        //this.loadPrice()
    },
}
</script>
