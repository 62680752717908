<template>
    <div class="text-lg max-w-3xl">
        <p>Hi 👋.</p>

        <p>
            We are making costing and pricing your projects less of a tedious
            chore and more of an insightful process.
        </p>

        <p>
            We are building this app for small, creative makers. It exists
            because we hadn&rsquo;t found anything out there that is tailored to
            simply <em>adding up time and materials</em>. We also recognise that
            quotes and estimates often start from copying an old project, and
            that costs and prices need to evolve along with the other work in
            your project.
        </p>

        <p>
            To get started:<br />
            &ndash; Create a price with the &ldquo;+&rdquo; button in the
            top-left.<br />
            &ndash;
            <button
                @click="$store.dispatch('loadExamples')"
                class="leading-normal border-b border-current hover:opacity-50"
            >
                Load some examples
            </button>
            to see how others are using it.<br />
            &ndash; <a href="http://docs.trifle.app">Read how to use it.</a>
        </p>

        <p>
            Some context to be aware of:<br />
            &ndash; We're actively developing this app; meaning things
            <em>will</em> likely change and possibly break.<br />
            &ndash; Data <em>is</em> saved automatically but <em>only</em> to
            your browser's local storage.<br />
            &ndash; This means your <em>data is not saved to the cloud</em> and
            there are no user accounts (yet).<br />
            &ndash; In the meantime, data can be exported and imported for
            back-up or sharing:<br />
            <span class="pl-6"
                >&ndash;All data: <a href="/settings#data">Settings</a></span
            ><br />
            <span class="pl-6"
                >&ndash;Individual Prices: Visit a Price &gt; Price menu (gear
                icon top-right)</span
            ><br />
            &ndash; Also in <a href="/settings#data">Settings</a>, you can
            <em>&ldquo;reset&rdquo;</em> the app to its default configuration.
        </p>

        <p>
            Learn more about Trifle&rsquo;s purpose, context and features on
            <a href="https://trifle.app">trifle.app</a>. If you have ideas or
            requests,
            <a
                href="https://buttondown.email/trifleapp?utm_source=use_trifle_app"
                >subscribe</a
            >
            or <a href="mailto:feedback@trifle.app">contact us</a> as we're keen
            to discuss.
        </p>

        <p>
            Thanks,<br />
            <a href="https://hipgrave.dev/">Chris</a> &amp;
            <a href="https://wesort.co.uk/">Ben</a>
        </p>
    </div>
</template>

<script>
export default {
    name: 'Home',
    components: {},
    methods: {},
    mounted() {
        this.$store.dispatch('prices/clearPrice')
        this.$store.dispatch('projects/clearProject')
        document.title = 'Trifle — Calculate costs & prices'
    },
}
</script>
