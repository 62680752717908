<template>
    <div>
        <div class="flex flex-col sm:flex-row sm:space-x-6 mb-4">
            <div>
                <div class="hidden md:block text-xs text-gray-500">
                    Price name
                </div>
                <div class="text-xl sm:text-2xl font-bold truncate -mt-1">
                    {{ price.name }}
                </div>
            </div>
            <div class="flex-grow sm:text-right text-xs text-gray-500">
                <p v-if="updatedAt">Last updated: {{ updatedAt }}</p>
                <p v-if="createdAt">Created: {{ createdAt }}</p>
            </div>
        </div>
        <div class="flex flex-wrap leading-none">
            <div class="border-t-4 border-black pr-4 mr-4 mb-2">
                <div class="text-xs text-gray-500">Price excl.</div>
                <div class="text-xl -mt-1 flex space-x-1">
                    <div class="text-gray-500">{{ settings.currency }}</div>
                    <div class="font-bold">
                        {{
                            separator(
                                this.$store.getters['prices/totalPrice'](price)
                            )
                        }}
                    </div>
                </div>
            </div>
            <div
                v-if="price.tax.rate > 0"
                class="border-t-4 border-black pr-4 mr-4 mb-2"
            >
                <div class="text-xs text-gray-500">Price inc.</div>
                <div class="text-xl -mt-1 flex space-x-1">
                    <div class="text-gray-500">{{ settings.currency }}</div>
                    <div class="font-bold">
                        {{
                            separator(
                                this.$store.getters['prices/totalPriceIncTax'](
                                    price
                                )
                            )
                        }}
                    </div>
                </div>
            </div>
            <div class="border-t-4 border-black pr-4 mr-4 mb-2">
                <div class="text-xs text-gray-500">Mark-up</div>
                <div class="text-xl -mt-1 flex space-x-1">
                    <div class="font-bold">{{ price.markup.rate }}</div>
                    <div class="text-gray-500">%</div>
                </div>
            </div>
            <div class="border-t-4 border-black pr-4 mr-4 mb-2">
                <div class="text-xs text-gray-500">Cost</div>
                <div class="text-xl -mt-1 flex space-x-1">
                    <div class="text-gray-500">{{ settings.currency }}</div>
                    <div class="font-bold">
                        {{
                            separator(
                                this.$store.getters['prices/totalCost'](price)
                            )
                        }}
                    </div>
                </div>
            </div>
            <div class="border-t-4 border-black pr-4 mr-4 mb-2">
                <div class="text-xs text-gray-500">Profit</div>
                <div class="text-xl -mt-1 flex space-x-1">
                    <div class="text-gray-500">{{ settings.currency }}</div>
                    <div class="font-bold overflow-hidden">
                        {{
                            separator(
                                this.$store.getters['prices/profit'](price)
                            )
                        }}
                    </div>
                </div>
            </div>
            <div class="border-t-4 border-black pr-4 mr-4 mb-2">
                <div class="text-xs text-gray-500">Margin</div>
                <div class="text-xl -mt-1 flex space-x-1">
                    <div class="font-bold overflow-hidden">
                        {{
                            separator(
                                this.$store.getters['prices/margin'](price)
                            )
                        }}
                    </div>
                    <div class="text-gray-500">%</div>
                </div>
            </div>
            <div class="border-t-4 border-black pr-4 mr-4 mb-2">
                <div class="text-xs text-gray-500">Cost Materials</div>
                <div class="text-xl -mt-1 flex space-x-1">
                    <div class="text-gray-500">{{ settings.currency }}</div>
                    <div class="font-bold">
                        {{
                            separator(
                                this.$store.getters[
                                    'prices/totalCostMaterials'
                                ](price)
                            )
                        }}
                    </div>
                </div>
            </div>
            <div class="border-t-4 border-black pr-4 mr-4 mb-2">
                <div class="text-xs text-gray-500">Hours</div>
                <div class="text-xl -mt-1 flex space-x-1">
                    <div class="font-bold">
                        {{
                            rounded(
                                this.$store.getters['prices/totalTimeHours'](
                                    price
                                )
                            )
                        }}
                    </div>
                </div>
            </div>
            <div class="border-t-4 border-black pr-4 mr-4 mb-2">
                <div class="text-xs text-gray-500">Days</div>
                <div class="text-xl -mt-1 flex space-x-1">
                    <div class="font-bold">
                        {{
                            rounded(
                                this.$store.getters['prices/totalTimeDays'](
                                    price
                                )
                            )
                        }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { format, formatDistance } from 'date-fns'
import { separator, rounded } from '@/helpers'
export default {
    name: 'PriceCardDetails',
    components: {},
    props: {
        price: Object,
        settings: Object,
        active: Boolean,
    },
    data() {
        return {
            separator,
            rounded,
        }
    },
    computed: {
        updatedAt() {
            return this.price.updated_at
                ? formatDistance(new Date(this.price.updated_at), new Date(), {
                      addSuffix: true,
                  })
                : ''
        },
        createdAt() {
            return this.price.created_at
                ? format(new Date(this.price.created_at), 'PPPPp')
                : ''
        },
    },
    methods: {},
    mounted() {},
}
</script>
