<template>
    <div  class="flex-grow overflow-hidden">
        <div 
            v-if="!isEditing" @dblclick="enableEditing"
            class="truncate"
        >
            {{ internalValue }}
        </div>

        <input
            v-else
            :value="internalValue"
            @input="updateValue($event.target.value)"
            @blur="disableEditing"
            @keyup.enter="disableEditing"
            autofocus
        />
    </div>
</template>

<script>
export default {
    props: ['value'],
    data() {
        return {
            internalValue: this.value,
            isEditing: false,
        }
    },
    watch: {
        value(newVal) {
            this.internalValue = newVal
        },
    },
    methods: {
        enableEditing() {
            this.isEditing = true
        },
        disableEditing() {
            this.isEditing = false
            this.$emit('input', this.internalValue)
            this.$emit('change', this.internalValue)
        },
        updateValue(value) {
            this.internalValue = value
            // emit input event here for real-time
            // this.$emit('input', value);
        },
    },
}
</script>
