<template>
    <div class="relative w-auto flex h-8">
        <button
            @click="$emit('addBlankRowBelow')"
            aria-label="Add blank row"
            class="group relative p-1 h-8 rounded-l text-gray-800 hover:text-gray-400 border border-gray-500"
            :class="[disabled ? 'cursor-default' : ' hover:opacity-100']"
            :disabled="disabled"
        >
            <div class="w-6 h-6">
                <IconAdd></IconAdd>
            </div>
            <div class="tooltip tooltip-left">Add blank row</div>
        </button>
        <button
            @click.prevent.stop="showMenu = !showMenu"
            aria-label="Show options for adding rows"
            class="group relative ml-px w-6 h-8 px-1 rounded-r text-gray-800 hover:text-gray-400 border border-gray-500"
        >
            <IconChevron></IconChevron>
            <div class="tooltip tooltip-left">Options for adding rows</div>
        </button>

        <div
            class="absolute z-10 top-7 mt-2 w-auto right-0 lg:right-auto lg:ml-8 border border-gray-200 rounded-md shadow-lg"
            :class="{ hidden: !showMenu }"
        >
            <div class="rounded-md bg-white shadow-xs py-2 divide-y">
                <button
                    @click="$emit('addBlankRowBelow')"
                    class="menu-options-btn"
                >
                    <div class="w-5 h-5">
                        <IconAdd></IconAdd>
                    </div>
                    <div class="">Add</div>
                </button>

                <button @click="$emit('duplicateRow')" class="menu-options-btn">
                    <div class="w-5 h-5">
                        <IconDuplicate></IconDuplicate>
                    </div>
                    <div class="">Duplicate</div>
                </button>

                <button
                    @click="$emit('addParentRowBelow')"
                    class="menu-options-btn"
                >
                    <div class="w-5 h-5">
                        <div class="reflect-x">
                            <IconParent></IconParent>
                        </div>
                    </div>
                    <div class="">Parent</div>
                </button>

                <!-- <button
                    @click="$emit('importRowBelow')"
                    class="menu-options-btn"
                >
                    <div class="w-5 h-5">
                        <IconSaveAs></IconSaveAs>
                    </div>
                    <div class="">Import</div>
                </button> -->
            </div>
        </div>
    </div>
</template>

<script>
import IconAdd from '@/components/icons/IconAdd'
import IconChevron from '@/components/icons/IconChevron'
import IconDuplicate from '@/components/icons/IconDuplicate'
import IconParent from '@/components/icons/IconParent'
// import IconSaveAs from '@/components/icons/IconSaveAs'
export default {
    name: 'RowAddMenu',
    components: {
        IconAdd,
        IconChevron,
        IconDuplicate,
        IconParent,
        // IconSaveAs,
    },
    props: {
        rowNumber: String,
        showChildOptions: {
            type: Boolean,
            default: true,
        },
        options: Array,
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            showMenu: false,
        }
    },
    methods: {
        hideMenu: function () {
            this.showMenu = false
        },
    },
    created: function () {
        window.addEventListener('click', this.hideMenu)
    },
    destroyed: function () {
        window.removeEventListener('click', this.hideMenu)
    },
}
</script>
