<template>
    <div class="w-full flex flex-nowrap">
        <RowQty
            v-model.number="model.qty"
            :active="active"
            :disabled="disabled"
        ></RowQty>

        <RowRate
            v-model="childRowsTotal"
            :reference="false"
            :editable="false"
            :disabled="true"
            :active="active"
            :settings="settings"
        ></RowRate>

        <RowTotal
            :qty="model.qty"
            :rate="childRowsTotal"
            :active="active"
            :settings="settings"
            :model="model"
            :markupRowTotal="model.markup_total"
            @toggle-markup="$emit('toggle-markup')"
        ></RowTotal>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import RowQty from '@/components/row/qty/RowQty'
import RowRate from '@/components/row/rate/RowRate'
import RowTotal from '@/components/row/total/RowTotal'

export default {
    name: 'RowSummary',
    components: {
        RowQty,
        RowRate,
        RowTotal,
    },
    props: {
        model: Object,
        settings: Object,
        disabled: {
            type: Boolean,
            default: false,
        },
        active: {
            type: Boolean,
            default: true,
        },
    },
    computed: {
        childRowsTotal() {
            if (this.model.rows.length == 0) return 0

            let total = 0
            total += this.childRowsCost(this.model)

            return total
        },
        ...mapGetters('prices', {
            referenceModel: 'referenceModel',
        }),
    },
    methods: {
        childRowsCost(parent) {
            let total = 0
            if (parent.rows) {
                parent.rows.forEach((row) => {
                    if (row.active) {
                        if (row.rows) {
                            let childrenTotal = this.childRowsCost(row)
                            childrenTotal *= row.qty
                            total += childrenTotal
                        } else {
                            let rate = row.rate
                            if (row.is_reference) {
                                let refRow =
                                    this.$store.getters[
                                        'prices/referenceModel'
                                    ](row)
                                rate = refRow.rate
                            }
                            let rowTotal = row.qty * rate * (row.active || true)
                            total += rowTotal
                        }
                    }
                })
            }

            return total
        },
    },
}
</script>
