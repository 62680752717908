<template>
    <div class="w-full flex justify-end space-x-4 mr-2 print:hidden">
        <!-- Show if showTimeSubtotal
          - ie: if there are descendents with UoM = time -->
        <div
            class="flex align-bottom w-auto text-gray-500 border-t-4 border-gray-400 pl-2"
        >
            <div class="text-right pt-1 mr-2">
                {{ separator(timeCost) }}
            </div>
            <button
                @click.stop.prevent="nextUom"
                aria-label="Toggle time unit of measure"
                :title="uom.name + ' ' + uom.unit"
                class="relative flex w-full overflow-hidden hover:text-gray-800"
            >
                <div class="relative w-6 h-6 rounded-full bg-gray-200">
                    <IconClock></IconClock>
                    <div
                        class="text-xs absolute px-px left-0 bottom-0 rounded-full bg-inherit"
                    >
                        {{ uomSymbol }}
                    </div>
                    <div class="sr-only">{{ uom.unit }}</div>
                </div>
            </button>
        </div>

        <div
            class="flex align-bottom w-auto text-gray-500 border-t-4 border-gray-400 pl-2"
        >
            <div class="relative inline-flex py-1">
                <div class="absolute pl-1 text-gray-500 pointer-events-none">
                    {{ settings.currency }}
                </div>
                <div class="w-full pl-4 pr-1 align-middle text-right">
                    {{ separator(materialsCost) }}
                </div>
            </div>
            <div class="relative flex w-full overflow-hidden">
                <div class="relative w-6 h-6">
                    <IconTag></IconTag>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { separator } from '@/helpers'
import { mapGetters } from 'vuex'
import IconClock from '@/components/icons/IconClock'
import IconTag from '@/components/icons/IconTag'
export default {
    name: 'ParentSummary',
    components: {
        IconClock,
        IconTag,
    },
    props: {
        model: Object,
        value: Object,
    },
    data() {
        return {
            separator,
            defaultUom: {
                name: 'time',
                unit: 'hours',
            },
        }
    },
    computed: {
        ...mapGetters('settings', {
            settings: 'settings',
        }),
        uoms() {
            return this.$store.state.settings.uoms.filter(
                (u) => u.name === 'time'
            )
        },
        uom: {
            get() {
                return this.value || this.defaultUom
            },
            set(newValue) {
                this.$emit('input', newValue)
            },
        },
        uomSymbol() {
            if (this.uom && this.uom.unit) {
                return this.uom.unit.charAt(0).toUpperCase()
            } else {
                return ''
            }
        },
        timeCost() {
            if (this.uom.unit === 'hours') {
                let cost =
                    this.$store.getters['prices/childRowsHours'](this.model) *
                    this.model.qty
                return cost
            } else {
                let cost =
                    (this.$store.getters['prices/childRowsHours'](this.model) /
                        this.settings.time.hoursperday) *
                    this.model.qty
                return cost
            }
        },
        materialsCost() {
            let cost =
                this.$store.getters['prices/childRowsMaterialsCost'](
                    this.model
                ) * this.model.qty
            return cost
        },
    },
    methods: {
        nextUom() {
            let currentIndex = this.uoms.findIndex(
                (u) => u.name === this.uom.name && u.unit === this.uom.unit
            )
            currentIndex += 1
            if (currentIndex > this.uoms.length - 1) {
                currentIndex = 0
            }
            this.uom = this.uoms[currentIndex]
        },
    },
}
</script>
