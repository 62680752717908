<template>
    <div class="border-gray-800 border-t-4 flex items-end mr-4">
        <div class="pr-4">
            <div class="text-xs text-gray-500">Projects</div>
            <div class="mt-1 flex flex-wrap items-start overflow-x-scroll">
                <div v-for="p in priceProjects" :key="p.id">
                    <router-link
                        :to="{ name: 'project', params: { projectid: p.id } }"
                        class="border border-gray-500 font-medium hover:bg-gray-200 px-2 rounded-full mr-2 mb-2"
                        >{{ p.name }}</router-link
                    >
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'PriceProjects',
    computed: {
        priceProjects() {
            let price = this.$store.getters['prices/price']
            let projects = this.$store.getters['projects/projects']
            let priceProjects = []
            projects.forEach((project) => {
                if (
                    project.prices.filter((p) => p.id === price.id).length > 0
                ) {
                    priceProjects.push(project)
                }
            })
            return priceProjects
        },
    },
}
</script>
