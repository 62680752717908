<template>
    <!-- Start: Add modal -->
    <div
        class="fixed z-20 bottom-0 top-0 inset-x-0 px-4 py-4 sm:inset-0 flex items-stretch sm:justify-center"
    >
        <div class="fixed inset-0 transition-opacity">
            <div
                @click="$emit('close')"
                class="absolute inset-0 bg-gray-500 opacity-75"
            ></div>
        </div>

        <div
            class="relative w-full max-w-screen-lg p-4 md:p-6 md:pr-3 bg-white rounded-lg overflow-auto lg:overflow-hidden shadow-xl transform transition-all"
        >
            <div class="absolute top-0 right-0 pt-4 pr-4">
                <button
                    class="text-gray-400 hover:text-gray-500 focus:outline-none focus:text-gray-500 transition ease-in-out duration-150"
                    type="button"
                    @click="$emit('close')"
                >
                    <IconClose></IconClose>
                </button>
            </div>

            <div>
                <h3
                    class="w-full text-2xl leading-6 font-medium text-gray-900 mb-4"
                >
                    <span>Add Price</span>
                </h3>

                <p class="text-gray-600 mb-4">
                    Select a Price to aggregate in this Project.
                </p>

                <!-- End: Filtering & controls -->
                <div class="lg:overflow-y-scroll h-full lg:h-add-modal md:pr-4">
                    <div
                        v-for="(price, index) in prices"
                        :key="index"
                        @click="selectPrice(price)"
                        class="bg-gray-200 border border-l-8 rounded mb-2 cursor-pointer hover:opacity-50"
                        :class="{
                            'opacity-50 cursor-not-allowed':
                                priceIsDisabled(price),
                        }"
                    >
                        <PriceCardDetails
                            :price="price"
                            :settings="settings"
                            class="block flex-grow flex-row p-2"
                        ></PriceCardDetails>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- End: Add modal -->
</template>

<script>
import { mapGetters } from 'vuex'
import IconClose from '@/components/icons/IconClose'
import PriceCardDetails from '@/components/projects/PriceCardDetails'

export default {
    name: 'AddModal',
    components: {
        IconClose,
        PriceCardDetails,
    },
    data() {
        return {}
    },
    computed: {
        ...mapGetters('projects', {
            project: 'project',
        }),
        ...mapGetters('prices', {
            prices: 'prices',
        }),
        ...mapGetters('settings', {
            settings: 'settings',
        }),
        filteredPrices() {
            /*this.prices.forEach(price => {
        price.disabled = false
      })
      if(this.project.prices.length == 0){
        return this.prices
      }
      let disabled = this.prices.some(p => this.project.prices.filter(ppp.id) )
      console.log(disabled)
      disabled.forEach(price => {
        price.disabled = true
      })*/
            return this.prices
        },
    },
    mounted() {},
    beforeDestroy() {},
    methods: {
        priceIsDisabled(price) {
            if (
                this.project.prices.filter((p) => p.id === price.id).length > 0
            ) {
                return true
            } else {
                return false
            }
        },
        selectPrice(price) {
            if (this.priceIsDisabled(price)) {
                return
            }
            this.$emit('select-price', price)
        },
    },
}
</script>
