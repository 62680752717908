<template>
    <div class="flex-1 print:hidden">
        <label
            class="block w-full text-xs border-b border-current lg:hidden text-gray-600 px-1"
            >Rate</label
        >
        <div class="relative w-full lg:h-full flex items-center">
            <span class="absolute pl-1 text-gray-500 pointer-events-none">{{
                settings.currency
            }}</span>
            <input
                v-if="!disabled"
                v-model.number="rate"
                aria-label="Rate"
                type="number"
                step="any"
                :class="{
                    'cursor-default bg-gray-200': disabled,
                    'line-through': !active,
                }"
                :disabled="disabled"
                class="w-full p-1 lg:pl-4 lg:pr-2 text-right bg-white"
            />
            <div
                v-else
                class="w-full p-1 lg:pl-4 lg:pr-2 text-right bg-gray-200"
                :class="{
                    'cursor-default bg-gray-200': disabled,
                    'line-through': !active,
                }"
            >
                {{ rate }}
            </div>
        </div>
    </div>
</template>

<script>
import { separator } from '@/helpers'
export default {
    name: 'RowRate',
    props: {
        model: Object,
        settings: Object,
        currency: String,
        uom: Object,
        value: [Number, String],
        disabled: {
            type: Boolean,
            default: false,
        },
        editable: {
            type: Boolean,
            default: true,
        },
        active: {
            type: Boolean,
            default: true,
        },
    },
    computed: {
        rate: {
            get() {
                return this.disabled ? separator(this.value) : this.value || 0
            },
            set(newValue) {
                this.$emit('input', newValue)
            },
        },
    },
}
</script>
