<template>
    <div
        @click.stop.prevent="$emit('text-entry-clicked')"
        :active="active"
        :disabled="disabled"
        :class="{
            'cursor-default bg-gray-200': disabled,
            'line-through': !active,
        }"
        contenteditable="true"
        role="textbox"
        aria-multiline="true"
        class="py-px px-1 w-full h-full self-stretch bg-white leading-tight rounded-l"
        @blur="emitChange"
        v-html="value"
    ></div>
</template>

<script>
export default {
    name: 'RowDescription',
    props: {
        value: {
            type: String,
            required: false,
            default: '',
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        active: {
            type: Boolean,
            default: true,
        },
        mode: {
            type: String,
            default: 'DEFAULT',
        },
    },
    methods: {
        emitChange(ev) {
            this.$emit('input', ev.target.innerHTML)
        },
    },
}
</script>
