<template>
    <!-- Start: Add modal -->
    <div
        class="fixed z-20 bottom-0 top-0 inset-x-0 px-4 py-4 sm:inset-0 flex items-stretch sm:justify-center"
    >
        <div class="fixed inset-0 transition-opacity">
            <div
                @click="$emit('close')"
                class="absolute inset-0 bg-gray-500 opacity-75"
            ></div>
        </div>

        <div
            class="relative w-full p-4 md:p-6 md:pr-3 bg-white rounded-lg overflow-auto lg:overflow-hidden shadow-xl transform transition-all"
            :class="reference ? 'max-w-screen-sm' : 'max-w-screen-xl'"
        >
            <div class="absolute top-0 right-0 pt-4 pr-4">
                <button
                    class="text-gray-400 hover:text-gray-500 focus:outline-none focus:text-gray-500 transition ease-in-out duration-150"
                    type="button"
                    @click="$emit('close')"
                >
                    <IconClose></IconClose>
                </button>
            </div>

            <div>
                <h3
                    class="w-full text-2xl leading-6 font-medium text-gray-900 mb-4"
                >
                    <span v-if="reference">Reference Row</span>
                    <span v-else>Import Row</span>
                </h3>
                <p v-if="reference" class="text-gray-600 mb-4">
                    This will add a new row which stays in sync with the row
                    it's created from.
                </p>
                <p v-else class="text-gray-600 mb-4">
                    This will make a copy of an existing row and it will be
                    independent.
                </p>

                <!-- Start: Filtering & controls -->
                <div class="hidden">
                    <RowFilters></RowFilters>
                </div>
                <!-- End: Filtering & controls -->

                <div :class="{ 'grid md:grid-cols-5': !reference }">
                    <PriceList
                        v-if="!reference"
                        :prices="prices"
                        :selectedPrice="selectedPrice"
                        class="overflow-x-hidden"
                        @select-price="selectPrice"
                    ></PriceList>

                    <div :class="{ 'md:col-span-4': !reference }">
                        <div
                            class="border-b border-gray-300 leading-none pb-px mb-2"
                        >
                            <div
                                class="flex flex-wrap overflow-hidden text-xs text-gray-600 uppercase"
                            >
                                <div class="lg:hidden">Rows</div>
                                <div
                                    class="hidden lg:block ml-12 w-auto flex-1"
                                >
                                    Description
                                </div>
                                <div class="hidden lg:block w-12 mr-4">UoM</div>
                                <div class="hidden lg:block w-24">Rate</div>
                            </div>
                        </div>

                        <div
                            class="lg:overflow-y-scroll h-full lg:h-add-modal md:pr-4"
                        >
                            <Row
                                v-for="(row, index) in filteredRows"
                                :model="row"
                                :depth="0"
                                :index="index"
                                :number="(index + 1).toString()"
                                :key="index"
                                :editable="false"
                                :mode="mode"
                            ></Row>

                            <div v-if="settings.debug.showJsonModels">
                                <h1>All rows:</h1>
                                <pre>
                  {{ filteredRows }}
                </pre
                                >
                                {{ allRowsFromPrice }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- End: Add modal -->
</template>

<script>
import { mapGetters } from 'vuex'
import IconClose from '@/components/icons/IconClose'
import RowFilters from '@/components/modals/RowFilters'
import Row from '@/components/row/Row'
import PriceList from '@/components/PriceList'
import { modes } from '@/constants'
import { flatten } from '@/helpers'

export default {
    name: 'AddModal',
    components: {
        IconClose,
        RowFilters,
        Row,
        PriceList,
    },
    props: {
        reference: {
            type: Boolean,
            default: false,
        },
        model: {
            type: Object,
        },
    },
    data() {
        return {
            modes,
            Row,
            selectedPrice: null,
        }
    },
    computed: {
        ...mapGetters('prices', {
            prices: 'prices',
            price: 'price',
        }),
        ...mapGetters('settings', {
            settings: 'settings',
        }),
        mode() {
            if (this.reference) {
                return modes.REFERENCE
            } else {
                return modes.IMPORT
            }
        },
        filteredRows() {
            if (this.reference) {
                return this.allRowsFromPrice
            } else {
                return this.allRows
            }
        },
        allRowsFromPrice() {
            const seen = new Set()
            const unique = flatten(this.price.rows).filter((el) => {
                if (el.rows && el.rows.length > 0) {
                    return false
                }

                if (el.id === this.model.root_id) {
                    return false
                }

                if (el.link_id) {
                    const duplicate = seen.has(el.link_id)
                    seen.add(el.link_id)
                    return !duplicate
                } else {
                    return true
                }
            })
            return unique
        },
        allRows() {
            let allrows = []

            let price = this.price
            if (this.selectedPrice) {
                price = this.selectedPrice
            }

            if (!price.rows) {
                return allrows
            }

            const seen = new Set()
            const unique = price.rows.filter((el) => {
                if (el.link_id) {
                    const duplicate = seen.has(el.link_id)
                    seen.add(el.link_id)
                    return !duplicate
                } else {
                    return true
                }
            })

            allrows.push(...unique)

            /*this.prices.forEach(price => {

        //let rows = price.rows.filter((v,i,a) => a.findIndex(t=>(t.id === v.id))===i)
        //let unique = price.rows.filter(row => row.link_id === undefined)
        const seen = new Set()
        const unique = price.rows.filter(el => {
          if(el.link_id){
            const duplicate = seen.has(el.link_id);
            seen.add(el.link_id);
            return !duplicate;
          }else{
            return true;
          }

        });
        allrows.push(...unique)
      })*/

            console.log(allrows)
            return allrows
        },
    },
    mounted() {
        this.selectedPrice = this.price
        this.prices.forEach((price) => {
            price.disabled = false
        })
        this.$root.$on('add-row', this.addRow)
    },
    beforeDestroy() {
        this.$root.$off('add-row', this.addRow)
    },
    methods: {
        addRow(row) {
            if (this.reference) {
                this.$emit('referenceRow', row)
            } else {
                this.$emit('importRow', row)
            }
        },
        selectPrice(price) {
            this.selectedPrice = price
        },
    },
}
</script>
