<template>
    <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M10.75 5.75H19.25"
            stroke="currentColor"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
        ></path>
        <path
            d="M13.75 9.75H19.25"
            stroke="currentColor"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
        ></path>
        <path
            d="M4.75 8.75L8.25 12L4.75 15.25"
            stroke="currentColor"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
        ></path>
        <path
            d="M10.75 18.25H19.25"
            stroke="currentColor"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
        ></path>
        <path
            d="M13.75 14.25H19.25"
            stroke="currentColor"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
        ></path>
    </svg>
</template>

<script>
// via iconic.app
export default {
    name: 'IconIndentLeft',
}
</script>
