<template>
    <button
        @click.prevent="$emit('toggle-summary', showSummary)"
        aria-label="Toggle showing Price Summary"
        class="group relative rounded-full w-5 px-1 py-1 cursor-pointer text-gray-800 hover:text-gray-500 focus:text-gray-500"
    >
        <IconExpand v-if="!showSummary"></IconExpand>
        <div v-if="!showSummary" class="tooltip tooltip-left">Show Summary</div>
        <IconCollapse v-if="showSummary"></IconCollapse>
        <div v-if="showSummary" class="tooltip tooltip-left">Hide Summary</div>
    </button>
</template>

<script>
import IconExpand from '@/components/icons/IconExpand'
import IconCollapse from '@/components/icons/IconCollapse'

export default {
    name: 'PriceSummaryToggle',
    props: ['showSummary'],
    components: {
        IconExpand,
        IconCollapse,
    },
    methods: {
        toggleSummary() {
            this.showSummary = !this.showSummary
        },
    },
}
</script>

<!--
<button class="cursor-pointer opacity-25 hover:opacity-75 focus:opacity-100"
     @click.prevent="toggleShowChildren">
  <IconExpand v-if="!model.show_children"></IconExpand>
  <IconCollapse v-if="model.show_children"></IconCollapse>
</button>

-->
