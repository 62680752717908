<template>
    <div>
        <div v-if="price" class="space-y-4">
            <div class="flex justify-between print:hidden">
                <div
                    class="flex flex-wrap lg:flex-nowrap items-start flex-col lg:flex-row lg:space-y-0 space-y-4"
                >
                    <PriceTotal></PriceTotal>
                    <PriceProjects></PriceProjects>
                </div>
                <PriceMenu
                    class="justify-end"
                    @duplicatePrice="duplicatePrice"
                    @delete-price="deletePrice"
                ></PriceMenu>
            </div>

            <div class="">
                <PriceSummary :price="price"></PriceSummary>
            </div>

            <!-- Start: Rows header NEW -->
            <RowsHeader></RowsHeader>
            <!-- End: Rows header NEW -->

            <div
                class="border-t-4 lg:border-none border-gray-800 text-gray-800 pr-4"
                v-if="!price.rows || price.rows.length == 0"
            >
                <p class="text-xl -mt-1">Add some rows of time or materials.</p>
            </div>

            <!-- Start: Initial Row menu -->
            <RowAddMenu
                v-if="!price.rows || price.rows.length == 0"
                :showChildOptions="false"
                @addParentRowBelow="addParentRow(0)"
                @addBlankRowBelow="addNewRow(0)"
                @importRowBelow="showImportModal(price, 0)"
                @addLinkedRowBelow="showReferenceModal(price, 0)"
            ></RowAddMenu>
            <!-- End: Initial Row menu -->

            <div v-if="settings.debug.showJsonModels" class="text-xs">
                id: {{ price.id }}
            </div>

            <!-- Start: Rows NEW -->
            <draggable
                class="rows pb-48 print:pb-0"
                handle=".handle"
                v-model="price.rows"
            >
                <!-- Start: Row -->
                <Row
                    v-for="(row, index) in price.rows"
                    :key="index"
                    :model="row"
                    :parent="price"
                    :rootRow="price.rows[0]"
                    :depth="0"
                    :index="index"
                    :number="(index + 1).toString()"
                    :disabled="rowDisabled(row)"
                    :editable="rowIsBeingEdited(row)"
                    @duplicateRow="duplicateRow"
                    @editReference="editReference"
                ></Row>
                <!-- End: Row -->
            </draggable>
            <!-- End: Rows NEW -->

            <AddModal
                v-if="importModalShowing || referenceModalShowing"
                :reference="referenceModalShowing"
                :model="modelToImportInto"
                :excludeRows="rowsToExclude"
                @close="
                    importModalShowing = false
                    referenceModalShowing = false
                "
                @referenceRow="referenceRow"
                @importRow="importRow"
            ></AddModal>

            <HeadlinePriceModal
                v-if="showHeadlinePriceModal"
                :price="price"
                :totalCostIncMarkup="priceExcl"
                :totalPriceIncTax="totalPriceIncTax"
                :type="'price'"
                @save="setHeadlinePrice"
                @close="showHeadlinePriceModal = false"
            ></HeadlinePriceModal>
        </div>
        <div v-else class="text-2xl font-black mb-4">Price not found</div>
    </div>
</template>

<script>
import Vue from 'vue'
import { mapGetters, mapActions } from 'vuex'
import { flatten } from '@/helpers'
import draggable from 'vuedraggable'
import PriceTotal from '@/components/price/PriceTotal'

import RowAddMenu from '@/components/row/RowAddMenu'
import RowsHeader from '@/components/row/RowsHeader'
import Row from '@/components/row/Row'
import PriceMenu from '@/components/price/PriceMenu'
import PriceSummary from '@/components/price/PriceSummary'
import PriceProjects from '@/components/price/PriceProjects'

import AddModal from '@/components/modals/AddModal'
import HeadlinePriceModal from '@/components/modals/HeadlinePriceModal'

export default {
    name: 'PriceEdit',
    components: {
        draggable,
        PriceTotal,
        RowAddMenu,
        RowsHeader,
        Row,
        PriceMenu,
        PriceSummary,
        PriceProjects,
        AddModal,
        HeadlinePriceModal,
    },
    data() {
        return {
            importModalShowing: false,
            referenceModalShowing: false,
            modelToImportInto: null,
            showHeadlinePriceModal: false,
        }
    },
    computed: {
        ...mapGetters('prices', {
            price: 'price',
        }),
        ...mapGetters('settings', {
            settings: 'settings',
        }),
        priceExcl() {
            return this.$store.getters['prices/totalCostIncMarkup'](this.price)
        },
        totalPrice() {
            return this.$store.getters['prices/totalPrice'](this.price)
        },
        totalPriceIncTax() {
            return this.$store.getters['prices/totalPriceIncTax'](this.price)
        },
        //options for draggable rows
        dragOptions() {
            return {
                animation: 0,
                group: 'rows',
                disabled: false,
                ghostClass: 'ghost',
            }
        },
    },
    methods: {
        ...mapActions('prices', {
            addPrice: 'addPrice',
            setPrice: 'setPrice',
        }),
        rowDisabled(row) {
            if (this.$store.getters['prices/editingReference'] !== '') {
                if (
                    row.id === this.$store.getters['prices/editingReference'] ||
                    row.link_id ===
                        this.$store.getters['prices/editingReference']
                ) {
                    return false
                }
                return true
            } else {
                return false
            }
        },
        setHeadlinePrice(options) {
            this.$store.commit('prices/SET_HEADLINE_PRICE_SETTINGS', options)
            /*this.$store.commit('prices/SET_PRICE_TOTAL_MODE', options.type)
      this.$store.commit('prices/SET_PRICE_TAX_RATE', options.tax.rate)
      if(options.type === 'FIXED'){
        this.$store.commit('prices/SET_PRICE_TOTAL', parseFloat(options.fixedPrice))
      }*/
            this.showHeadlinePriceModal = false
        },
        rowsToExclude() {
            let exclusions = []
            let model = this.modelToImportInto
            exclusions.push(model.root_id)
            if (model.is_reference) {
                exclusions.push(model.link_id)
            }
            return exclusions
        },
        rowIsBeingEdited(row) {
            if (row.price_id !== this.price.id) {
                return false
            }

            if (row.is_reference) {
                if (this.$store.getters['prices/editingReference'] !== '') {
                    if (
                        row.id ===
                            this.$store.getters['prices/editingReference'] ||
                        row.link_id ===
                            this.$store.getters['prices/editingReference']
                    ) {
                        return true
                    }
                }
            }
            return false
        },
        showImportModal(model, index) {
            console.log('showImportModal')
            this.modelToImportInto = model
            this.newRowIndex = index
            this.importModalShowing = true
            this.referenceModalShowing = false
        },
        showReferenceModal(model, index) {
            this.modelToImportInto = model
            this.newRowIndex = index
            this.importModalShowing = false
            this.referenceModalShowing = true
        },
        //add a new blank row at the index specified
        addNewRow(index) {
            //add the row to our price
            //let model = this.price
            let payload = {
                model: this.price,
                index: index,
            }
            this.$store.dispatch('prices/addRowToModelAtIndex', payload)
        },
        addParentRow(index) {
            let payload = {
                model: this.price,
                index: index,
            }
            this.$store.dispatch('prices/addParentRow', payload)
        },
        //make a new reference row which is linked to the row passed in
        //changes made to this row will also change any linked rows
        referenceRow(row) {
            let payload = {
                row: row,
                model: this.modelToImportInto,
                index: this.newRowIndex,
            }

            //model is parent
            if (payload.model.rows && payload.model.rows.length > 0) {
                this.$store.dispatch(
                    'prices/referenceChildToParentModelAtIndex',
                    payload
                )

                //model is not reference
            } else if (!payload.model.is_reference) {
                this.$store.dispatch(
                    'prices/referenceChildToModelAtIndex',
                    payload
                )

                //model is reference
            } else {
                //this.$store.dispatch('prices/referenceChildToReferenceModelAtIndex', payload)
            }

            this.referenceModalShowing = false
        },
        //import an existing row to our price (row can be from any price in the database)
        importRow(row) {
            console.log('importRow')
            let payload = {
                row: row,
                model: this.modelToImportInto,
                index: this.newRowIndex,
            }

            //model is parent
            if (this.modelToImportInto.rows) {
                this.$store.dispatch(
                    'prices/importRowToParentModelAtIndex',
                    payload
                )

                //model is not reference
            } else if (!this.modelToImportInto.is_reference) {
                this.$store.dispatch('prices/importRowToModelAtIndex', payload)

                //model is reference
            } else {
                this.$store.dispatch(
                    'prices/importRowToReferenceModelAtIndex',
                    payload
                )
            }

            //hide the modal
            this.importModalShowing = false
        },
        //duplicate an existing row
        duplicateRow({ index: index, row: row }) {
            this.$store.dispatch('prices/duplicateRow', { row, index })
        },
        //duplicate this whole price then naviagte to new one
        duplicatePrice() {
            this.$root.$emit('duplicate-price', this.price)
        },
        deletePrice() {
            this.$root.$emit('delete-price', this.price)
        },
        editReference(row) {
            this.$store.dispatch(
                'prices/setEditingReference',
                row.link_id ? row.link_id : row.id
            )
        },
        clicked() {
            this.$store.dispatch('prices/setEditingReference', '')
        },
        convertRowToIndependent(row) {
            if (window.confirm('Are you sure you want to unlink this row?')) {
                this.$store.dispatch('prices/convertRowToIndependent', row)
            }
        },
        linkedRowUpdated(row) {
            let rows = this.price.rows.filter((r) => r.id === row.id)
            rows.forEach((r) => {
                Vue.set(r, 'linked_description', row.linked_description)
                Vue.set(r, 'rate', row.rate)
                Vue.set(r, 'uom', row.uom)
            })
        },
        reference_model(row) {
            let rows = flatten(this.$store.getters['prices/price'].rows)

            return rows.filter((r) => {
                return r.id === row.link_id
            })[0]
        },
    },
    mounted() {
        window.umami(
            `VIEW_PRICE_${this.$store.state.session.sessionId}_${this.$route.params.priceid}`
        )

        if (this.$route.params.priceid) {
            const priceid = this.$route.params.priceid
            this.setPrice(priceid)
            this.$store.commit('projects/SET_PROJECT', null)
        }
        this.$root.$on('show-import-modal', this.showImportModal)
        this.$root.$on('show-reference-modal', this.showReferenceModal)
        this.$root.$on('edit-reference', this.editReference)
        this.$root.$on('convert-to-independent', this.convertRowToIndependent)
        this.$root.$on('linked-row-updated', this.linkedRowUpdated)
        this.$root.$on('show-headline-price-modal', () => {
            this.showHeadlinePriceModal = true
        })

        window.addEventListener('click', this.clicked, false)
    },
    beforeDestroy() {
        this.$root.$off('childAdded')
        this.$root.$off('addBlankRowBelow')
        this.$root.$off('saveAsPriceRow')

        this.$root.$off('show-import-modal', this.showImportModal)
        this.$root.$off('show-reference-modal', this.showReferenceModal)
        this.$root.$off('edit-reference', this.editReference)
        this.$root.$off('convert-to-independent', this.convertRowToIndependent)
        this.$root.$off('linked-row-updated', this.linkedRowUpdated)
        this.$root.$off('show-headline-price-modal')

        window.removeEventListener('click', this.clicked, false)
    },
}
</script>
