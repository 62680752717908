<template>
    <div class="w-full">
        <label
            for="name"
            class="hidden md:block print:block text-xs text-gray-500"
            >{{ label }}</label
        >
        <input
            v-model="name"
            name="name"
            id="name"
            type="text"
            placeholder="Name your price"
            class="w-full lg:w-1/2 text-xl sm:text-2xl font-bold truncate border-b border-gray-800 print:border-0 bg-transparent mb-1"
        />
    </div>
</template>

<script>
export default {
    name: 'HeaderName',
    props: {
        value: String,
        label: String,
    },
    computed: {
        name: {
            get() {
                return this.value
            },
            set(newValue) {
                this.$emit('input', newValue)
            },
        },
    },
}
</script>
