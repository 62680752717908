<template>
    <div class="pb-8 pr-4">
        <div class="border-b border-gray-300 mb-2">
            <div class="text-xs text-gray-600 uppercase">Prices</div>
        </div>

        <div class="overflow-y-scroll max-h-sidebar md:max-h-sidebar">
            <div
                v-for="(p, index) in prices"
                :key="index"
                class="truncate mb-2"
                :class="{
                    'font-bold': selectedPrice && selectedPrice.id === p.id,
                    'text-gray-400': p.disabled,
                }"
                :disabled="p.disabled"
            >
                <span
                    :class="[
                        p.disabled ? 'cursor-not-allowed' : 'cursor-pointer',
                    ]"
                    @click="selectPrice(p)"
                    >{{ p.name }}</span
                >
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'PriceList',
    props: {
        selectedPrice: Object,
        prices: Array,
    },
    methods: {
        selectPrice(p) {
            if (p.disabled) return

            this.$emit('select-price', p)
        },
    },
}
</script>
