<template>
    <div class="border-t-4 border-gray-800 text-gray-800 pr-4 mr-4 mb-2">
        <label class="text-xs text-gray-500">{{ option.description }}</label>

        <div
            v-if="option.unit == 'time'"
            class="flex space-x-1 text-xl font-bold -mt-1"
        >
            {{ rounded(value, false) }}
        </div>

        <div
            v-else-if="option.unit == 'financial'"
            class="flex space-x-1 text-xl font-bold -mt-1 print:hidden"
        >
            <div v-if="value" class="text-gray-500">
                {{ settings.currency }}
            </div>
            <div v-if="value" class="">{{ separator(value) }}</div>
        </div>

        <div
            v-else-if="option.unit == 'percentage'"
            class="flex space-x-1 text-xl font-bold -mt-1 print:hidden"
        >
            <div v-if="value" class="">{{ separator(value) }}</div>
            <div v-if="value" class="text-gray-500">%</div>
        </div>
    </div>
</template>

<script>
import { separator, rounded } from '@/helpers'
export default {
    name: 'PriceSummaryItem',
    props: {
        editable: Boolean,
        option: Object,
        value: Number,
        model: Object,
        settings: Object,
    },
    data() {
        return { separator, rounded }
    },
}
</script>
