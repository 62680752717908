<template>
    <div class="hidden md:flex md:flex-shrink-0 w-full">
        <div class="flex flex-col pl-4 pt-2 w-full">
            <div class="flex items-center">
                <div class="">
                    <router-link :to="{ name: 'home' }">
                        <Logotype class="logotype w-20"></Logotype>
                    </router-link>
                </div>
            </div>
            <div class="mt-5 flex-1 w-full">
                <div class="mb-4 relative">
                    <NewButton></NewButton>
                </div>
                <div class="mb-4 relative">
                    <div
                        class="border-b border-gray-300 text-xs text-gray-500 mb-1"
                    >
                        Prices & Projects
                    </div>
                    <SidebarNav></SidebarNav>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import SidebarNav from '@/components/sidebar/SidebarNav'
import NewButton from '@/components/NewButton'
import Logotype from '@/components/sidebar/Logotype'

export default {
    name: 'SidebarDesktop',
    components: {
        SidebarNav,
        NewButton,
        Logotype,
    },
}
</script>
