<template>
    <div class="text-lg max-w-3xl">
        <h1 class="text-2xl font-black mb-4">Page not found (404 error)</h1>
    </div>
</template>

<script>
export default {
    name: 'NotFound',
    mounted() {
        document.title = 'Page not found — Trifle'
    },
}
</script>
