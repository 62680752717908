import Vue from 'vue'
import Router from 'vue-router'

import Home from '@/views/Home'
import Settings from '@/views/Settings'
import PricesEdit from '@/views/prices/Edit'
import ProjectView from '@/views/projects/View'
import NotFound from '@/views/404'

Vue.use(Router)

const routes = [
    {
        path: '/',
        name: 'home',
        component: Home,
    },
    {
        path: '/settings',
        name: 'settings',
        component: Settings,
    },
    {
        path: '/prices/:priceid',
        name: 'price',
        component: PricesEdit,
    },
    {
        path: '/projects/:projectid',
        name: 'project',
        component: ProjectView,
    },
    {
        path: '*',
        name: '404',
        component: NotFound,
    },
]

const router = new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
})

/*router.beforeEach((to, from, next) => {
    if (to.path === '/') {
        next({ path: '/search' });
    } else {
        next();
    }
});*/

export default router
