<template>
    <div class="flex flex-wrap">
        <div class="w-full lg:w-1/3">
            <div class="border-t-4 border-gray-800 lg:border-0">
                <h3 class="text-lg font-medium leading-6 text-gray-900">
                    Settings
                </h3>
                <p class="mt-1 text-sm leading-5 text-gray-500">
                    Configure and define
                </p>
            </div>
        </div>

        <div class="w-full lg:w-2/3 mb-12">
            <div class="py-4 lg:border-t-4 border-gray-800">
                <div class="flex flex-wrap">
                    <div class="w-32 mr-4 mb-4 lg:mb-0">
                        <label
                            for="currency-symbol"
                            class="block text-sm text-gray-600"
                            >Currency symbol</label
                        >
                        <select
                            v-model="settings.currency"
                            id="currency-symbol"
                            class="form-select w-full truncate rounded bg-white border border-gray-400"
                        >
                            <option selected>£</option>
                            <option>$</option>
                            <option>€</option>
                        </select>
                    </div>
                </div>
            </div>
        </div>

        <div class="w-full lg:w-1/3">
            <div class="border-t-4 border-gray-800 lg:border-0">
                <h3 class="text-lg font-medium leading-6 text-gray-900">
                    Default values
                </h3>
                <p class="mt-1 text-sm leading-5 text-gray-500">
                    Tax, markup, etc.
                </p>
            </div>
        </div>

        <div class="w-full lg:w-2/3 mb-12">
            <div class="py-4 lg:border-t-4 border-gray-800">
                <div class="w-full mb-4">
                    <p>
                        Default values that are set when new prices are created.
                    </p>
                </div>

                <div class="flex flex-wrap space-x-4">
                    <div class="relative">
                        <label
                            for="tax-rate"
                            class="block text-sm text-gray-600"
                            >Tax rate</label
                        >
                        <input
                            type="number"
                            placeholder="20"
                            id="tax-rate"
                            v-model="settings.tax.rate"
                            class="w-24 p-1 pr-6 align-middle text-right bg-white rounded border border-gray-400"
                        />
                        <span class="absolute right-0 pr-1 mt-1 text-gray-500"
                            >%</span
                        >
                    </div>
                    <div class="relative">
                        <label for="mark-up" class="block text-sm text-gray-600"
                            >Markup</label
                        >
                        <div
                            class="absolute top-0 right-0 -mt-1 text-gray-400 hover:text-gray-black cursor-pointer"
                            title="% to add on top of costs"
                        >
                            <div class="w-4 h-4"><IconInfo></IconInfo></div>
                        </div>
                        <input
                            v-model="settings.markup.rate"
                            id="mark-up"
                            type="number"
                            placeholder="25"
                            class="w-24 p-1 pr-6 align-middle text-right bg-white rounded border border-gray-400"
                        />
                        <span class="absolute right-0 pr-1 mt-1 text-gray-500"
                            >%</span
                        >
                    </div>
                </div>
            </div>
        </div>

        <div class="w-full lg:w-1/3">
            <div class="border-t-4 border-gray-800 lg:border-0">
                <h3
                    id="data"
                    class="text-lg font-medium leading-6 text-gray-900"
                >
                    Data
                </h3>
                <p class="mt-1 text-sm leading-5 text-gray-500">Be careful!</p>
            </div>
        </div>

        <div v-if="settings.debug" class="w-full lg:w-2/3 mb-12">
            <div class="py-4 lg:border-t-4 border-gray-800">
                <div class="grid gap-8 lg:grid-cols-2">
                    <div class="">
                        <div class="w-full inline-flex items-center space-x-1">
                            <span class="inline-block w-4 h-4"
                                ><IconInfo></IconInfo
                            ></span>
                            <span class="inline-block font-bold text-xl"
                                >Example data</span
                            >
                        </div>
                        <p>
                            We've prepared a few <em>Prices</em> to demonstate
                            simple and complex uses of the app.
                        </p>
                        <div class="flex flex-wrap space-x-2 mb-2">
                            <button
                                @click="$store.dispatch('loadExamples')"
                                class="btn"
                            >
                                Load examples
                            </button>
                        </div>
                    </div>

                    <div class="">
                        <div class="w-full inline-flex items-center space-x-1">
                            <span class="inline-block w-4 h-4"
                                ><IconInfo></IconInfo
                            ></span>
                            <span class="inline-block font-bold text-xl"
                                >Export & Import</span
                            >
                        </div>
                        <p>
                            Create a backup of <em>Prices</em> and
                            <em>Settings</em> (not <em>Projects</em>) to share
                            with another device or person.
                        </p>
                        <div class="flex flex-wrap space-x-2 mb-2">
                            <button @click="exportAllData" class="btn">
                                Export
                            </button>
                            <button @click="importAllData" class="btn">
                                Import
                            </button>
                        </div>
                    </div>

                    <div class="col-span-full flex flex-col">
                        <div class="w-full inline-flex items-center space-x-1">
                            <span class="inline-block w-4 h-4"
                                ><IconInfo></IconInfo
                            ></span>
                            <span
                                class="inline-block font-bold text-xl text-red-500"
                                >Warning</span
                            >
                        </div>
                        <p>
                            Resetting the app will delete <b>all data</b> and
                            revert the app to a clean state.
                        </p>
                        <p>
                            You may want to export / backup first:<br />
                            &ndash; <em>Prices</em> and <em>Settings</em>: see
                            above<br />
                            &ndash; Individual <em>Prices</em>: visit a Price >
                            gear button in top-right
                        </p>
                        <div class="">
                            <button
                                @click="clearStorage"
                                class="text-gray-800 bg-red-200 hover:bg-red-500 rounded py-1 px-2 lg:py-2 lg:px-3"
                            >
                                Reset app
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <input
            type="file"
            class="hidden"
            @change="onFileChange"
            ref="fileInput"
        />

        <div class="w-full lg:w-1/3">
            <div class="border-t-4 border-gray-800 lg:border-0">
                <h3 class="text-lg font-medium leading-6 text-gray-900">
                    Debug
                </h3>
                <p class="mt-1 text-sm leading-5 text-gray-500">
                    Display the underlying data
                </p>
            </div>
        </div>

        <div v-if="settings.debug" class="w-full lg:w-2/3 mb-12">
            <div class="py-4 lg:border-t-4 border-gray-800">
                <div class="">
                    <label
                        class="block text-sm text-gray-600 mb-2"
                        for="showJsonModels"
                        >Show JSON models</label
                    >
                    <input
                        id="showJsonModels"
                        v-model="showJsonModels"
                        type="checkbox"
                        class="form-checkbox h-5 w-5"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import IconInfo from '../components/icons/IconInfo'
import { formatDate, makeid } from '@/helpers'

export default {
    name: 'Settings',
    components: {
        IconInfo,
    },
    computed: {
        showJsonModels: {
            get() {
                return this.$store.state.settings.settings.debug.showJsonModels
            },
            set(value) {
                this.$store.dispatch('settings/updateDebug', value)
            },
        },
        settings: {
            get() {
                return this.$store.state.settings.settings
            },
            set(value) {
                console.log(value)
                this.$store.commit('UPDATE_SETTINGS', value)
            },
        },
    },
    methods: {
        saveSettings() {
            //this.$store.dispatch('settings/updateSettings', this.settings)
        },
        clearStorage() {
            if (
                window.confirm(
                    'Are you sure? \n \nThis will delete all data and revert to app to a clean installation.'
                )
            ) {
                window.localStorage.clear()
                location.replace('/')
            }
        },
        exportAllData() {
            let now = new Date()
            let filename = 'trifle-data-' + formatDate(now)

            // let projects = this.$store.state.projects.projects
            let folders = this.$store.state.prices.folders
            let settings = this.$store.state.settings.settings

            var a = document.createElement('a')
            var file = new Blob(
                [
                    JSON.stringify({
                        folders,
                        // projects,
                        settings,
                    }),
                ],
                { type: 'text/plain' }
            )
            a.href = URL.createObjectURL(file)

            a.download = filename + '.json'
            a.click()
        },
        importAllData() {
            this.$refs.fileInput.click()
        },
        onFileChange(e) {
            let files = e.target.files || e.dataTransfer.files

            if (!files.length) {
                return
            }

            if (
                !window.confirm('Are you sure? This will overwrite all data.')
            ) {
                return
            }

            console.log(files)

            Array.from(files).forEach((file) => {
                let reader = new FileReader()
                reader.onload = (e) => {
                    let data = JSON.parse(e.target.result)

                    // const updatedData = this.importData(this.$store.state.prices, data)
                    // console.log(updatedData)
                    if(data.folders){
                        this.$store.commit('prices/SET_FOLDERS', data.folders)
                    }
                    

                    if (data.prices) {
                        data.prices.forEach((price) => {
                            price.id = makeid(4)
                            this.assignPriceIdToRows(price)
                            this.$store.dispatch('prices/addPrice', price)
                        })
                    }

                    if (data.projects) {
                        data.projects.forEach((project) => {
                            project.id = makeid(4)
                            this.$store.dispatch('projects/addProject', project)
                        })
                    }

                    if (data.settings) {
                        this.$store.commit(
                            'settings/UPDATE_SETTINGS',
                            data.settings
                        )
                    }
                }
                reader.readAsText(file)
            })
        },
        importData(existingData, newData) {
            // Recursive function to update or add new data
            function updateOrAddData(target, data) {
                console.log(target, data)
                data.children.forEach((newItem) => {
                    console.log(newItem, target)
                    const existingItemIndex = target.children.findIndex(
                        (item) => item.id === newItem.id
                    )

                    if (existingItemIndex !== -1) {
                        // If the item exists, update it
                        if (newItem.type === 'folder') {
                            // If it's a folder, recursively update its children
                            updateOrAddData(
                                target.children[existingItemIndex],
                                newItem
                            )
                        } else {
                            // If it's a price, replace the existing item with the new one
                            target.children[existingItemIndex] = newItem
                        }
                    } else {
                        // If the item does not exist, add it
                        target.children.push(newItem)
                    }
                })
            }

            // Clone the existing data to avoid mutating the original object directly
            const updatedData = JSON.parse(JSON.stringify(existingData))

            // Start the update/add process
            updateOrAddData(updatedData.folders, newData.folders)

            return updatedData
        },
        assignPriceIdToRows(parent) {
            parent.rows.forEach((row) => {
                row.price_id = parent.price_id ? parent.price_id : parent.id
                if (row.rows) {
                    this.assignPriceIdToRows(row)
                }
            })
        },
    },
    mounted() {
        document.title = 'Settings — Trifle'
    },
}
</script>
