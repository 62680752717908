<template>
    <nav
        class="flex-1 h-full overflow-y-scroll max-h-sidebar md:max-h-sidebar pb-8"
    >
        <!-- <div v-if="items.length > 0" class="pr-1">
      <div v-for="(p, index) in items" :key="index" class="truncate mb-2">
        <router-link :to="p.to" class="flex space-x-1 focus:text-gray-500 hover:text-gray-500">
          <div class="flex-shrink-0 w-6">
            <component :is="p.icon" class=""></component>
          </div>
          <div class="">{{ p.name }}</div>
        </router-link>
      </div>
    </div>
    <div v-else class="italic">No Prices or Projects (yet)</div> -->

        <SlVueTree
            v-model="folders.children"
            @nodeclick="nodeSelected"
        ></SlVueTree>
    </nav>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
// import IconClipboard from '@/components/icons/IconClipboard'
// import IconCalculator from '@/components/icons/IconCalculator'
import SlVueTree from '@/components/SlVueTree'

export default {
    name: 'SidebarNav',
    data() {
        return {
            showProjectNav: [],
        }
    },
    components: {
        // IconClipboard,
        // IconCalculator,
        SlVueTree,
    },
    computed: {
        ...mapState('prices', {
            activeFolder: 'activeFolder',
        }),
        ...mapGetters('prices', {
            prices: 'prices',
            price: 'price',
            folders: 'folders',
        }),
        ...mapGetters('projects', {
            projects: 'projects',
        }),
        // items(){
        //   this.prices.forEach(price => {
        //     price.to = { name: 'price', params: { priceid: price.id } }
        //     price.icon = IconCalculator
        //   })
        //   this.projects.forEach(project => {
        //     project.to = { name: 'project', params: { projectid: project.id } }
        //     project.icon = IconClipboard
        //   })
        //   this.folders.forEach(folder => {
        //     folder.to = { name: 'folder', params: { folderid: folder.id } }
        //     folder.icon = IconClipboard
        //   })
        //   console.log(this.projects)
        //   return [...this.prices, ...this.projects]
        // }
    },
    methods: {
        nodeSelected(node) {
            this.$store.commit('prices/SET_SELECTED_NODE', node)

            if (node.rowType === 'price') {
                this.$router.push({
                    name: 'price',
                    params: { priceid: node.id },
                })
            }
        },
        toggleVisible: function (id) {
            let index = this.getIndex(id)
            if (index < 0) return
            this.showProjectNav[index].visible =
                !this.showProjectNav[index].visible
        },
        getVisible: function (id) {
            //if (this.showProjectNav.length == 0) return;
            let index = this.getIndex(id)
            if (index < 0) return

            return this.showProjectNav[index].visible
        },
        setVisible: function (id) {
            //if (this.showProjectNav.length == 0) return;

            let index = this.getIndex(id)
            if (index < 0) return

            this.showProjectNav[index].visible = true
        },
        getIndex: function (id) {
            let index = this.showProjectNav
                .map(function (e) {
                    return e.id
                })
                .indexOf(id)
            return index
        },
        closeAll: function () {
            this.showProjectNav.forEach(function (nav) {
                nav.visible = false
            })
        },
    },
    mounted() {
        this.closeAll()
        this.setVisible(this.$route.params.id)
    },
}
</script>
