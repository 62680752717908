import Vue from 'vue'

const defaultSettings = {
    currency: '£',
    time: {
        hoursperday: 8,
        hoursperweek: 40,
    },
    uom: {
        default: {
            name: 'time',
            unit: 'hours',
        },
    },
    tax: {
        rate: 20,
    },
    markup: {
        rate: 25,
    },
    debug: {
        showJsonModels: false,
    },
    price: {
        summary: {
            options: [
                {
                    label: 'cost',
                    description: 'Cost',
                    unit: 'financial',
                    isChecked: true,
                    func: 'prices/totalCost',
                },
                {
                    label: 'profit',
                    description: 'Profit',
                    unit: 'financial',
                    isChecked: true,
                    func: 'prices/profit',
                },
                {
                    label: 'margin',
                    description: 'Margin',
                    unit: 'percentage',
                    isChecked: true,
                    func: 'prices/margin',
                },
                {
                    label: 'hours',
                    description: 'Hours',
                    unit: 'time',
                    isChecked: true,
                    func: 'prices/totalTimeHours',
                },
                {
                    label: 'days',
                    description: 'Days',
                    unit: 'time',
                    isChecked: true,
                    func: 'prices/totalTimeDays',
                },
                {
                    label: 'time cost',
                    description: 'Time cost',
                    unit: 'financial',
                    isChecked: false,
                    func: 'prices/totalCostTime',
                },
                {
                    label: 'materials cost',
                    description: 'Material cost',
                    unit: 'financial',
                    isChecked: false,
                    func: 'prices/totalCostMaterials',
                },
                {
                    label: 'cost + markup',
                    description: 'Cost + markup',
                    unit: 'financial',
                    isChecked: false,
                    func: 'prices/totalCostIncMarkup',
                },
                {
                    label: 'cost + markup + tax',
                    description: 'Cost + markup + tax',
                    unit: 'financial',
                    isChecked: false,
                    func: 'prices/totalCostIncMarkupIncTax',
                },
            ],
        },
    },
    project: {
        summary: {
            options: [
                {
                    label: 'profit',
                    description: 'Profit',
                    unit: 'financial',
                    isChecked: true,
                    func: 'projects/profit',
                },
                {
                    label: 'margin',
                    description: 'Margin',
                    unit: 'percentage',
                    isChecked: true,
                    func: 'projects/margin',
                },
                {
                    label: 'cost',
                    description: 'Cost',
                    unit: 'financial',
                    isChecked: true,
                    func: 'projects/totalCost',
                },
                {
                    label: 'hours',
                    description: 'Hours',
                    unit: 'time',
                    isChecked: true,
                    func: 'projects/totalTimeHours',
                },
                {
                    label: 'days',
                    description: 'Days',
                    unit: 'time',
                    isChecked: true,
                    func: 'projects/totalTimeDays',
                },
            ],
        },
    },
    bannerHidden: false,
}

const state = Vue.observable({
    settings: window.localStorage.getItem('settings')
        ? JSON.parse(window.localStorage.getItem('settings'))
        : defaultSettings,
    uoms: [
        {
            name: 'materials',
            unit: '',
        },
        {
            name: 'time',
            unit: 'hours',
        },
        {
            name: 'time',
            unit: 'days',
        },
    ],
})

// getters
const getters = {
    settings: (state) => state.settings, //all settings
    priceSummaryOptions: (state) => state.settings.price.summary.options,
    defaultUom: (state) => state.settings.uom.default || state.uoms[0],
    projectSummaryOptions: (state) =>
        state.settings.project?.summary?.options ||
        defaultSettings.project.summary.options,
}

// mutations
const mutations = {
    UPDATE_SETTINGS(state, settings) {
        state.settings = settings
    },
    UPDATE_DEBUG(state, debug) {
        state.settings.debug.showJsonModels = debug
    },
    SET_DEFAULT_UOM(state, uom) {
        state.settings.uom.default = uom
    },
    HIDE_BANNER(state) {
        state.settings.bannerHidden = true
    }
}

// actions
const actions = {
    updateSettings({ commit }, settings) {
        console.log(settings)
        commit('UPDATE_SETTINGS', settings)
    },
    updateDebug({ commit }, debug) {
        commit('UPDATE_DEBUG', debug)
    },
    setDefaultUom({ commit }, uom) {
        commit('SET_DEFAULT_UOM', uom)
    },
    hideBanner({ commit }) {
        commit('HIDE_BANNER')
    }
}

const vm = new Vue()
vm.$watch(
    () => state,
    () => {
        window.localStorage.setItem('settings', JSON.stringify(state.settings))
    },
    {
        deep: true,
    }
)

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}
