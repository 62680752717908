<template>
    <header
        class="w-full flex justify-between items-center px-2 pt-1 pb-2 md:pr-4 border-b border-gray-300 print:px-0"
    >
        <HeaderName
            v-if="price"
            :label="'Price name'"
            v-model="price.name"
        ></HeaderName>
        <HeaderName
            v-else-if="project"
            :label="'Project name'"
            v-model="project.name"
        ></HeaderName>
        <div v-else>
            <router-link :to="{ name: 'home' }">
                <Logotype
                    class="logotype w-20 md:hidden print:block"
                ></Logotype>
            </router-link>
        </div>

        <Logotype class="logotype w-20 hidden print:block"></Logotype>

        <div class="flex items-end flex-row pl-2 py-2 print:hidden">
            <div class="relative w-8 h-8 ml-2 rounded-full">
                <button
                    @click.prevent.stop="showInfo = !showInfo"
                    aria-label="User menu"
                    aria-haspopup="true"
                    class="w-full overflow-hidden w-full m-auto rounded-full border-2 border-gray-400 text-gray-400 hover:text-gray-800 focus:outline-none focus:text-gray-800 hover:border-gray-800 focus:border-gray-800"
                >
                    <div class="h-full w-full text-xl text-center">?</div>
                </button>
                <div
                    :class="{ hidden: !showInfo }"
                    class="origin-top-right absolute right-0 mt-2 mr-1 w-48 z-30 border border-gray-200 rounded-md shadow-lg"
                >
                    <div
                        role="menu"
                        aria-orientation="vertical"
                        aria-labelledby="user-menu"
                        class="py-1 rounded-md bg-white shadow-xs"
                    >
                        <a
                            href="https://trifle.app"
                            @click="showInfo = !showInfo"
                            role="menuitem"
                            class="block w-full px-4 py-2 text-left text-sm leading-5 whitespace-no-wrap text-gray-700 hover:bg-gray-200 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900 transition ease-in-out duration-150"
                            >About</a
                        >

                        <a
                            href="http://trifle.app/releases"
                            @click="showInfo = !showInfo"
                            role="menuitem"
                            class="block w-full px-4 py-2 text-left text-sm leading-5 whitespace-no-wrap text-gray-700 hover:bg-gray-200 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900 transition ease-in-out duration-150"
                            >Release notes</a
                        >
                        <a
                            href="http://docs.trifle.app"
                            target="_blank"
                            @click="showInfo = !showInfo"
                            role="menuitem"
                            class="block w-full px-4 py-2 text-left text-sm leading-5 whitespace-no-wrap text-gray-700 hover:bg-gray-200 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900 transition ease-in-out duration-150"
                            >Documentation</a
                        >
                        <a
                            href="mailto:feedback@trifle.app"
                            @click="showInfo = !showInfo"
                            role="menuitem"
                            class="block w-full px-4 py-2 text-left text-sm leading-5 whitespace-no-wrap text-gray-700 hover:bg-gray-200 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900 transition ease-in-out duration-150"
                            >Feedback</a
                        >
                    </div>
                </div>
            </div>
            <div class="relative w-8 h-8 ml-2 rounded-full">
                <button
                    @click.prevent.stop="showMenu = !showMenu"
                    aria-label="User menu"
                    aria-haspopup="true"
                    class="w-full overflow-hidden w-full m-auto rounded-full border-2 border-gray-400 text-gray-400 hover:text-gray-800 focus:outline-none focus:text-gray-800 hover:border-gray-800 focus:border-gray-800"
                >
                    <svg fill="currentColor" viewBox="0 0 24 24">
                        <path
                            d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z"
                        />
                    </svg>
                </button>
                <div
                    :class="{ hidden: !showMenu }"
                    class="origin-top-right absolute right-0 mt-2 mr-1 w-48 z-30 border border-gray-200 rounded-md shadow-lg"
                >
                    <div
                        role="menu"
                        aria-orientation="vertical"
                        aria-labelledby="user-menu"
                        class="py-1 rounded-md bg-white shadow-xs"
                    >
                        <router-link
                            :to="{ name: 'settings' }"
                            role="menuitem"
                            class="block w-full px-4 py-2 text-left text-sm leading-5 whitespace-no-wrap text-gray-700 hover:bg-gray-200 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900 transition ease-in-out duration-150"
                            >Settings</router-link
                        >
                    </div>
                </div>
            </div>
        </div>
    </header>
</template>

<script>
import { mapGetters } from 'vuex'
import HeaderName from '@/components/HeaderName'
import Logotype from '@/components/sidebar/Logotype'

export default {
    name: 'Header',
    components: {
        HeaderName,
        Logotype,
    },
    data() {
        return {
            showMenu: false,
            showInfo: false,
            selected: null,
        }
    },
    computed: {
        ...mapGetters('prices', {
            price: 'price',
        }),
        ...mapGetters('projects', {
            project: 'project',
        }),
    },
    methods: {
        hideMenu: function () {
            this.showMenu = false
        },
        hideInfo: function () {
            this.showInfo = false
        },
        changeProject: function () {
            console.log('changeProject:')
            console.log(this.selected)
        },
    },
    created: function () {
        window.addEventListener('click', this.hideMenu)
        window.addEventListener('click', this.hideInfo)
    },
    destroyed: function () {
        window.removeEventListener('mousemove', this.hideMenu)
        window.removeEventListener('mousemove', this.hideInfo)
    },
}
</script>
