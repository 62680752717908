import Vue from 'vue'
import { roundPrice } from '@/helpers'

const state = Vue.observable({
    projects: window.localStorage.getItem('projects')
        ? JSON.parse(window.localStorage.getItem('projects'))
        : [],
    project: null,
})

// getters
const getters = {
    projects: (state) => state.projects,
    project: (state) => state.project,
    profit: (state, getters) => (prices) => {
        let total = getters.totalPrice
        let cost = getters.totalCost(prices)
        return total - cost
    },
    computedPrice: (state, getters, rootState, rootGetters) => {
        let totalPrice = 0
        state.project.prices?.forEach((pr) => {
            let price = rootState.prices.prices.find((p) => p.id === pr.id)
            if (pr.active) {
                totalPrice += rootGetters['prices/totalPrice'](price)
            }
        })
        return totalPrice
    },
    totalPrice: (state, getters) => {
        let totalPrice = 0
        if (state.project.type === 'COMPUTED') {
            totalPrice = getters.computedPrice
        } else {
            totalPrice = state.project.fixedPrice || 0
        }
        if (state.project.roundPrice.enabled) {
            totalPrice = roundPrice(
                totalPrice,
                state.project.roundPrice.direction,
                state.project.roundPrice.amount
            )
        }
        return totalPrice
    },
    computedPriceIncTax: (state, getters, rootState, rootGetters) => {
        let totalPrice = 0
        state.project.prices?.forEach((pr) => {
            let price = rootState.prices.prices.find((p) => p.id === pr.id)
            if (pr.active) {
                totalPrice += rootGetters['prices/totalPriceIncTax'](price)
            }
        })
        return totalPrice
    },
    totalPriceIncTax: (state, getters) => {
        if (state.project.type === 'COMPUTED') {
            let totalPrice = getters.computedPriceIncTax
            return totalPrice
        } else {
            let totalPrice = state.project.fixedPrice || 0
            return totalPrice
        }
    },
    margin: (state, getters) => (prices) => {
        let cost = getters.totalCost(prices)
        let total = getters.totalPrice
        let diff = total - cost
        let margin = (diff / total) * 100
        return margin || 0
    },
    totalCost: (state, getters, rootState, rootGetters) => (prices) => {
        let v = 0
        prices.forEach((price) => {
            if (price.active) {
                let p = rootState.prices.prices.find((p) => p.id === price.id)
                v += rootGetters['prices/totalCost'](p)
            }
        })
        return v
    },
    totalCostIncMarkupIncTax:
        (state, getters, rootState, rootGetters) => (prices) => {
            let v = 0
            prices.forEach((price) => {
                if (price.active) {
                    let p = rootState.prices.prices.find(
                        (p) => p.id === price.id
                    )
                    v += rootGetters['prices/totalCostIncMarkupIncTax'](p)
                }
            })

            return v
        },
    totalTimeHours: (state, getters, rootState, rootGetters) => (prices) => {
        let v = 0
        prices.forEach((price) => {
            if (price.active) {
                v += rootGetters['prices/totalTimeHours'](price)
            }
        })
        return v
    },
    totalTimeDays: (state, getters, rootState, rootGetters) => (prices) => {
        let v = 0
        prices.forEach((price) => {
            if (price.active) {
                v += rootGetters['prices/totalTimeDays'](price)
            }
        })
        return v
    },
}

// mutations
const mutations = {
    ADD_PROJECT(state, project) {
        state.projects.push(project)
        window.umami(
            `ADD_PROJECT_${localStorage.getItem('trifle-session-id')}_COUNT_${state.projects.length}`
        )
    },
    SET_PROJECT(state, project) {
        state.project = project
        if (project) {
            document.title = project.name + ' – Trifle'
        }
    },
    UPDATE_PROJECT(state, project) {
        state.project.name = project
    },
    SET_PRICE_TOTAL_MODE(state, mode) {
        Vue.set(state.project, 'priceTotalMode', mode)
    },
    SET_PRICE_TOTAL(state, price) {
        Vue.set(state.project, 'fixedPrice', price)
    },
    DELETE_PROJECT(state, idx) {
        state.projects.splice(idx, 1)
    },
    SET_HEADLINE_PRICE_SETTINGS(state, settings) {
        state.project.type = settings.type
        if (state.project.type === 'COMPUTED') {
            Vue.delete(state.project, 'fixedPrice')
        } else {
            state.project.fixedPrice = settings.fixedPrice
        }
        state.project.roundPrice = settings.roundPrice
    },
    DELETE_PRICE_FROM_PROJECTS(state, id) {
        console.log('DELETE_PRICE_FROM_PROJECTS')
        state.projects.forEach((project) => {
            project.prices.forEach((price, index) => {
                console.log(price.id, id)
                if (price.id === id) {
                    project.prices.splice(index, 1)
                }
            })
        })
    },
}

// actions
const actions = {
    addProject({ commit }, project) {
        commit('ADD_PROJECT', project)
    },
    setProject({ commit }, idx) {
        let project = state.projects.find((p) => p.id === idx)
        project.prices.forEach((price) => {
            if (!Object.prototype.hasOwnProperty.call(price, 'active')) {
                Vue.set(price, 'active', true)
            }
        })
        commit('SET_PROJECT', project)
    },
    updateProject({ commit }, project) {
        commit('UPDATE_PROJECT', project)
    },
    deleteProject({ commit }, id) {
        const idx = state.projects.findIndex((project) => project.id === id)
        commit('DELETE_PROJECT', idx)
    },
    clearProject({ commit }) {
        commit('SET_PROJECT', null)
    },
}

const vm = new Vue()
vm.$watch(
    () => state,
    () => {
        window.localStorage.setItem('projects', JSON.stringify(state.projects))
    },
    {
        deep: true,
    }
)

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}
