<template>
    <button
        @click="$emit('click')"
        @keyup.a="$emit('keyUp')"
        :class="{
            'hover:opacity-50 mr-2 cursor-not-allowed': disabled,
            '': !disabled,
        }"
        class="p-1 w-8 h-8 rounded border-current text-gray-800 border hover:opacity-50"
    >
        <slot></slot>
    </button>
</template>

<script>
export default {
    name: 'SortButton',
    props: {
        disabled: {
            type: Boolean,
            default: false,
        },
    },
}
</script>
