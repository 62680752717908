<template>
    <svg
        SIX-DOTS
        fill="currentColor"
        viewBox="0 0 12 18"
        width="12"
        height="18"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path fill="none" d="M0 0h24v24H0V0z" />
        <path
            d="M 5 15 C 5 16.1 4.1 17 3 17 C 1.9 17 1 16.1 1 15 C 1 13.9 1.9 13 3 13 C 4.1 13 5 13.9 5 15 Z M 3 7 C 1.9 7 1 7.9 1 9 C 1 10.1 1.9 11 3 11 C 4.1 11 5 10.1 5 9 C 5 7.9 4.1 7 3 7 Z M 3 1 C 1.9 1 1 1.9 1 3 C 1 4.1 1.9 5 3 5 C 4.1 5 5 4.1 5 3 C 5 1.9 4.1 1 3 1 Z M 9 5 C 10.1 5 11 4.1 11 3 C 11 1.9 10.1 1 9 1 C 7.9 1 7 1.9 7 3 C 7 4.1 7.9 5 9 5 Z M 9 7 C 7.9 7 7 7.9 7 9 C 7 10.1 7.9 11 9 11 C 10.1 11 11 10.1 11 9 C 11 7.9 10.1 7 9 7 Z M 9 13 C 7.9 13 7 13.9 7 15 C 7 16.1 7.9 17 9 17 C 10.1 17 11 16.1 11 15 C 11 13.9 10.1 13 9 13 Z"
        />
    </svg>
</template>

<script>
export default {
    name: 'IconDrag',
}
</script>
