<template>
    <div>
        <div v-if="project" class="space-y-4">
            <div class="flex justify-between">
                <ProjectTotal></ProjectTotal>
                <ProjectMenu
                    class="justify-end"
                    @duplicate-project="duplicateProject"
                    @delete-project="deleteProject"
                ></ProjectMenu>
            </div>

            <ProjectSummary
                :project="project"
                :prices="prices"
            ></ProjectSummary>

            <div class="border-t-4 border-gray-800 leading-none"></div>

            <button
                :class="{
                    'p-4 sm:px-16 sm:py-8 w-full sm:w-auto':
                        !project.prices || project.prices.length == 0,
                    'px-4 py-2': project.prices,
                }"
                @click="showAddPriceModal = true"
                class="flex items-center space-x-2 rounded text-gray-800 border-2 border-dashed border-gray-400 hover:opacity-50"
            >
                <div class="flex-shrink-0 w-6 h-6">
                    <IconAdd></IconAdd>
                </div>
                <p
                    v-if="!project.prices || project.prices.length == 0"
                    class="text-xl"
                >
                    Add some prices to this project
                </p>
                <p v-else class="text-lg">Add prices</p>
            </button>

            <div v-if="project.prices" class="overflow-hidden">
                <draggable
                    class="space-y-4"
                    handle=".handle"
                    v-model="project.prices"
                >
                    <PriceCard
                        v-for="(price, index) in prices"
                        :key="index"
                        :price="price"
                        :active="project.prices[index].active"
                        :settings="settings"
                        @delete-price="deletePrice(index)"
                        @toggle-active="toggleActive(index)"
                    ></PriceCard>
                </draggable>
            </div>

            <AddPriceModal
                v-if="showAddPriceModal"
                @select-price="addPrice"
                @close="showAddPriceModal = false"
            ></AddPriceModal>

            <HeadlinePriceModal
                v-if="showHeadlinePriceModal"
                :price="project"
                :totalCostIncMarkup="computedPrice"
                :totalPriceIncTax="totalPriceIncTax"
                :incTax="false"
                :type="'project'"
                @save="setHeadlinePrice"
                @close="showHeadlinePriceModal = false"
            ></HeadlinePriceModal>
        </div>
        <div v-else class="text-2xl font-black mb-4">Project not found</div>
    </div>
</template>

<script>
import Vue from 'vue'
import { mapGetters, mapActions } from 'vuex'
import draggable from 'vuedraggable'
import IconAdd from '@/components/icons/IconAdd'
import ProjectMenu from '@/components/projects/ProjectMenu'
import PriceCard from '@/components/projects/PriceCard'
import AddPriceModal from '@/components/modals/AddPriceModal'
import ProjectTotal from '@/components/projects/ProjectTotal'
import ProjectSummary from '@/components/projects/ProjectSummary'
import HeadlinePriceModal from '@/components/modals/HeadlinePriceModal'

export default {
    name: 'ProjectView',
    components: {
        draggable,
        IconAdd,
        ProjectMenu,
        PriceCard,
        AddPriceModal,
        ProjectTotal,
        ProjectSummary,
        HeadlinePriceModal,
    },
    data() {
        return {
            showAddPriceModal: false,
            showHeadlinePriceModal: false,
        }
    },
    computed: {
        ...mapGetters('projects', {
            project: 'project',
        }),
        ...mapGetters('settings', {
            settings: 'settings',
        }),
        computedPrice() {
            return this.$store.getters['projects/computedPrice']
        },
        totalPrice() {
            return this.$store.getters['projects/totalPrice']
        },
        totalPriceIncTax() {
            return this.$store.getters['projects/totalPriceIncTax']
        },
        prices() {
            let prices = []
            this.project.prices?.forEach((pr) => {
                let price = this.$store.state.prices.prices.find(
                    (p) => p.id === pr.id
                )
                price.active = pr.active
                prices.push(price)
            })
            return prices
        },
    },
    methods: {
        ...mapActions('projects', {
            setProject: 'setProject',
        }),
        ...mapActions('prices', {
            setPrice: 'setPrice',
        }),
        addPrice(price) {
            if (!this.project.prices) {
                Vue.set(this.project, 'prices', [])
            }
            let p = {
                id: price.id,
                active: true,
            }
            this.project.prices.unshift(p)
            this.showAddPriceModal = false
        },
        setFixedPrice(price) {
            this.$store.commit('projects/SET_PRICE_TOTAL_MODE', 'FIXED')
            this.$store.commit('projects/SET_PRICE_TOTAL', price)
        },
        deletePrice(index) {
            this.project.prices.splice(index, 1)
        },
        deleteProject() {
            this.$root.$emit('delete-project', this.project)
        },
        duplicateProject() {
            this.$root.$emit('duplicate-project', this.project)
        },
        toggleActive(index) {
            let price = this.project.prices[index]
            if (!Object.prototype.hasOwnProperty.call(price, 'active')) {
                Vue.set(price, 'active', true)
            }
            price.active = !price.active
        },
        setHeadlinePrice(options) {
            this.$store.commit('projects/SET_HEADLINE_PRICE_SETTINGS', options)
            this.showHeadlinePriceModal = false
        },
    },
    mounted() {
        window.umami(
            `VIEW_PROJECT_${this.$store.state.session.sessionId}_${this.$route.params.projectid}`
        )

        if (this.$route.params.projectid) {
            const projectid = this.$route.params.projectid
            this.setProject(projectid)
            this.setPrice(null)
        }
        window.addEventListener('click', this.clicked, false)
        this.$root.$on('show-headline-price-modal', () => {
            this.showHeadlinePriceModal = true
        })
    },
    beforeDestroy() {
        window.removeEventListener('click', this.clicked, false)
        this.$root.$off('show-headline-price-modal')
    },
}
</script>
