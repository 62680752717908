<template>
    <div class="NewButton">
        <!-- click button to toggle showing the menu -->
        <button
            @click.prevent.stop="showMenu = !showMenu"
            aria-label="Add New"
            class="flex items-center rounded-full hover:text-gray-500 focus:text-gray-500 focus:outline-none"
        >
            <div class="w-8 h-8 mr-2">
                <IconAdd></IconAdd>
            </div>
            <div class="inline-block h-4 w-4">
                <IconChevron></IconChevron>
            </div>
        </button>
        <div
            class="origin-top-left absolute z-20 left-0 mt-2 w-auto border border-gray-200 rounded-md shadow-lg"
            :class="{ hidden: !showMenu }"
        >
            <!-- this is the menu-->
            <div class="rounded-md bg-white">
                <div class="py-1">
                    <button
                        class="block w-full px-4 py-2 text-left text-sm leading-5 whitespace-no-wrap text-gray-800 hover:bg-gray-200 hover:text-gray-900 focus:outline-none focus:bg-gray-200 focus:text-gray-900"
                        @click.prevent.stop="$root.$emit('new-price')"
                    >
                        New price
                    </button>
                    <button
                        class="hidden w-full px-4 py-2 text-left text-sm leading-5 whitespace-no-wrap text-gray-800 hover:bg-gray-200 hover:text-gray-900 focus:outline-none focus:bg-gray-200 focus:text-gray-900"
                        @click.prevent.stop="$root.$emit('new-project')"
                    >
                        New project
                    </button>
                    <button
                        class="block w-full px-4 py-2 text-left text-sm leading-5 whitespace-no-wrap text-gray-800 hover:bg-gray-200 hover:text-gray-900 focus:outline-none focus:bg-gray-200 focus:text-gray-900"
                        @click.prevent.stop="$root.$emit('new-folder')"
                    >
                        New folder
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import IconAdd from '@/components/icons/IconAdd'
import IconChevron from '@/components/icons/IconChevron'

export default {
    name: 'NewButton',
    components: {
        IconAdd,
        IconChevron,
    },
    data() {
        return {
            showMenu: false,
        }
    },
    methods: {
        hideMenu: function () {
            this.showMenu = false
        },
    },
    created: function () {
        window.addEventListener('click', this.hideMenu)
    },
    destroyed: function () {
        window.removeEventListener('click', this.hideMenu)
    },
    mounted() {},
}
</script>
