<template>
    <div class="flex items-center space-x-2">
        <div class="border-gray-300 border-r pr-2">Colour</div>

        <button
            v-for="color in colors"
            :key="color"
            @click="$emit('color-selected', color)"
            class="w-4 h-4 rounded-full focus:outline-none hover:ring-2 focus:ring-2"
            :class="[
                colorClass(color),
                color === selectedColor ? 'ring-2' : '',
            ]"
        ></button>
    </div>
</template>

<script>
export default {
    name: 'ColorPicker',
    props: {
        selectedColor: {
            type: String,
            default: 'gray',
        },
    },
    data() {
        return {
            colors: ['gray', 'red', 'green', 'blue', 'purple', 'yellow'],
        }
    },
    methods: {
        colorClass(color) {
            return 'bg-' + color + '-300 ring-' + color + '-400'
        },
    },
}
</script>
