<template>
    <div RowQty class="flex-0 w-24 lg:w-16 print:w-full">
        <label
            class="block w-full text-xs border-b border-current lg:hidden print:hidden text-gray-600 px-1"
            >Qty</label
        >
        <div
            class="w-full lg:h-full lg:leading-2 bg-white rounded-l border-r border-gray-400 print:border-0"
        >
            <input
                v-model.number="qty"
                aria-label="Quantity"
                type="number"
                step="any"
                placeholder="0"
                :disabled="disabled"
                class="w-full p-1 print:pr-2 align-middle text-right rounded-bl lg:rounded-l print:rounded-l"
                :class="{
                    'cursor-default bg-gray-200': disabled,
                    'bg-white': !disabled,
                    'line-through': !active,
                }"
            />
        </div>
    </div>
</template>

<script>
export default {
    name: 'RowQty',
    props: {
        value: Number,
        disabled: {
            type: Boolean,
            default: false,
        },
        active: {
            type: Boolean,
            default: true,
        },
    },
    computed: {
        qty: {
            get() {
                return this.value
            },
            set(newValue) {
                this.$emit('input', newValue || 0)
            },
        },
    },
}
</script>
