<template>
    <div class="flex flex-col">
        <svg
            class="transform rotate-180"
            viewBox="0 2 18 18"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fill-rule="evenodd"
                d="M 1.469 6.509 C 2.091 5.86 3.102 5.86 3.729 6.509 L 8.991 11.986 L 14.253 6.509 C 15.107 5.587 16.598 5.975 16.938 7.205 C 17.101 7.798 16.938 8.434 16.512 8.861 L 10.12 15.514 C 9.495 16.161 8.484 16.161 7.861 15.514 L 1.469 8.861 C 0.843 8.212 0.843 7.157 1.469 6.509 Z"
                clip-rule="evenodd"
                bx:origin="0.437514 0.600057"
            />
        </svg>
        <svg
            class="transform rotate-0 -mt-px"
            viewBox="0 2 18 18"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fill-rule="evenodd"
                d="M 1.469 6.509 C 2.091 5.86 3.102 5.86 3.729 6.509 L 8.991 11.986 L 14.253 6.509 C 15.107 5.587 16.598 5.975 16.938 7.205 C 17.101 7.798 16.938 8.434 16.512 8.861 L 10.12 15.514 C 9.495 16.161 8.484 16.161 7.861 15.514 L 1.469 8.861 C 0.843 8.212 0.843 7.157 1.469 6.509 Z"
                clip-rule="evenodd"
                bx:origin="0.437514 0.600057"
            />
        </svg>
    </div>
</template>

<script>
export default {
    name: 'IconExpand',
}
</script>
