<template>
    <div
        class="rowchild w-full relative flex flex-wrap lg:flex-nowrap print:flex-nowrap justify-between rounded-r-sm mb-1 bg-gray-200"
        :class="[depth > 0 ? 'border-r-0 rounded-r-none' : '']"
    >
        <div class="flex justify-between">
            <div
                v-if="mode !== modes.REFERENCE"
                class="flex space-x-4 lg:space-x-0 lg:flex-col py-1 lg:py-0 mt-1"
            >
                <div class="flex items-center pl-2 relative">
                    <RowNumber :number="number"></RowNumber>
                    <div
                        class="h-3/4 opacity-25 border-l border-current lg:border-0 print:border-0"
                    ></div>
                </div>

                <div
                    v-if="mode === modes.DEFAULT"
                    class="flex justify-between items-center space-x-4 lg:space-x-2 px-2 lg:px-1 print:hidden"
                >
                    <div
                        :class="{ 'w-4': !isOutdentable }"
                        class="inline-flex items-center"
                    >
                        <button
                            v-show="isOutdentable"
                            @click.prevent="outdentClicked"
                            aria-label="Outdent row"
                            class="group relative cursor-pointer text-gray-400 hover:text-gray-800"
                        >
                            <IconIndentRight></IconIndentRight>
                            <div class="tooltip tooltip-left">Outdent row</div>
                        </button>
                    </div>

                    <button
                        aria-label="Drag row to reorder"
                        class="group relative handle cursor-move inline-flex justify-center w-4 text-gray-400 hover:text-gray-800"
                    >
                        <IconDrag></IconDrag>
                        <div class="tooltip tooltip-left">Reorder row</div>
                    </button>

                    <div
                        :class="{ 'w-4': !isIndentable }"
                        class="inline-flex items-center"
                    >
                        <button
                            v-show="isIndentable"
                            @click.prevent="indentClicked"
                            aria-label="Indent row"
                            class="group relative cursor-pointer text-gray-400 hover:text-gray-800"
                        >
                            <IconIndentLeft></IconIndentLeft>
                            <div class="tooltip tooltip-left">Indent row</div>
                        </button>
                    </div>
                </div>
            </div>

            <!-- spacer-->
            <div
                v-if="mode === modes.DEFAULT"
                class="hidden lg:block w-8"
            ></div>
        </div>

        <!-- debug -->
        <div v-if="settings.debug.showJsonModels" class="text-xs">
            <p v-if="model.id">id: {{ model.id }}</p>
            <p v-if="model.root_id">root_id: {{ model.root_id }}</p>
            <p v-if="model.parent_id">parent_id: {{ model.parent_id }}</p>
            <p v-if="model.link_id">link_id {{ model.link_id }}</p>
            <p v-if="model.price_id">price_id {{ model.price_id }}</p>
        </div>

        <div v-if="mode !== modes.DEFAULT" class="ml-2 my-1 print:hidden">
            <SortButton class="mr-2" @click="$root.$emit('add-row', model)">
                <IconAdd></IconAdd>
            </SortButton>
        </div>
        <div v-else class="flex my-1 print:hidden">
            <RowAddMenu
                :disabled="editable"
                :showReferenceButton="true"
                @duplicateRow="duplicateRow"
                @addParentRowBelow="addParentRowBelow"
                @addBlankRowBelow="addNewRowBelow"
                @importRowBelow="importRowBelow"
            ></RowAddMenu>
            <RowContextMenu
                :isReference="isReference"
                :source="model.source"
                :disabled="editable"
                :active="isActive"
                :color="model.color"
                @color-selected="colorSelected"
                @convertToIndependent="
                    $root.$emit('convert-to-independent', model)
                "
                @deleteRow="deleteThisRow"
                @toggle-active="toggleRowActive"
            ></RowContextMenu>
        </div>

        <div
            class="w-full lg:w-auto mx-2 lg:mx-0 flex justify-between lg:flex-1 mb-2 lg:my-1 border border-gray-800 rounded bg-white"
        >
            <div class="flex flex-col self-stretch w-full">
                <!-- Temp to notify of references removal -->
                <div
                    v-if="isReference"
                    @click="$root.$emit('convert-to-independent', model)"
                    class="group flex w-full p-px relative cursor-pointer bg-gray-200 rounded-tl-md border-b border-gray-400"
                >
                    <div
                        class="inline-block text-xs font-bold bg-red-400 text-white rounded-full w-4 h-4 text-center"
                    >
                        !
                    </div>
                    <div class="tooltip tooltip-left">
                        References have been removed (for now)<br />
                        – Speak to Ben if you need help<br />
                        – Click to make unique
                    </div>
                    <div class="ml-1 opacity-50 leading-none mt-px">
                        {{ reference_model.linked_description }}
                    </div>
                </div>

                <RowDescription
                    v-if="
                        model.show_description &&
                        ((isReference && mode === modes.DEFAULT) ||
                            !isReference)
                    "
                    v-model="description"
                    :active="isActive"
                    :disabled="mode !== modes.DEFAULT || editable"
                ></RowDescription>
            </div>

            <RowUom
                v-model="uom"
                :editable="editable"
                :disabled="mode !== modes.DEFAULT"
            ></RowUom>
        </div>

        <div
            class="w-full print:w-48 mx-2 mb-2 lg:my-1 ml-2 print:mr-0 self-start flex border border-gray-800 rounded"
            :class="{
                'lg:w-24': mode === modes.REFERENCE,
                'lg:w-72': mode !== modes.REFERENCE,
            }"
        >
            <RowQty
                v-if="mode !== modes.REFERENCE"
                v-model="model.qty"
                :active="isActive"
                :disabled="mode !== modes.DEFAULT || editable"
            ></RowQty>

            <RowRate
                v-model="rate"
                :editable="editable"
                :settings="settings"
                :disabled="mode !== modes.DEFAULT"
                :active="isActive"
            ></RowRate>

            <RowTotal
                v-if="mode !== modes.REFERENCE"
                :model="model"
                :markupRowTotal="model.markup_total"
                :qty="model.qty"
                :rate="rate"
                :active="isActive"
                :settings="settings"
                @toggle-markup="toggleMarkup"
            ></RowTotal>
        </div>

        <transition name="fade">
            <div
                v-if="disabled"
                class="w-full h-full absolute left-0 top-0 bg-gray-100 opacity-90 rounded-sm"
            ></div>
        </transition>
    </div>
</template>

<script>
import Vue from 'vue'
import { mapGetters, mapMutations } from 'vuex'
import { modes } from '@/constants'

import SortButton from '@/components/ui/SortButton'
import IconAdd from '@/components/icons/IconAdd'
import IconDrag from '@/components/icons/IconDrag'
import IconIndentRight from '@/components/icons/IconIndentRight'
import IconIndentLeft from '@/components/icons/IconIndentLeft'

import RowNumber from '@/components/row/RowNumber'
// import RowMove from '@/components/row/RowMove'
import RowAddMenu from '@/components/row/RowAddMenu'
import RowDescription from '@/components/row/description/RowDescription'
import RowUom from '@/components/row/uom/RowUom'
import RowQty from '@/components/row/qty/RowQty'
import RowRate from '@/components/row/rate/RowRate'
import RowTotal from '@/components/row/total/RowTotal'
import RowContextMenu from '@/components/row/RowContextMenu'

import { flatten } from '@/helpers'

export default {
    name: 'RowChild',
    components: {
        SortButton,
        IconAdd,
        IconDrag,
        IconIndentRight,
        IconIndentLeft,
        RowNumber,
        // RowMove,
        RowAddMenu,
        RowDescription,
        RowUom,
        RowQty,
        RowRate,
        RowTotal,
        RowContextMenu,
    },
    props: {
        model: {
            type: Object,
            default() {
                return {}
            },
        },
        parent: {
            type: Object,
            default() {
                return {}
            },
        },
        rootRow: {
            type: Object,
            default() {
                return {}
            },
        },
        depth: {
            type: Number,
            default: 0,
        },
        index: {
            type: Number,
            default: 0,
        },
        number: {
            type: String,
            default: '1',
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        editable: {
            type: Boolean,
            default: true,
        },
        mode: {
            type: String,
            default: 'DEFAULT',
        },
    },
    data() {
        return {
            modes,
        }
    },
    computed: {
        ...mapGetters('prices', {
            price: 'price',
        }),
        ...mapGetters('settings', {
            settings: 'settings',
        }),
        isOutdentable() {
            return this.depth > 0
        },
        isIndentable() {
            return true
        },
        isReference() {
            return this.model.is_reference // && this.mode !== 'ADD' // !== undefined ? true : false;
        },
        isActive() {
            if (this.mode === modes.DEFAULT) {
                return this.model.active
            } else {
                return true
            }
        },
        uom: {
            get() {
                if (this.isReference) {
                    return this.reference_model.uom
                } else {
                    return this.model.uom
                }
            },
            set(newValue) {
                if (this.isReference) {
                    this.reference_model.uom = newValue
                } else {
                    this.model.uom = newValue
                }
            },
        },
        rate: {
            get() {
                if (this.isReference) {
                    return this.reference_model.rate
                } else {
                    return this.model.rate
                }
            },
            set(newValue) {
                if (this.isReference) {
                    //return this.reference_model.rate;
                    this.reference_model.rate = newValue
                } else {
                    this.model.rate = newValue
                }
            },
        },
        description: {
            get() {
                return this.model.is_reference && this.mode !== modes.DEFAULT
                    ? this.model.linked_description
                    : this.model.description
            },
            set(newValue) {
                if (this.model.is_reference && this.mode !== modes.DEFAULT) {
                    //this.model.linked_description = newValue
                } else {
                    this.model.description = newValue
                }
            },
        },
        reference_model() {
            let price_id = this.model.price_id || this.model.id
            let price = this.$store.state.prices.prices.filter(
                (p) => p.id === price_id
            )[0]
            let rows = flatten(price.rows)
            return rows.filter((row) => {
                return row.id === this.model.link_id
            })[0]
        },
    },
    methods: {
        outdentClicked() {
            let payload = {
                row: this.model,
                parent: this.parent,
                index: this.index,
            }
            this.$emit('outdent-row', payload)
        },
        indentClicked() {
            let payload = {
                row: this.model,
                parent: this.parent,
                index: this.index,
            }
            this.$store.dispatch('prices/indentRow', payload)
        },
        toggleMarkup() {
            Vue.set(this.model, 'markup_total', !this.model.markup_total)
        },
        colorSelected(color) {
            Vue.set(this.model, 'color', color)
        },
        ...mapMutations('sorter', {
            setPrice: 'setPrice',
        }),
        toggleDescription() {
            this.model.show_description = !this.model.show_description
        },
        toggleRowActive() {
            if (!Object.prototype.hasOwnProperty.call(this.model, 'active')) {
                Vue.set(this.model, 'active', true)
            }
            this.model.active = !this.model.active
        },
        addParentRowBelow() {
            let payload = {
                model: this.parent,
                index: this.index + 1,
            }
            this.$store.dispatch('prices/addParentRow', payload)
        },
        addParentRowChild() {
            let payload = {
                model: this.model,
                index: this.index + 1,
            }

            if (!this.isReference) {
                this.$store.dispatch('prices/addParentToModelAtIndex', payload)
            } else {
                this.$store.dispatch(
                    'prices/addParentToReferenceModelAtIndex',
                    payload
                )
            }
        },
        addNewRowBelow() {
            //add the row to our parent so it appears below this one
            let model = this.parent
            let index = this.index + 1
            this.$store.dispatch('prices/addRowToModelAtIndex', {
                model,
                index,
            })
        },
        addNewRowChild() {
            let model = this.model
            let index = this.index + 1

            if (!this.isReference) {
                this.$store.dispatch('prices/addChildToModelAtIndex', {
                    model,
                    index,
                })
            } else {
                this.$store.dispatch('prices/addChildToReferenceModelAtIndex', {
                    model,
                    index,
                })
            }
        },
        importRowBelow() {
            console.log('importRowBelow')
            this.$root.$emit('show-import-modal', this.parent, this.index + 1)
        },
        importRowChild() {
            let index = this.model.rows ? this.model.rows.length : 0
            this.$root.$emit('show-import-modal', this.model, index)
        },
        referenceRowBelow() {
            this.$root.$emit(
                'show-reference-modal',
                this.parent,
                this.index + 1
            )
        },
        referenceRowChild() {
            let index = this.model.rows ? this.model.rows.length : 0
            this.$root.$emit('show-reference-modal', this.model, index)
        },
        deleteThisRow() {
            if (window.confirm('Are you sure you want to delete this row?')) {
                this.model.depth = this.depth
                this.model.index = this.index

                let row = this.model
                let model = this.parent
                this.$store.dispatch('prices/deleteRowFromModel', {
                    row,
                    model,
                })
            }
        },
        duplicateRow() {
            let row = this.model
            let model = this.parent
            let index = this.index + 1
            this.$store.dispatch('prices/duplicateRowInModelAtIndex', {
                row,
                model,
                index,
            })
        },
    },
}
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.25s;
}
.fade-enter,
.fade-leave-to {
    opacity: 0;
}
</style>
