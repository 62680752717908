<template>
    <div
        class="fixed z-20 bottom-0 top-0 inset-x-0 px-4 py-4 flex items-center max-w-xl mx-auto"
    >
        <div class="fixed inset-0 transition-opacity">
            <div
                @click="$emit('close')"
                class="absolute inset-0 bg-gray-500 opacity-75"
            ></div>
        </div>
        <div
            class="relative bg-white overflow-y-auto max-h-full h-auto rounded-lg p-4 md:p-6 shadow-xl transform transition-all w-full"
        >
            <div class="absolute top-0 right-0">
                <button
                    @click="$emit('close')"
                    type="button"
                    class="p-4 text-gray-400 hover:text-gray-500 focus:outline-none focus:text-gray-500 transition ease-in-out duration-150"
                >
                    <IconClose></IconClose>
                </button>
            </div>

            <div>
                <h3 class="w-full text-2xl leading-6 font-medium text-gray-900">
                    Headline Price for {{ type }}
                </h3>
                <p class="mb-12">Set how a price is calculated</p>

                <div class="space-y-12">
                    <div
                        class="grid md:grid-cols-5 gap-x-4 gap-y-8 leading-none"
                    >
                        <div
                            class="md:col-span-2 border-t-4 border-gray-800 text-gray-800 pr-4"
                        >
                            <label class="text-xs text-gray-500"
                                >Price excl.</label
                            >
                            <div class="flex space-x-1 text-2xl -mt-1">
                                <div class="text-gray-500">
                                    {{ settings.currency }}
                                </div>
                                <div class="w-full text-right font-bold">
                                    {{ separator(priceExcl) }}
                                </div>
                            </div>
                        </div>

                        <div
                            v-if="incTax"
                            class="border-t-4 border-gray-800 text-gray-800 pr-4"
                        >
                            <label class="text-xs text-gray-500">Tax</label>
                            <div class="flex space-x-1 text-2xl -mt-1">
                                <input
                                    v-model.number="options.tax.rate"
                                    type="number"
                                    step="any"
                                    class="w-full px-1 border-b border-gray-500 bg-transparent font-bold text-right"
                                />
                                <div class="text-gray-500">%</div>
                            </div>
                        </div>

                        <div
                            v-if="showPriceInc"
                            class="md:col-span-2 border-t-4 border-gray-800 text-gray-800 pr-4"
                        >
                            <label class="text-xs text-gray-500"
                                >Price incl.</label
                            >
                            <div class="flex space-x-1 text-2xl -mt-1">
                                <div class="text-gray-500">
                                    {{ settings.currency }}
                                </div>
                                <div class="w-full text-right font-bold">
                                    {{ totalIncTax }}
                                </div>
                                <div class="w-full text-right font-bold">
                                    {{ separator(priceInc) }}
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- Calc or Fixed -->
                    <div
                        class="grid md:grid-cols-2 gap-x-4 gap-y-8 leading-none"
                    >
                        <div
                            class="border-t-4 border-gray-800 text-gray-800 pr-4"
                        >
                            <label
                                for="price_formula"
                                class="text-xs text-gray-500"
                                >Price calculation</label
                            >
                            <div class="text-2xl">
                                <select
                                    v-model="options.type"
                                    id="price_formula"
                                    name="price_formula"
                                    class="block w-full pb-1 bg-white border-b border-gray-500 text-base"
                                >
                                    <option value="COMPUTED">Calculated</option>
                                    <option value="FIXED">Fixed</option>
                                </select>
                            </div>
                        </div>

                        <div
                            class="border-t-4 border-gray-800 text-gray-800 pr-4"
                        >
                            <label class="text-xs text-gray-500">
                                <span v-if="options.type === 'FIXED'"
                                    >Fixed price excl.</span
                                >
                                <span v-else>Calculated (Cost + Mark-up)</span>
                            </label>
                            <div class="flex space-x-1 text-2xl -mt-1">
                                <div class="text-gray-500">
                                    {{ settings.currency }}
                                </div>
                                <input
                                    v-if="options.type === 'FIXED'"
                                    v-model.number="options.fixedPrice"
                                    type="number"
                                    step="any"
                                    class="w-full px-1 border-b border-gray-500 bg-transparent font-bold text-right"
                                />
                                <div
                                    v-else
                                    class="w-full text-gray-400 cursor-disabled font-bold text-right"
                                >
                                    {{ separator(totalCostIncMarkup) }}
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- Rounding-->
                    <div v-if="allowRounding">
                        <div
                            class="border-t-4 border-gray-800 text-gray-800 pr-4"
                        >
                            <label class="text-xs text-gray-500"
                                >Price rounding</label
                            >
                            <div class="text-2xl">
                                <button
                                    @click="
                                        options.roundPrice.enabled =
                                            !options.roundPrice.enabled
                                    "
                                    aria-pressed="false"
                                    type="button"
                                    name=""
                                    class="flex-shrink-0 group relative rounded-full inline-flex items-center justify-center h-5 w-10 cursor-pointer"
                                >
                                    <span class="sr-only"
                                        >Toggle rounding of price</span
                                    >
                                    <span
                                        aria-hidden="true"
                                        class="pointer-events-none absolute bg-white w-full h-full rounded-full"
                                    ></span>
                                    <span
                                        aria-hidden="true"
                                        :class="{
                                            'bg-gray-200':
                                                !options.roundPrice.enabled,
                                            'bg-green-200':
                                                options.roundPrice.enabled,
                                        }"
                                        class="pointer-events-none absolute h-4 w-9 mx-auto rounded-full transition-colors ease-in-out duration-200 bg-gray-200"
                                    ></span>
                                    <span
                                        aria-hidden="true"
                                        :class="{
                                            'translate-x-5':
                                                options.roundPrice.enabled,
                                        }"
                                        class="pointer-events-none absolute left-0 inline-block h-5 w-5 border border-gray-400 rounded-full bg-white shadow transform ring-0 transition-transform ease-in-out duration-200 translate-x-0"
                                    ></span>
                                </button>
                            </div>
                        </div>

                        <div
                            v-if="options.roundPrice.enabled"
                            class="grid md:grid-cols-2 gap-x-4 gap-y-8"
                        >
                            <div
                                class="border-t-4 border-gray-800 text-gray-800 pr-4"
                            >
                                <label class="text-xs text-gray-500"
                                    >Rounding amount</label
                                >
                                <div class="text-2xl -mt-1">
                                    <input
                                        v-model.number="
                                            options.roundPrice.amount
                                        "
                                        type="number"
                                        step="any"
                                        placeholder="250"
                                        class="w-full px-1 border-b border-gray-500 bg-transparent font-bold text-right"
                                    />
                                </div>
                            </div>

                            <div
                                class="border-t-4 border-gray-800 text-gray-800 pr-4"
                            >
                                <label
                                    for="round_price_direction"
                                    class="text-xs text-gray-500"
                                    >Rounding direction</label
                                >
                                <div class="text-2xl">
                                    <select
                                        v-model="options.roundPrice.direction"
                                        id="round_price_direction"
                                        name="round_price_direction"
                                        class="block w-full pb-1 bg-white border-b border-gray-500 text-base"
                                    >
                                        <option value="UP">Up</option>
                                        <option value="NEAREST">Nearest</option>
                                        <option value="DOWN">Down</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!--<div v-else>-->
                    <!--  <div class="border-t-4 border-gray-800 text-gray-800 pr-4">-->
                    <!--    <label class="text-xs text-gray-500">Price rounding</label>-->
                    <!--    <p><em>Rounding of Project Prices is not available yet.</em></p>-->
                    <!--  </div>-->
                    <!--</div>-->

                    <!-- Buttons -->
                    <div class="flex justify-end align-top">
                        <div class="inline-flex mr-2">
                            <button
                                @click="$emit('save', options)"
                                type="submit"
                                class="py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-gray-800 shadow-sm hover:bg-gray-500 focus:outline-none focus:shadow-outline-blue active:bg-gray-600 transition duration-150 ease-in-out"
                            >
                                Set price
                            </button>
                        </div>
                        <div class="inline-flex">
                            <button
                                @click="$emit('close')"
                                type="button"
                                class="py-2 px-4 border border-gray-300 rounded-md text-sm leading-5 font-medium text-gray-700 hover:bg-gray-300 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue transition duration-150 ease-in-out"
                            >
                                Cancel
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import { separator, roundPrice } from '@/helpers'
import IconClose from '@/components/icons/IconClose'

export default {
    name: 'HeadlinePriceModal',
    components: {
        IconClose,
    },
    props: {
        type: String,
        price: Object,
        totalIncTax: Number,
        totalCostIncMarkup: Number,
        totalPriceIncTax: Number,
        incTax: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            separator,
            options: {
                type: this.price.type,
                fixedPrice: this.price.fixedPrice || 0,
                tax: {
                    rate: this.price.tax?.rate || 0,
                },
                roundPrice: {
                    enabled: this.price.roundPrice.enabled,
                    amount: this.price.roundPrice.amount,
                    direction: this.price.roundPrice.direction,
                },
            },
        }
    },
    computed: {
        ...mapState('settings', {
            settings: 'settings',
        }),
        allowRounding() {
            if (this.options.type === 'FIXED') {
                return false
            }
            return this.type === 'price'
        },
        showPriceInc() {
            if (
                this.type === 'project' &&
                this.priceExcl !== this.priceIncProject
            ) {
                return true
            }
            return this.priceExcl !== this.priceInc
        },
        priceExcl() {
            let priceExcl = 0
            if (this.options.type === 'FIXED') {
                priceExcl = this.options.fixedPrice
            } else {
                priceExcl = this.totalCostIncMarkup
            }
            if (this.options.roundPrice.enabled) {
                priceExcl = roundPrice(
                    priceExcl,
                    this.options.roundPrice.direction,
                    this.options.roundPrice.amount
                )
            }
            return priceExcl
        },
        priceInc() {
            if (this.type === 'price') {
                return this.priceIncPrice
            } else {
                return this.priceIncProject
            }
        },
        priceIncPrice() {
            let priceInc = this.priceExcl
            let tax = 1 + this.options.tax.rate / 100
            priceInc = priceInc * tax
            return priceInc
        },
        priceIncProject() {
            let priceInc = 0
            if (this.options.type === 'COMPUTED') {
                priceInc = this.$store.getters['projects/computedPriceIncTax']
            } else {
                priceInc = this.priceExcl
            }
            return priceInc
        },
    },
}
</script>
