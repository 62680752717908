<template>
    <div RowTotal class="flex-1 print:hidden">
        <label
            class="block w-full text-xs border-b border-current lg:hidden text-gray-600 px-1"
            >Row Total</label
        >
        <button
            v-if="showMarkupToggle"
            @click="$emit('toggle-markup')"
            type="button"
            class="group relative w-full p-1 pl-0 lg:h-full flex space-x-1 items-center rounded-br lg:rounded-r bg-gray-200 border-l border-gray-400"
        >
            <div class="sr-only">Toggle Markup</div>
            <div v-if="!markupRowTotal" class="w-6 h-4 text-gray-500">
                <IconNarrowRight></IconNarrowRight>
                <div class="tooltip tooltip-right">Mark-up not applied</div>
            </div>
            <div v-if="markupRowTotal" class="w-6 h-4">
                <IconTrendingUp></IconTrendingUp>
                <div class="tooltip tooltip-right">Mark-up applied</div>
            </div>
            <div class="text-gray-500">{{ settings.currency }}</div>
            <div class="w-full text-right" :class="{ 'line-through': !active }">
                {{ separator(rowTotal) }}
            </div>
        </button>
        <div
            v-else
            class="w-full p-1 lg:h-full flex space-x-1 items-center rounded-br lg:rounded-r bg-gray-200 border-l border-gray-400"
        >
            <div class="text-gray-500">{{ settings.currency }}</div>
            <div class="w-full text-right" :class="{ 'line-through': !active }">
                {{ separator(rowTotal) }}
            </div>
        </div>
    </div>
</template>

<script>
import { separator } from '@/helpers'
import { mapState } from 'vuex'
import IconTrendingUp from '@/components/icons/IconTrendingUp'
import IconNarrowRight from '@/components/icons/IconNarrowRight'

export default {
    name: 'RowTotal',
    components: {
        IconTrendingUp,
        IconNarrowRight,
    },
    props: {
        currency: String,
        qty: Number,
        rate: [Number, String],
        model: Object,
        markupRowTotal: Boolean,
        settings: Object,
        active: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            separator,
        }
    },
    computed: {
        ...mapState('prices', {
            price: 'price',
        }),
        showMarkupToggle() {
            return this.price.markup.rate > 0
        },
        rowTotal() {
            let total = 0
            total = parseFloat(this.qty) * parseFloat(this.rate)

            if (isNaN(total)) {
                total = 0
            }

            if (this.markupRowTotal) {
                let markup =
                    1 + this.$store.state.prices.price.markup.rate / 100
                total *= markup
            }

            return total
        },
    },
}
</script>
