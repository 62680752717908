<template>
    <button
        @click.stop.prevent="nextUom"
        aria-label="Toggle unit of measure"
        :disabled="disabled"
        class="relative h-full hover:text-gray-500 border-l border-gray-400 rounded-r-sm"
    >
        <div class="leading-none group relative h-full">
            <div class="block h-full">
                <div
                    v-if="uom.name == 'time'"
                    class="relative cursor-pointer h-full"
                >
                    <div
                        class="relative rounded-r w-8 h-full p-1 bg-white cursor-pointer"
                    >
                        <IconClock></IconClock>
                        <div
                            class="absolute px-px left-0 top-4 rounded-full bg-inherit"
                        >
                            <div>{{ uomSymbol }}</div>
                            <div class="sr-only">Time in {{ uom.unit }}</div>
                        </div>
                    </div>
                </div>
                <div
                    v-if="uom.name == 'materials'"
                    class="rounded-r w-8 h-full p-1 bg-white cursor-pointer"
                    :class="{
                        'cursor-default bg-gray-200':
                            (reference || disabled) && !editable,
                    }"
                >
                    <IconTag></IconTag>
                    <div class="sr-only">Materials</div>
                </div>
            </div>
            <div class="tooltip tooltip-right">Unit of measure</div>
        </div>
    </button>
</template>

<script>
import IconTag from '@/components/icons/IconTag'
import IconClock from '@/components/icons/IconClock'

export default {
    name: 'RowUom',
    components: {
        IconTag,
        IconClock,
    },
    props: {
        value: Object,
        reference: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        editable: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            showMenu: false,
        }
    },
    computed: {
        uoms() {
            return this.$store.state.settings.uoms
        },
        uom: {
            get() {
                return this.value
            },
            set(newValue) {
                this.$emit('input', newValue)
            },
        },
        uomSymbol() {
            if (this.uom && this.uom.unit) {
                return this.uom.unit.charAt(0).toUpperCase()
            } else {
                return ''
            }
        },
    },
    methods: {
        nextUom() {
            if (this.reference && !this.editable) {
                this.$emit('edit-reference')
            } else {
                let currentIndex = this.uoms.findIndex(
                    (u) => u.name === this.uom.name && u.unit === this.uom.unit
                )
                currentIndex += 1
                if (currentIndex > this.uoms.length - 1) {
                    currentIndex = 0
                }
                this.uom = this.uoms[currentIndex]
                this.$store.dispatch(
                    'settings/setDefaultUom',
                    this.uoms[currentIndex]
                )
            }
        },
    },
}
</script>
