<template>
    <div class="rounded-l-sm border-l-8 origin-top-left" :class="[borderColor]">
        <RowParent
            v-if="isParent"
            :model="model"
            :parent="parent"
            :mode="mode"
            :rootRow="rootRow"
            :index="index"
            :depth="depth"
            :number="rowNumber"
            :disabled="rowDisabled(model)"
            :editable="rowIsBeingEdited(model)"
            @outdent-row="outdentRow"
        ></RowParent>
        <RowChild
            v-else
            :model="model"
            :parent="parent"
            :mode="mode"
            :rootRow="rootRow"
            :index="index"
            :depth="depth"
            :number="rowNumber"
            :disabled="rowDisabled(model)"
            :editable="rowIsBeingEdited(model)"
            @outdent-row="outdentRow"
        ></RowChild>
    </div>
</template>

<script>
import RowParent from '@/components/row/RowParent'
import RowChild from '@/components/row/RowChild'
import { getRowColor } from '@/row'

export default {
    name: 'Row',
    components: {
        RowParent,
        RowChild,
    },
    props: {
        model: {
            type: Object,
            default() {
                return {}
            },
        },
        parent: {
            type: Object,
            default() {
                return {}
            },
        },
        rootRow: {
            type: Object,
            default() {
                return {}
            },
        },
        depth: {
            type: Number,
            default: 0,
        },
        index: {
            type: Number,
            default: 0,
        },
        number: {
            type: String,
            default: '1',
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        editable: {
            type: Boolean,
            default: true,
        },
        mode: {
            type: String,
            default: 'DEFAULT',
        },
    },
    data() {
        return {
            importRow: false,
            linkedRowIndex: 0,
        }
    },
    computed: {
        rowNumber() {
            let n = this.number.toString()
            if (this.depth > 0) {
                n += '.' + (this.index + 1)
            }
            return n
        },
        isParent() {
            return this.model.rows && this.model.rows.length > 0 ? true : false
        },
        borderColor() {
            return 'border-' + getRowColor(this.model) + '-400'
        },
    },
    methods: {
        outdentRow(payload) {
            this.$emit('outdent-row', payload)
        },
        rowDisabled(row) {
            if (this.$store.getters['prices/editingReference'] !== '') {
                if (
                    row.id === this.$store.getters['prices/editingReference'] ||
                    row.link_id ===
                        this.$store.getters['prices/editingReference']
                ) {
                    return false
                }
                return true
            } else {
                return false
            }
        },
        rowIsBeingEdited(row) {
            if (row.is_reference) {
                if (this.$store.getters['prices/editingReference'] !== '') {
                    if (row.price_id === this.$store.state.prices.price.id) {
                        if (
                            row.id ===
                                this.$store.getters[
                                    'prices/editingReference'
                                ] ||
                            row.link_id ===
                                this.$store.getters['prices/editingReference']
                        ) {
                            return true
                        }
                    }
                }
            }
            return false
        },
    },
}
</script>

<style>
.rotatable {
    transform: rotate(-90deg);
    transition: all ease-out 0.05s;
}
.rotatable:focus {
    outline: 0;
}
.rotatable.rotated {
    transform: rotate(0deg);
}
.sortable-ghost {
    border: 2px dashed red !important;
    padding-left: 1rem;
}
.sortable-chosen {
    opacity: 0.5;
}
</style>
