<template>
    <div class="inline-block lg:block mr-2">
        <div
            class="text-xs print:text-lg text-gray-500 print:text-black lg:leading-none"
        >
            {{ number }}
        </div>
    </div>
</template>

<script>
export default {
    name: 'RowNumber',
    props: {
        number: String,
    },
}
</script>
