<template>
    <!-- Start: Add modal -->
    <div
        class="fixed z-20 bottom-0 top-0 inset-x-0 px-4 py-4 flex items-center max-w-xl mx-auto"
    >
        <div class="fixed inset-0 transition-opacity">
            <div
                @click="$emit('close')"
                class="absolute inset-0 bg-gray-500 opacity-75"
            ></div>
        </div>

        <div
            class="relative bg-white rounded-lg p-4 overflow-auto lg:overflow-hidden shadow-xl transform transition-all md:p-6 md:pr-3 w-full"
        >
            <div class="absolute top-0 right-0">
                <button
                    class="p-4 text-gray-400 hover:text-gray-500 focus:outline-none focus:text-gray-500 transition ease-in-out duration-150"
                    @click="$emit('close')"
                    type="button"
                >
                    <IconClose></IconClose>
                </button>
            </div>
            <div class="">
                <h3
                    class="w-full text-xl leading-6 font-medium text-gray-900 mb-4"
                >
                    {{ project ? 'Edit project name' : 'Create a new project' }}
                </h3>

                <form method="post" @submit.stop.prevent="createProject">
                    <div
                        class="form-group md:flex justify-between space-y-2 md:space-x-2 items-end"
                    >
                        <div class="flex-1">
                            <label
                                class="block text-sm font-medium leading-5 text-gray-700"
                                >Name</label
                            >

                            <input
                                class="mt-1 form-input block w-full py-2 px-3 text-xl border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out"
                                name="name"
                                v-model="name"
                                type="text"
                                placeholder="Project name"
                            />
                        </div>
                        <div class="flex justify-end">
                            <button
                                class="py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-gray-800 shadow-sm hover:bg-gray-500 focus:outline-none focus:shadow-outline-blue active:bg-gray-600 transition duration-150 ease-in-out"
                                type="submit"
                            >
                                {{ project ? 'Save' : 'Create' }}
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
    <!-- End: Add modal -->
</template>

<script>
import IconClose from '@/components/icons/IconClose'

export default {
    name: 'ProjectModal',
    components: {
        IconClose,
    },
    props: {
        project: Object,
    },
    data() {
        return {
            name: '',
        }
    },
    computed: {},
    mounted() {
        this.name = this.project ? this.project.name : ''
    },
    methods: {
        createProject() {
            this.$emit('create-project', this.name)
        },
    },
}
</script>
