<template>
    <div class="flex items-center">
        <button
            @click="showMenu = !showMenu"
            class="group relative rounded text-gray-800 hover:text-gray-400"
            :class="[disabled ? 'cursor-default' : ' hover:opacity-100']"
            :disabled="disabled"
        >
            <div class="w-8 h-8 p-1">
                <IconDotsVertical></IconDotsVertical>
            </div>
            <div class="tooltip tooltip-right">Card menu</div>
        </button>
        <div
            :class="{ hidden: !showMenu }"
            class="absolute z-10 right-0 mt-0 mr-4 w-auto border border-gray-200 rounded-md shadow-lg"
        >
            <div class="rounded-md bg-white shadow-xs py-2 divide-y">
                <button
                    @click="$emit('toggle-active')"
                    class="menu-options-btn"
                >
                    <div class="w-5 h-5">
                        <div v-if="active">
                            <IconPause></IconPause>
                        </div>
                        <div v-else>
                            <IconPlay></IconPlay>
                        </div>
                    </div>
                    <div class="">{{ active ? 'Disable' : 'Enable' }}</div>
                </button>

                <div class="menu-options-btn">
                    <div class="w-5 h-5">
                        <IconColorSwatch></IconColorSwatch>
                    </div>
                    <ColorPicker
                        :selectedColor="color"
                        @color-selected="colorSelected"
                    />
                </div>

                <button @click="$emit('delete')" class="menu-options-btn">
                    <div class="w-5 h-5">
                        <IconTrash></IconTrash>
                    </div>
                    <div class="">Remove</div>
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import ColorPicker from '@/components/row/ColorPicker'
import IconDotsVertical from '@/components/icons/IconDotsVertical'
import IconPause from '@/components/icons/IconPause'
import IconPlay from '@/components/icons/IconPlay'
import IconColorSwatch from '@/components/icons/IconColorSwatch'
import IconTrash from '@/components/icons/IconTrash'

export default {
    name: 'PriceContextMenu',
    components: {
        ColorPicker,
        IconDotsVertical,
        IconPause,
        IconPlay,
        IconColorSwatch,
        IconTrash,
    },
    props: {
        isReference: {
            type: Boolean,
            default: false,
        },
        source: String,
        disabled: {
            type: Boolean,
            default: false,
        },
        active: {
            type: Boolean,
            default: true,
        },
        color: String,
    },
    data() {
        return {
            showMenu: false,
        }
    },
    methods: {
        hideMenu() {
            this.showMenu = false
        },
        colorSelected(color) {
            this.$emit('color-selected', color)
            this.hideMenu()
        },
    },
    created() {
        window.addEventListener('click', this.hideMenu, true)
    },
    destroyed() {
        window.removeEventListener('click', this.hideMenu)
    },
}
</script>
