<template>
    <div>
        <div class="sr-only">Trifle</div>
        <svg
            class="w-full h-auto"
            viewBox="0 0 230 77"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect width="230" height="10" fill="currentColor"></rect>
            <path
                d="M0.123779 29.9567H16.527V76.18H27.7241V29.9567H44.1274V20.1982H0.123779V29.9567Z"
                fill="currentColor"
            ></path>
            <path
                d="M46.9921 76.18H58.1369V52.426C58.1369 47.2597 61.7471 43.7062 66.6655 43.7062C68.209 43.7062 70.3281 43.9795 71.3746 44.3349V34.0023C70.3804 33.7563 68.9939 33.5923 67.8689 33.5923C63.3692 33.5923 59.6804 36.3257 58.2153 41.5194H57.7968V34.1936H46.9921V76.18Z"
                fill="currentColor"
            ></path>
            <path
                d="M77.1366 76.18H88.2814V34.1936H77.1366V76.18ZM82.7352 28.7813C86.0577 28.7813 88.7785 26.1298 88.7785 22.877C88.7785 19.6515 86.0577 17 82.7352 17C79.4389 17 76.7181 19.6515 76.7181 22.877C76.7181 26.1298 79.4389 28.7813 82.7352 28.7813Z"
                fill="currentColor"
            ></path>
            <path
                d="M118.818 34.1936H110.839V31.3781C110.839 28.5353 111.938 26.8679 115.208 26.8679C116.542 26.8679 117.903 27.1686 118.792 27.4693L120.754 18.7221C119.368 18.2847 116.307 17.574 112.88 17.574C105.319 17.574 99.6944 22.0296 99.6944 31.1595V34.1936H94.0173V42.9408H99.6944V76.18H110.839V42.9408H118.818V34.1936Z"
                fill="currentColor"
            ></path>
            <path
                d="M136.772 20.1982H125.627V76.18H136.772V20.1982Z"
                fill="currentColor"
            ></path>
            <path
                d="M164.012 77C173.954 77 180.651 71.9431 182.221 64.1526L171.913 63.4419C170.788 66.6401 167.91 68.3075 164.195 68.3075C158.623 68.3075 155.091 64.4533 155.091 58.1936V58.1663H182.456V54.9681C182.456 40.6993 174.189 33.6469 163.568 33.6469C151.743 33.6469 144.077 42.4214 144.077 55.3781C144.077 68.6902 151.638 77 164.012 77ZM155.091 50.9499C155.327 46.1663 158.806 42.3394 163.751 42.3394C168.591 42.3394 171.939 45.9476 171.965 50.9499H155.091Z"
                fill="currentColor"
            ></path>
        </svg>
    </div>
</template>

<script>
export default {
    name: 'Logotype',
}
</script>
