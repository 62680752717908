import Vue from 'vue'
import {
    makeid,
    flatten,
    setmarkup,
    createprice,
    roundPrice,
    dfs,
    deleteNodeById,
    findNodeById,
} from '@/helpers'
import {
    createrow,
    createparent,
    referencechildren,
    setrowdefaults,
} from '@/row'

const state = Vue.observable({
    folders: window.localStorage.getItem('folders')
        ? JSON.parse(window.localStorage.getItem('folders'))
        : { type: 'folder', children: [] },
    activeNode: null,
    prices: window.localStorage.getItem('prices')
        ? JSON.parse(window.localStorage.getItem('prices'))
        : [],
    price: null,
    editingReference: '',
})

// getters
const getters = {
    folders: (state) => state.folders, //all folders
    activeNode: (state) => state.activeNode,
    prices: (state, getters, rootState) => {
        let tax = rootState.settings.settings.tax
        let markup = rootState.settings.settings.markup
        let defaultprice = createprice(1, 'test', markup, tax)
        state.prices.forEach((price) => {
            if (!Object.prototype.hasOwnProperty.call(price, 'type')) {
                Vue.set(price, 'type', defaultprice.type)
            }
            if (!Object.prototype.hasOwnProperty.call(price, 'tax')) {
                Vue.set(price, 'tax', tax)
            }
            if (!Object.prototype.hasOwnProperty.call(price, 'markup')) {
                Vue.set(price, 'markup', markup)
            }
            if (!Object.prototype.hasOwnProperty.call(price, 'show_summary')) {
                Vue.set(price, 'show_summary', true)
            }
            if (!Object.prototype.hasOwnProperty.call(price, 'roundPrice')) {
                Vue.set(price, 'roundPrice', defaultprice.roundPrice)
            }
        })
        return state.prices
    },
    price: (state) => state.price,
    editingReference: (state) => state.editingReference,
    referenceModel: (state) => (row) => {
        let price = state.prices.find((p) => p.id === row.price_id)
        let rows = flatten(price.rows)
        let filtered = rows.filter((r) => {
            return r.id === row.link_id
        })
        return filtered[0]
    },
    flattendRows: (state) => {
        return flatten(state.price.rows)
    },
    totalPrice: (state, getters) => (price) => {
        let totalPrice = 0
        if (price.type === 'COMPUTED') {
            totalPrice = getters.totalCostIncMarkup(price)
        } else {
            totalPrice = price.fixedPrice || 0
        }

        if (price.roundPrice.enabled) {
            totalPrice = roundPrice(
                totalPrice,
                price.roundPrice.direction,
                price.roundPrice.amount
            )
        }
        return totalPrice
    },
    totalPriceIncTax: (state, getters) => (price) => {
        let tax = 1 + price.tax.rate / 100
        let totalPrice = getters.totalPrice(price) * tax
        return totalPrice
    },
    totalCost: (state, getters) => (price) => {
        let total = 0
        if (price.rows) {
            price.rows.forEach((row) => {
                total += getters.totalRowCost(row)
            })
        }
        return total
    },
    totalCostIncMarkup: (state, getters) => (price) => {
        let markup = 1 + price.markup.rate / 100
        return getters.totalCost(price) * markup
    },
    totalCostIncTax: (state, getters) => (price) => {
        let tax = 1 + price.tax.rate / 100
        return getters.totalCost(price) * tax
    },
    totalCostIncMarkupIncTax: (state, getters) => (price) => {
        let tax = 1 + price.tax.rate / 100
        return getters.totalCostIncMarkup(price) * tax
    },
    totalRowCost: (state, getters) => (row) => {
        if (row.rows && row.rows.length > 0) {
            if (row.active) {
                let total = getters.childRowsTotal(row)
                return total * row.qty
            } else {
                return 0
            }
        } else {
            if (row.active) {
                let rate = row.rate
                if (row.is_reference) {
                    let refRow = getters.referenceModel(row)
                    rate = refRow.rate
                }
                return row.qty * rate
            } else {
                return 0
            }
        }
    },
    childRowsTotal: (state, getters) => (parent) => {
        let total = 0
        if (parent.rows) {
            parent.rows.forEach((row) => {
                if (row.rows) {
                    let childrenTotal = getters.childRowsTotal(row)
                    childrenTotal *= row.qty
                    total += childrenTotal
                } else {
                    let rate = row.rate
                    if (row.is_reference) {
                        let refRow = getters.referenceModel(row)
                        rate = refRow.rate
                    }
                    let rowTotal = row.active ? row.qty * rate : 0
                    total += rowTotal
                }
            })
        }
        return total
    },
    totalTimeHours: (state, getters, rootState) => (price) => {
        let total = 0
        if (price.rows) {
            price.rows.forEach((row) => {
                if (row.active) {
                    if (row.rows && row.rows.length > 0) {
                        total += getters.childRowsHours(row) * row.qty
                    } else {
                        let uom = row.uom
                        if (row.is_reference) {
                            let refRow = getters.referenceModel(row)
                            uom = refRow.uom
                        }
                        if (
                            uom.name.toLowerCase() == 'time' &&
                            uom.unit.toLowerCase() == 'hours'
                        ) {
                            total += row.qty
                        } else if (
                            uom.name.toLowerCase() == 'time' &&
                            uom.unit.toLowerCase() == 'days'
                        ) {
                            total +=
                                row.qty *
                                rootState.settings.settings.time.hoursperday
                        }
                    }
                }
            })
        }
        return total
    },
    childRowsHours: (state, getters, rootState) => (parent) => {
        let total = 0
        parent.rows.forEach((row) => {
            if (row.active) {
                if (row.rows) {
                    let childrenTotal = getters.childRowsHours(row)
                    childrenTotal *= row.qty
                    total += childrenTotal
                } else {
                    let uom = row.uom
                    if (row.is_reference) {
                        let refRow = getters.referenceModel(row)
                        uom = refRow.uom
                    }

                    if (
                        uom.name.toLowerCase() == 'time' &&
                        uom.unit.toLowerCase() == 'hours'
                    ) {
                        total += row.qty
                    } else if (
                        uom.name.toLowerCase() == 'time' &&
                        uom.unit.toLowerCase() == 'days'
                    ) {
                        total +=
                            row.qty *
                            rootState.settings.settings.time.hoursperday
                    }
                }
            }
        })
        return total
    },
    totalTimeDays: (state, getters, rootState) => (price) => {
        if (!price.rows) return 0
        if (price.rows.length == 0) return 0

        return (
            getters.totalTimeHours(price) /
            rootState.settings.settings.time.hoursperday
        )
    },
    totalCostTime: (state, getters) => (price) => {
        let total = 0
        if (price.rows) {
            price.rows.forEach((row) => {
                if (row.active) {
                    if (row.rows && row.rows.length > 0) {
                        total += getters.childRowsTimeCost(row) * row.qty
                    } else {
                        let uom = row.uom
                        if (row.is_reference) {
                            let refRow = getters.referenceModel(row)
                            uom = refRow.uom
                        }
                        if (uom.name.toLowerCase() == 'time') {
                            total += row.qty * row.rate
                        }
                    }
                }
            })
        }
        return total
    },
    totalCostMaterials: (state, getters) => (price) => {
        let total = 0
        if (price.rows) {
            price.rows.forEach((row) => {
                if (row.active) {
                    if (row.rows && row.rows.length > 0) {
                        total += getters.childRowsMaterialsCost(row) * row.qty
                    } else {
                        let uom = row.uom
                        if (row.is_reference) {
                            let refRow = getters.referenceModel(row)
                            uom = refRow.uom
                        }
                        if (uom.name.toLowerCase() == 'materials') {
                            total += row.qty * row.rate
                        }
                    }
                }
            })
        }
        return total
    },
    childRowsMaterialsCost: (state, getters) => (parent) => {
        let total = 0
        parent.rows.forEach((row) => {
            if (row.active) {
                if (row.rows) {
                    let childrenTotal = getters.childRowsMaterialsCost(row)
                    childrenTotal *= row.qty
                    total += childrenTotal
                } else {
                    let uom = row.uom
                    let rate = row.rate
                    if (row.is_reference) {
                        let refRow = getters.referenceModel(row)
                        uom = refRow.uom
                        rate = refRow.rate
                    }
                    if (uom.name.toLowerCase() == 'materials') {
                        total += row.qty * rate
                    }
                }
            }
        })
        return total
    },
    childRowsTimeCost: (state, getters) => (parent) => {
        let total = 0
        parent.rows.forEach((row) => {
            if (row.active) {
                if (row.rows) {
                    let childrenTotal = getters.childRowsTimeCost(row)
                    childrenTotal *= row.qty
                    total += childrenTotal
                } else {
                    let uom = row.uom
                    let rate = row.rate
                    console.log(rate)
                    if (row.is_reference) {
                        let refRow = getters.referenceModel(row)
                        uom = refRow.uom
                        rate = refRow.rate
                    }
                    if (uom.name.toLowerCase() == 'time') {
                        total += row.qty * rate
                    }
                }
            }
        })
        return total
    },
    margin: (state, getters) => (price) => {
        let cost = getters.totalCost(price)
        let total = getters.totalPrice(price)
        let diff = total - cost
        let margin = (diff / total) * 100
        return margin || 0
    },
    profit: (state, getters) => (price) => {
        let total = getters.totalPrice(price)
        let cost = getters.totalCost(price)
        return total - cost
    },
}

// mutations
const mutations = {
    SET_FOLDERS(state, folders) {
        state.folders = folders
    },
    CREATE_FOLDER(state, folder) {
        console.log(state.activeNode)
        if (!state.activeNode || state.activeNode.type !== 'folder') {
            state.activeNode = state.folders
            if (!state.activeNode.children) {
                Vue.set(state.activeNode, 'children', [])
            }
        }

        state.activeNode.children.push({
            id: folder.id,
            name: folder.name,
            type: 'folder',
            open: true,
            collapsable: true,
            children: [],
        })
        // Vue.set(state.activeNode, 'open', true)
    },
    ADD_FOLDER(state, folder) {
        if (!state.activeNode || state.activeNode.type !== 'folder') {
            state.activeNode = state.folders
            if (!state.activeNode.children) {
                Vue.set(state.activeNode, 'children', [])
            }
        }

        state.activeNode.children.push(folder)
    },
    UPDATE_FOLDER(state, node) {
        let folder = null

        for (let obj of state.folders.children) {
            folder = dfs(obj, node.id)
            if (folder) {
                break
            }
        }

        Vue.set(folder, 'name', node.name)
    },
    DELETE_FOLDER(state, idx) {
        state.folders.children.splice(idx, 1)
    },
    SET_SELECTED_NODE(state, node) {
        const ref = findNodeById(state.folders, node.id)
        state.activeNode = ref
    },
    ADD_PRICE(state, price) {
        if (!state.activeNode || state.activeNode.type !== 'folder') {
            state.activeNode = state.folders
            if (!state.activeNode.children) {
                Vue.set(state.activeNode, 'children', [])
            }
        }

        // let index = state.prices.findIndex(p => p.id === price.id)
        // if(index > -1){
        // state.prices[index] = price
        // }else{
        state.activeNode.children.push({
            ...price,
            rowType: 'price',
        })
        // }
        window.umami(
            `ADD_PRICE_${localStorage.getItem('trifle-session-id')}_COUNT_${state.prices.length}`
        )
    },
    // ADD_PRICE(state, price) {
    //     // let index = state.prices.findIndex(p => p.id === price.id)
    //     // if(index > -1){
    //     // 	state.prices[index] = price
    //     // }else{
    //     // 	state.prices.push(price)
    //     // }
    //     if (!state.activeNode || state.activeNode.type !== 'folder') {
    //         state.activeNode = state.folders
    //         if (!state.activeNode.children) {
    //             Vue.set(state.activeNode, 'children', [])
    //         }
    //     }

    //     state.activeNode.children.push({
    //         id: price.id,
    //         name: price.name,
    //         type: 'price',
    //     })

    //     // Vue.set(state.activeFolder, 'open', true)

    //     // window.umami(`ADD_PRICE_${localStorage.getItem('trifle-session-id')}_COUNT_${state.prices.length}`)
    // },
    UPDATE_PRICE(state, price) {
        state.price.name = price
    },
    DELETE_PRICE(state, idx) {
        state.prices.splice(idx, 1)
    },
    SET_PRICE(state, price) {
        if (price) {
            state.price = price
            document.title = price.name + ' – Trifle'
        } else {
            state.price = null
        }
    },
    SET_HEADLINE_PRICE_SETTINGS(state, settings) {
        state.price.type = settings.type
        if (state.price.type === 'COMPUTED') {
            Vue.delete(state.price, 'fixedPrice')
        } else {
            state.price.fixedPrice = settings.fixedPrice
        }
        state.price.tax.rate = settings.tax.rate
        state.price.roundPrice = settings.roundPrice
    },
    SET_PRICE_TOTAL_MODE(state, mode) {
        state.price.type = mode
    },
    SET_PRICE_TOTAL(state, price) {
        state.price.fixedPrice = price
    },
    SET_PRICE_TAX_RATE(state, rate) {
        state.price.tax.rate = rate
    },
    ADD_NEW_ROW_TO_PRICE(state, { idx, row }) {
        state.price.rows.splice(idx, 0, row)
    },
    ADD_ROWS_TO_PRICE(state, rows) {
        state.price.rows = state.price.rows.concat(rows)
    },
    REMOVE_ROW_FROM_PRICE(state, idx) {
        state.price.rows.splice(idx, 1)
    },
    DELETE_ROW_FROM_MODEL(state, { row, model }) {
        const idx = model.rows.findIndex((r) => r.id === row.id)
        model.rows.splice(idx, 1)
    },
    ADD_ROW_TO_MODEL_AT_INDEX(state, payload) {
        if (!payload.model.rows) {
            Vue.set(payload.model, 'rows', [])
        }
        Vue.set(payload.model, 'show_children', true)
        payload.model.rows.splice(payload.index, 0, payload.row)
    },
    SET_EDITING_REFERENCE(state, idx) {
        state.editingReference = idx
    },
    CONVERT_ROW_TO_INDEPENDENT(state, rowToConvert) {
        let link_id = rowToConvert.link_id
        let is_root = rowToConvert.link_id === rowToConvert.id
        let linked_description = ''
        let description = ''
        let rate = rowToConvert.rate
        if (is_root) {
            linked_description = rowToConvert.linked_description
            description =
                rowToConvert.linked_description + ' ' + rowToConvert.description
        } else {
            let refRow = getters.referenceModel(rowToConvert)
            linked_description = refRow.linked_description
            description = linked_description + ' ' + rowToConvert.description
            rate = refRow.rate
        }

        Vue.delete(rowToConvert, 'link_id')
        Vue.delete(rowToConvert, 'is_reference')
        Vue.delete(rowToConvert, 'linked_description')
        Vue.set(rowToConvert, 'show_description', true)
        Vue.set(rowToConvert, 'description', description)
        Vue.set(rowToConvert, 'rate', rate)

        //if there is only one more reference row after this one is deleted
        //then make the last row not a reference anymore
        //const row = model.rows.findIndex(row => row.id === idx)
        let allrows = flatten(state.price.rows)

        let references = allrows.filter((row) => {
            return row.link_id === link_id
        })

        //we deleted the root so we need to update all the references
        if (is_root) {
            Vue.set(references[0], 'linked_description', linked_description)
            references.forEach((reference) => {
                Vue.set(reference, 'link_id', references[0].id)
            })
        }

        if (references.length === 1) {
            let ref = references[0]

            let description = ref.linked_description + '\r\n' + ref.description
            Vue.set(ref, 'description', description)
            Vue.set(ref, 'uom', rowToConvert.uom)
            Vue.set(ref, 'rate', rowToConvert.rate)
            Vue.delete(ref, 'link_id')
            Vue.delete(ref, 'is_reference')
            Vue.delete(ref, 'linked_description')
        }
    },
    REFERENCE_ROW_IN_MODEL_AT_INDEX(state, { row, model, index }) {
        //first clone the row so we have our new row
        let clone = JSON.parse(JSON.stringify(row))

        //set the clone as a reference
        /*Vue.set(clone, 'is_reference', true)
        Vue.set(clone, 'description', '')
        Vue.set(clone, 'link_id', row.id)
        Vue.delete(clone, 'linked_description')

        //set the row we are referencing as a reference
        Vue.set(row, 'is_reference', true)
        Vue.set(row, 'link_id', row.id) //set the link_id to itself
        if(!row.linked_description){
            Vue.set(row, 'linked_description', row.description)
            Vue.set(row, 'description', '')
        }*/
        referencechildren(row, clone)

        //add the cloned row to the model we passed in
        if (!model.rows) {
            Vue.set(model, 'rows', [])
        }

        Vue.set(clone, 'root_id', model.id)

        model.rows.splice(index, 0, clone)
    },
    ADD_PARENT_ROW_TO_MODEL(state, { model, index }) {
        //make a new ID for this row
        let parent_id = makeid()

        //create the row with blank data
        let parent = createparent(parent_id)

        let row_id = makeid()
        let row = createrow(row_id)

        if (!model.root_id) {
            model.root_id = model.id
        }

        row.parent_id = parent.id
        row.root_id = model.id
        parent.parent_id = model.id
        parent.root_id = model.id

        //add model as a child
        parent.rows.splice(0, 0, row)

        if (!model.rows) {
            Vue.set(model, 'rows', [])
        }

        model.rows.splice(index, 0, parent)
    },
    DUPLICATE_ROW_IN_MODEL_AT_INDEX(state, { row, model, index }) {
        let clone = JSON.parse(JSON.stringify(row))
        Vue.set(clone, 'id', makeid())
        Vue.set(clone, 'root_id', clone.id)
        model.rows.splice(index, 0, clone)
    },
    SET_ROW_COLOR(state, payload) {
        let row = payload.row
        let color = payload.color
        Vue.set(row, 'color', color)
    },
    CONVERT_ROW_TO_PARENT(state, row) {
        console.log('CONVERT_ROW_TO_PARENT')
        Vue.set(row, 'rows', [])
        Vue.set(row, 'qty', 1)
        //Vue.set(row, 'description', '')
        Vue.delete(row, 'uom')
        Vue.delete(row, 'rate')
        Vue.set(row, 'show_children', true)
    },
    SET_NEW_ROOT_REFERENCE(state, row) {
        let link_id = row.link_id

        let allrows = flatten(state.price.rows)

        let references = allrows.filter((r) => {
            return r.link_id === link_id
        })

        Vue.set(references[0], 'linked_description', row.linked_description)
        Vue.set(references[0], 'uom', row.uom)
        Vue.set(references[0], 'rate', row.rate)
        references.forEach((reference) => {
            Vue.set(reference, 'link_id', references[0].id)
        })
    },
    CONVERT_PARENT_TO_ROW(state, parent) {
        console.log('CONVERT_PARENT_TO_ROW')
        let row = createrow(parent.id) //row defaults
        Vue.set(parent, 'uom', row.uom)
        Vue.set(parent, 'rate', row.rate)
        //Vue.set(parent, 'description', row.description)
        Vue.set(parent, 'show_description', row.show_description)
        Vue.delete(parent, 'rows')
    },
    SET_MARKUP_ON_ALL_ROWS(state, showMarkup) {
        //let rows = flatten(state.price.rows)
        state.price.rows.forEach((row) => {
            setmarkup(row, showMarkup)
        })
    },
}

// actions
const actions = {
    createFolder({ commit }, folder) {
        commit('CREATE_FOLDER', folder)
    },
    outdentRow({ commit }, payload) {
        console.log(payload)
        let row = payload.row
        let parent = payload.parent
        let root = payload.root
        let index = payload.index

        let clone = JSON.parse(JSON.stringify(row))
        commit('ADD_ROW_TO_MODEL_AT_INDEX', {
            row: clone,
            model: root,
            index: index,
        })

        if (row.is_reference) {
            let is_root = row.link_id === row.id
            if (is_root) {
                Vue.delete(row, 'is_root')
            }
        }

        commit('DELETE_ROW_FROM_MODEL', { row: row, model: parent })
        if (parent.rows.length === 0) {
            commit('CONVERT_PARENT_TO_ROW', parent)
        }
    },
    indentRow({ commit }, payload) {
        console.log('indentRow')
        let row = payload.row
        let parent = payload.parent
        let index = payload.index

        let rowAbove = null
        if (parent.rows && parent.rows.length > index) {
            rowAbove = parent.rows[index - 1]
        }

        if (rowAbove) {
            if ((rowAbove.rate && rowAbove.rate > 0) || rowAbove.is_reference) {
                let newParent = createparent(makeid())
                Vue.set(newParent, 'price_id', state.price.id)
                /*let newPayload = {
					row: rowAbove,
					model: newParent,
					index: 0
				}
				commit('ADD_ROW_TO_MODEL_AT_INDEX', newPayload)*/

                let newPayload = {
                    row: row,
                    model: newParent,
                    index: 1,
                }
                commit('ADD_ROW_TO_MODEL_AT_INDEX', newPayload)

                newPayload = {
                    row: newParent,
                    model: parent,
                    index: index,
                }
                commit('ADD_ROW_TO_MODEL_AT_INDEX', newPayload)

                const idx = parent.rows.findIndex((r) => r.id === row.id)
                parent.rows.splice(idx, 1)
                //commit('DELETE_ROW_FROM_MODEL', { row: row, model: parent })
                //commit('DELETE_ROW_FROM_MODEL', { row: rowAbove, model: parent })
            } else {
                if (!rowAbove.rows) {
                    commit('CONVERT_ROW_TO_PARENT', rowAbove)
                }

                let newPayload = {
                    row: row,
                    model: rowAbove,
                    index: rowAbove.rows.length,
                }
                commit('ADD_ROW_TO_MODEL_AT_INDEX', newPayload)

                const idx = parent.rows.findIndex((r) => r.id === row.id)
                parent.rows.splice(idx, 1)
                //commit('DELETE_ROW_FROM_MODEL', { row: row, model: parent })
            }
        } else {
            console.log('MAKE NEW PARENT')
            //remove row from current parent
            //make new parent
            //add row to new parent

            let newParent = createparent(makeid())
            Vue.set(newParent, 'price_id', parent.price_id || parent.id)
            let newPayload = {
                row: newParent,
                model: parent,
                index: index,
            }
            commit('ADD_ROW_TO_MODEL_AT_INDEX', newPayload)

            newPayload = {
                row: row,
                model: newParent,
                index: 0,
            }
            commit('ADD_ROW_TO_MODEL_AT_INDEX', newPayload)

            //commit('DELETE_ROW_FROM_MODEL', { row: row, model: parent })
            const idx = parent.rows.findIndex((r) => r.id === row.id)
            parent.rows.splice(idx, 1)
        }
    },
    setRowColor({ commit, dispatch }, payload) {
        let row = payload.row
        let color = payload.color
        commit('SET_ROW_COLOR', payload)
        if (row.rows) {
            row.rows.forEach((r) => {
                let payload = {
                    row: r,
                    color: color,
                }
                dispatch('setRowColor', payload)
            })
        }
    },
    addPrice({ commit }, price) {
        commit('ADD_PRICE', price)
    },
    updatePrice({ commit }, price) {
        commit('UPDATE_PRICE', price)
    },
    deletePrice({ state }, id) {
        deleteNodeById(state.folders, id)
    },
    setPrice({ commit, rootState }, id) {
        // let price = state.prices.find(p => p.id === idx)
        let price = null

        for (let obj of state.folders.children) {
            price = dfs(obj, id)
            if (price) {
                break
            }
        }

        if (price) {
            let tax = rootState.settings.settings.tax
            let markup = rootState.settings.settings.markup
            let defaultprice = createprice(1, 'test', markup, tax)

            if (!Object.prototype.hasOwnProperty.call(price, 'type')) {
                Vue.set(price, 'type', defaultprice.type)
            }

            if (!Object.prototype.hasOwnProperty.call(price, 'tax')) {
                Vue.set(price, 'tax', tax)
            }
            if (!Object.prototype.hasOwnProperty.call(price, 'markup')) {
                Vue.set(price, 'markup', markup)
            }
            if (!Object.prototype.hasOwnProperty.call(price, 'show_summary')) {
                Vue.set(price, 'show_summary', true)
            }
            if (!Object.prototype.hasOwnProperty.call(price, 'roundPrice')) {
                Vue.set(price, 'roundPrice', defaultprice.roundPrice)
            }

            if (price.rows) {
                setrowdefaults(price.rows)
            }
        }

        commit('SET_PRICE', price)
    },
    clearPrice({ commit }) {
        commit('SET_PRICE', null)
    },
    addRowsToPrice({ commit }, rows) {
        rows.forEach((row) => {
            Vue.set(row, 'price_id', state.price.id)
        })

        commit('ADD_ROWS_TO_PRICE', rows)
    },
    addRowToModelAtIndex({ commit }, payload) {
        let model = payload.model
        let index = payload.index

        //make a new ID for this row
        let id = makeid()

        //create the row with blank data
        let row = createrow(id)
        //row.uom = rootGetters['settings/defaultUom']

        //if we are adding to the price (so at root level)
        //then don't add root_id etc.
        if (model.id !== state.price.id) {
            Vue.set(row, 'parent_id', model.id)
            Vue.set(row, 'root_id', model.root_id)
        }
        Vue.set(row, 'price_id', model.price_id || model.id)

        commit('ADD_ROW_TO_MODEL_AT_INDEX', { row, model, index })
    },
    referenceRow({ commit }, data) {
        let model = data.model
        let row = data.row
        let index = data.index

        commit('REFERENCE_ROW_IN_MODEL_AT_INDEX', { row, model, index })
    },
    addParentRow({ commit }, payload) {
        let model = payload.model
        let index = payload.index

        //make a new ID for this row
        let parent_id = makeid()

        //create the row with blank data
        let parent = createparent(parent_id)

        let child_id = makeid()
        let child = createrow(child_id)

        //if we are adding to the price (so at root level)
        //then don't add root_id etc.
        if (model.id !== state.price.id) {
            Vue.set(parent, 'parent_id', model.id)
            Vue.set(parent, 'root_id', model.root_id || model.id)
        }
        Vue.set(parent, 'price_id', model.price_id || model.id)
        Vue.set(child, 'price_id', model.price_id || model.id)
        Vue.set(child, 'parent_id', parent_id)
        Vue.set(child, 'root_id', parent.root_id || parent_id)

        let payloadChild = {
            row: child,
            model: parent,
            index: 0,
        }
        let payloadParent = {
            row: parent,
            model: model,
            index: index,
        }
        commit('ADD_ROW_TO_MODEL_AT_INDEX', payloadChild)
        commit('ADD_ROW_TO_MODEL_AT_INDEX', payloadParent)
    },
    addChildToModelAtIndex({ commit }, data) {
        let model = data.model
        let index = data.index

        //clone this row to add it as first child
        let row1 = JSON.parse(JSON.stringify(model))
        row1.id = makeid() //give the clone a new id

        //create a new row
        let id = makeid()
        let row2 = createrow(id)
        row2.id = makeid()
        Vue.set(row1, 'price_id', model.price_id || model.id)
        Vue.set(row2, 'price_id', model.price_id || model.id)

        if (!row1.root_id) {
            row1.root_id = model.id
        }

        row2.parent_id = model.id
        row2.root_id = model.root_id

        //model is not a parent then make it one
        Vue.set(model, 'rows', [])
        Vue.set(model, 'qty', 1)
        Vue.set(model, 'description', '')
        Vue.delete(model, 'uom')
        Vue.delete(model, 'rate')

        //if we are already a parent then just add
        //a blank row to the end of the list
        //if(model.rows && model.rows.length > 0){
        //    model.rows.splice(index, 0, clone)
        //}

        let row = row1
        commit('ADD_ROW_TO_MODEL_AT_INDEX', { row, model, index })
        //row = row2
        //commit('ADD_ROW_TO_MODEL_AT_INDEX', { row, model, index })
    },
    addChildToParentModelAtIndex({ commit }, data) {
        let model = data.model
        let index = data.index

        let id = makeid()
        let row = createrow(id)
        row.id = makeid()
        Vue.set(row, 'price_id', model.price_id)

        if (!model.root_id) {
            model.root_id = model.id
        }

        row.parent_id = model.id
        row.root_id = model.root_id

        commit('ADD_ROW_TO_MODEL_AT_INDEX', { row, model, index })
    },
    addChildToReferenceModelAtIndex({ commit }, data) {
        let model = data.model
        let index = data.index
        console.log('addChildToModelAtIndex', model, index)

        //clone this row to add it as first child
        let row1 = JSON.parse(JSON.stringify(model))

        //create a new row
        let id = makeid()
        let row2 = createrow(id)
        row2.id = makeid()

        if (!row1.root_id) {
            row1.root_id = model.id
        }

        row2.parent_id = model.id
        row2.root_id = model.root_id

        //model is not a parent then make it one
        Vue.set(model, 'rows', [])
        Vue.set(model, 'qty', 1)
        Vue.set(model, 'description', '')
        Vue.delete(model, 'uom')
        Vue.delete(model, 'rate')
        Vue.delete(model, 'is_reference')
        Vue.delete(model, 'link_id')
        Vue.delete(model, 'linked_description')

        //if we are already a parent then just add
        //a blank row to the end of the list
        //if(model.rows && model.rows.length > 0){
        //    model.rows.splice(index, 0, clone)
        //}

        let row = row1
        commit('ADD_ROW_TO_MODEL_AT_INDEX', { row, model, index })
        row = row2
        commit('ADD_ROW_TO_MODEL_AT_INDEX', { row, model, index })
    },
    addParentToModelAtIndex({ commit, dispatch }, payload) {
        let model = payload.model
        //let index = payload.index

        //clone this row to add it as first child
        let row = JSON.parse(JSON.stringify(model))
        let row_id = makeid()
        Vue.set(row, 'id', row_id)
        Vue.set(row, 'root_id', model.root_id || model.id)
        Vue.set(row, 'parent_id', model.id)

        //model is not a parent so make it one
        Vue.set(model, 'rows', [])
        Vue.set(model, 'qty', 1)
        Vue.set(model, 'description', '')
        Vue.delete(model, 'uom')
        Vue.delete(model, 'rate')

        let payloadRow = {
            row: row,
            model: model,
            index: 0,
        }
        commit('ADD_ROW_TO_MODEL_AT_INDEX', payloadRow)

        //make a new ID for this row
        let parent_id = makeid()

        //create the row with blank data
        let parent = createparent(parent_id)
        Vue.set(parent, 'root_id', model.root_id || model.id)
        Vue.set(parent, 'parent_id', model.id)

        let payloadParent = {
            row: parent,
            model: model,
            index: 1,
        }
        dispatch('addParentRow', payloadParent)
        //commit('ADD_PARENT_ROW_TO_MODEL', { model, index })
    },
    addParentToParentModelAtIndex({ commit }, data) {
        let model = data.model
        let index = data.index

        commit('ADD_PARENT_ROW_TO_MODEL', { model, index })
    },
    addParentToReferenceModelAtIndex({ commit }, data) {
        let model = data.model
        let index = data.index

        //clone this row to add it as first child
        let row1 = JSON.parse(JSON.stringify(model))

        //create parent row

        if (!row1.root_id) {
            row1.root_id = model.id
        }

        //model is not a parent then make it one
        Vue.set(model, 'rows', [])
        Vue.set(model, 'qty', 1)
        Vue.set(model, 'description', '')
        Vue.delete(model, 'uom')
        Vue.delete(model, 'rate')
        Vue.delete(model, 'is_reference')
        Vue.delete(model, 'link_id')
        Vue.delete(model, 'linked_description')

        //if we are already a parent then just add
        //a blank row to the end of the list
        //if(model.rows && model.rows.length > 0){
        //    model.rows.splice(index, 0, clone)
        //}

        let row = row1
        commit('ADD_ROW_TO_MODEL_AT_INDEX', { row, model, index })
        commit('ADD_PARENT_ROW_TO_MODEL', { model, index })
    },
    importRowToModelAtIndex({ commit }, payload) {
        console.log('importRowToModelAtIndex')
        let model = payload.model
        //let index = payload.index
        let row = payload.row

        //clone this row to add it as first child
        let row1 = JSON.parse(JSON.stringify(model))
        let row1_id = makeid()
        Vue.set(row1, 'id', row1_id)
        Vue.set(row1, 'price_id', model.price_id)
        Vue.set(row1, 'parent_id', model.id)
        Vue.set(row1, 'root_id', model.root_id)

        //clone the row
        let row2 = JSON.parse(JSON.stringify(row))
        let row2_id = makeid()
        Vue.set(row, 'id', row2_id)
        Vue.set(row2, 'price_id', model.price_id)
        Vue.set(row2, 'parent_id', model.id)
        Vue.set(row2, 'root_id', model.root_id)

        if (row2.linked_description) {
            Vue.set(row2, 'description', row2.linked_description)
        }

        Vue.delete(row2, 'is_reference')
        Vue.delete(row2, 'link_id')
        Vue.delete(row2, 'linked_description')

        //model is not a parent then make it one
        Vue.set(model, 'rows', [])
        Vue.set(model, 'qty', 1)
        Vue.set(model, 'description', '')
        Vue.delete(model, 'uom')
        Vue.delete(model, 'rate')

        //if we are already a parent then just add
        //a blank row to the end of the list
        //if(model.rows && model.rows.length > 0){
        //    model.rows.splice(index, 0, clone)
        //}

        let payloadChild1 = {
            row: row1,
            model: model,
            index: 0,
        }
        commit('ADD_ROW_TO_MODEL_AT_INDEX', payloadChild1)

        let payloadChild2 = {
            row: row2,
            model: model,
            index: 1,
        }
        commit('ADD_ROW_TO_MODEL_AT_INDEX', payloadChild2)
    },
    importRowToParentModelAtIndex({ commit }, payload) {
        console.log('importRowToParentModelAtIndex')
        let model = payload.model
        let index = payload.index

        //clone the row we want to import and give it a new ID
        let row = JSON.parse(JSON.stringify(payload.row))
        let id = makeid()
        Vue.set(row, 'id', id)

        //set the cloned rows price ID to our current price
        Vue.set(row, 'price_id', state.price.id)

        //set the rows parent and root IDs
        if (model.id !== state.price.id) {
            Vue.set(row, 'parent_id', model.id)
            Vue.set(row, 'root_id', model.root_id)
        }

        //if we are importing a reference row then we need to save it's
        //description to the linked description
        if (row.linked_description) {
            Vue.set(row, 'description', row.linked_description)
        }
        Vue.delete(row, 'is_reference')
        Vue.delete(row, 'link_id')
        Vue.delete(row, 'linked_description')

        commit('ADD_ROW_TO_MODEL_AT_INDEX', { row, model, index })
    },
    importRowToReferenceModelAtIndex({ commit }, data) {
        console.log('importRowToReferenceModelAtIndex')
        let model = data.model
        let index = data.index
        console.log('addChildToModelAtIndex', model, index)

        //clone this row to add it as first child
        let row1 = JSON.parse(JSON.stringify(model))

        //clone the row
        let row2 = JSON.parse(JSON.stringify(data.row))

        if (row2.linked_description) {
            Vue.set(row2, 'description', row2.linked_description)
        }
        Vue.delete(row2, 'is_reference')
        Vue.delete(row2, 'link_id')
        Vue.delete(row2, 'linked_description')

        if (!row1.root_id) {
            row1.root_id = model.id
        }

        Vue.set(row2, 'price_id', model.price_id)
        Vue.set(row2, 'parent_id', model.id)
        Vue.set(row2, 'root_id', model.root_id)

        //model is not a parent then make it one
        Vue.set(model, 'rows', [])
        Vue.set(model, 'qty', 1)
        Vue.set(model, 'description', '')
        Vue.delete(model, 'uom')
        Vue.delete(model, 'rate')
        Vue.delete(model, 'is_reference')
        Vue.delete(model, 'link_id')
        Vue.delete(model, 'linked_description')

        //if we are already a parent then just add
        //a blank row to the end of the list
        //if(model.rows && model.rows.length > 0){
        //    model.rows.splice(index, 0, clone)
        //}

        let row = row1
        commit('ADD_ROW_TO_MODEL_AT_INDEX', { row, model, index })
        row = row2
        commit('ADD_ROW_TO_MODEL_AT_INDEX', { row, model, index })
    },
    referenceChildToModelAtIndex({ commit }, data) {
        let model = data.model
        let index = data.index

        //clone this row to add it as first child
        let row1 = JSON.parse(JSON.stringify(model))

        //clone the row we want to use as a reference
        let row2 = JSON.parse(JSON.stringify(data.row))
        referencechildren(data.row, row2)
        Vue.set(row2, 'root_id', model.id)
        Vue.set(row2, 'price_id', model.price_id)

        if (!row1.root_id) {
            Vue.set(row1, 'root_id', model.id)
        }

        //model is not a parent then make it one
        Vue.set(model, 'id', makeid())
        Vue.set(model, 'rows', [])
        Vue.set(model, 'qty', 1)
        Vue.set(model, 'description', '')
        Vue.delete(model, 'uom')
        Vue.delete(model, 'rate')

        let row = row1
        commit('ADD_ROW_TO_MODEL_AT_INDEX', { row, model, index })
        row = row2
        commit('ADD_ROW_TO_MODEL_AT_INDEX', { row, model, index })
    },
    referenceChildToParentModelAtIndex({ commit }, payload) {
        console.log('referenceChildToParentModelAtIndex', payload)
        let model = payload.model
        let index = payload.index

        //clone the row we want to use as a reference
        let row = JSON.parse(JSON.stringify(payload.row))
        Vue.set(row, 'root_id', model.id)
        Vue.set(row, 'price_id', model.price_id || model.id)
        Vue.set(row, 'qty', 1)
        Vue.delete(row, 'rate')
        Vue.delete(row, 'uom')
        referencechildren(payload.row, row)

        commit('ADD_ROW_TO_MODEL_AT_INDEX', { row, model, index })
    },
    duplicateRow({ commit }, data) {
        let idx = data.index

        //clone the row
        let row = JSON.parse(JSON.stringify(data.row))

        //make a new id for this new row
        row.id = makeid()

        commit('ADD_NEW_ROW_TO_PRICE', { idx, row })
    },
    //TODO: remove
    //UNUSED
    addNewChild({ commit }, data) {
        //make a new ID for this row
        let id = makeid()

        //create the row with blank data
        let row = createparent(id)

        //add model as a child
        row.rows.splice(0, 0, data.model)

        let idx = data.index

        // commit('ADD_NEW_ROW_TO_PRICE', { idx, row })
        //commit('ADD_NEW_ROW_TO_PARENT', { mode, row, idx })

        //remove row from array
        commit('REMOVE_ROW_FROM_PRICE', idx)

        //add the row to our price
        commit('ADD_NEW_ROW_TO_PRICE', { idx, row })
    },
    deleteRowFromModel({ commit, dispatch }, data) {
        let row = data.row
        let model = data.model

        let link_id = row.link_id
        let is_reference = row.is_reference
        let is_root = link_id === row.id

        if (row.rows && row.rows.length > 0) {
            let flatchildren = flatten(row.rows)
            flatchildren.forEach((child) => {
                if (child.is_reference) {
                    dispatch('convertRowToIndependent', child)
                }
            })
        }

        if (is_reference) {
            let allrows = flatten(state.price.rows)

            let references = allrows.filter((row) => {
                return row.link_id === link_id
            })

            //we deleted a reference so check whether it's the last one remaining
            //if it is then convert it to independent
            if (references.length === 2) {
                dispatch('convertRowToIndependent', references[0])
            } else if (is_root) {
                //we deleted the root so we need to update all the references
                commit('SET_NEW_ROOT_REFERENCE', row)
            }
        }

        commit('DELETE_ROW_FROM_MODEL', { row, model })
        if (model.rows.length === 0) {
            commit('CONVERT_PARENT_TO_ROW', model)
        }
    },
    setEditingReference({ commit }, idx) {
        commit('SET_EDITING_REFERENCE', idx)
    },
    convertRowToIndependent({ getters }, row) {
        let link_id = row.link_id
        let is_root = row.link_id === row.id
        let linked_description = ''
        let description = ''
        let uom = row.uom || ''
        let rate = row.rate
        if (is_root) {
            linked_description = row.linked_description
            description = row.linked_description + ' ' + row.description
        } else {
            let refRow = getters.referenceModel(row)
            uom = refRow.uom
            rate = refRow.rate
            linked_description = refRow.linked_description
            description = linked_description + ' ' + row.description
        }

        Vue.delete(row, 'link_id')
        Vue.delete(row, 'is_reference')
        Vue.delete(row, 'linked_description')
        Vue.set(row, 'show_description', true)
        Vue.set(row, 'description', description)
        Vue.set(row, 'rate', rate)
        Vue.set(row, 'uom', uom)

        //if there is only one more reference row after this one is deleted
        //then make the last row not a reference anymore
        //const row = model.rows.findIndex(row => row.id === idx)
        let allrows = flatten(state.price.rows)

        let references = allrows.filter((row) => {
            return row.link_id === link_id
        })

        //we deleted the root so we need to update all the references
        if (is_root) {
            Vue.set(references[0], 'linked_description', linked_description)
            Vue.set(references[0], 'uom', uom)
            Vue.set(references[0], 'rate', row.rate)
            references.forEach((reference) => {
                Vue.set(reference, 'link_id', references[0].id)
            })
        }

        if (references.length === 1) {
            let ref = references[0]

            let description = ref.linked_description + ' ' + ref.description
            Vue.set(ref, 'description', description)
            Vue.set(ref, 'uom', row.uom)
            Vue.set(ref, 'rate', row.rate)
            Vue.delete(ref, 'link_id')
            Vue.delete(ref, 'is_reference')
            Vue.delete(ref, 'linked_description')
        }
    },
    duplicateRowInModelAtIndex({ commit }, data) {
        let model = data.model
        let row = data.row
        let index = data.index

        commit('DUPLICATE_ROW_IN_MODEL_AT_INDEX', { row, model, index })
    },
}

const vm = new Vue()
vm.$watch(
    () => state,
    () => {
        window.localStorage.setItem('folders', JSON.stringify(state.folders))
    },
    {
        deep: true,
    }
)

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}
